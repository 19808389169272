var content = require("!!../../../../../../node_modules/typings-for-css-modules-loader/lib/index.js?modules&namedExport&camelCase&importLoaders=1&localIdentName=[name]__[local]___[md5:hash:base64:5]!../../../../../../node_modules/stylus-loader/index.js?import=/var/www/frontend/src/constants.styl!./room.styl");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
