exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".app__content___28yTp {\n  margin: 24px;\n  padding: 16px;\n  background-color: #fff;\n}\n.app__headerCenterSection___3Y5J1 {\n  color: #fff;\n  font-weight: 600;\n  font-size: 24px;\n  text-align: center;\n}\n.app__headerRightSection___1bLLS {\n  text-align: right;\n}\n.app__headerRightSection___1bLLS > * {\n  margin-left: 24px;\n}\n.app__headerRightSection___1bLLS > span {\n  color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"content": "app__content___28yTp",
	"headerCenterSection": "app__headerCenterSection___3Y5J1",
	"headerRightSection": "app__headerRightSection___1bLLS"
};