import { PromiseState } from 'react-redux-fetch'
import { MapStateToProps } from 'react-redux'
import { Event, EventType, MealFormat, User, Room, IOption, Timezone } from 'types/models'
import { RouteComponentProps } from 'react-router'
import { StoreInterface } from 'store'
import { SorterResult, PaginationConfig } from 'antd/lib/table'
import { Moment } from 'moment'

import { Tabs } from './constants'

export enum NULLABLE {
  NULL = 'null',
  NOT_NULL = 'not_null'
}

export interface StateInterface {
  searchString?: string
  tab: Tabs
  pagination: PaginationConfig
  sorter?: SorterResult<Event>
  availableHeight?: number
  dates: [Moment, Moment]
  eventTypes?: number[]
  mealFormats?: number[]
  eventStatus?: string[]
  eventReservation?: string[]
  manager?: number[]
  room?: number[],
  overdue: boolean,
  overdueDays: string,
  newRange: string
}

export interface QueryInterface {
  authorId?: number
  pageSize?: number
  page?: number
  status?: string
  sortColumn?: string
  sortOrder?: string
  archivedAt?: NULLABLE
  searchString?: string
  startDate?: string
  endDate?: string
  eventTypes?: number[]
  mealFormats?: number[]
  eventStatus?: string[]
  eventReservation?: string[]
  manager?: number[]
  room?: number[],
  overdue?: string,
  overdueDays?: string,
  newRange?: string
}

export interface StatePropsInterface {
  currentUserId?: number
}

export type MapStateToPropsType = MapStateToProps<StatePropsInterface, null, StoreInterface>

export interface ComponentPropsInterface extends StatePropsInterface, RouteComponentProps {
  eventsFetch: PromiseState<Event[]>
  eventsCountFetch: PromiseState<number>
  eventArchiveFetch: PromiseState<Event>
  eventTypesFetch: PromiseState<EventType[]>
  mealFormatsFetch: PromiseState<MealFormat[]>
  usersFetch: PromiseState<User[]>
  roomsFetch: PromiseState<Room[]>
  optionTimeFetch: PromiseState<IOption[]>
  timezoneFetch: PromiseState<Timezone>
  dispatchEventsGet(query: QueryInterface): void
  dispatchEventsCountGet(query: Pick<QueryInterface, 'authorId' | 'status'>): void
  dispatchEventArchivePut(eventId: number): void
  dispatchEventTypesGet(): void
  dispatchMealFormatsGet(): void
  dispatchUsersGet(): void
  dispatchRoomsGet(): void
  dispatchOptionTimeGet(): void
  dispatchTimezoneGet(): void
}
