exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".booking__root___e0lNc {\n  padding: 16px;\n}\n.booking__root___e0lNc:not(:last-child) {\n  border-bottom: 1px solid #808080;\n}\n.booking__label___200wb {\n  font-weight: 600;\n}\n.booking__highlighted___16a5t {\n  color: #f00;\n}\n", ""]);

// exports
exports.locals = {
	"root": "booking__root___e0lNc",
	"label": "booking__label___200wb",
	"highlighted": "booking__highlighted___16a5t"
};