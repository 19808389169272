import React from 'react'
import connect from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import config from 'common/config'
import { IUsageFormat } from 'types/models'

interface IUsageFormatsProps {
    usageFormatsFetch: {
        value: IUsageFormat[],
    }
    dispatchUsageFormatsGet(): void
}

const columns: ColumnProps<IUsageFormat>[] = [
    {
        key: 'id',
        title: 'id',
        dataIndex: 'id',
        width: 100
    },
    {
        key: 'name',
        title: 'Название',
        dataIndex: 'name',
        render: (text: string, record: IUsageFormat) => <Link to={`/admin/usage-formats/${record.id}`}>{text}</Link>
    },
];

class UsageFormatsTable extends Table<IUsageFormat> { }

class UsageFormats extends React.Component<IUsageFormatsProps> {
    public componentDidMount(): void {
        this.props.dispatchUsageFormatsGet()
    }

    public render(): React.ReactNode {
        return (
            <div>
                <Link to='/admin/usage-formats/create'>
                    <Button type='primary'>Новый параметр</Button>
                </Link>

                {
                    this.props.usageFormatsFetch.value && <UsageFormatsTable
                        columns={columns}
                        rowKey='id'
                        dataSource={this.props.usageFormatsFetch.value}
                    />
                }
            </div>
        );
    }
}

export default connect([{
    resource: 'usageFormats',
    method: 'get',
    request: {
        url: urljoin(config.backendUrl, 'usage-formats')
    }
}])(UsageFormats)
