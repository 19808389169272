import { Button, message, PageHeader, Spin } from 'antd'
import config from 'common/config'
import { ROOM_TYPE_FORM } from 'constants/RoomType'
import { autobind } from 'core-decorators'
import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { FormAction, submit } from 'redux-form'
import { RoomType } from 'types/models'
import urljoin from 'url-join'

import Form, { RoomTypeFormInterface } from '../_shared/form/Form'
import { formatToForm } from '../_shared/form/helpers'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  roomTypeGetFetch: PromiseState<RoomType>
  roomTypePutFetch: PromiseState<RoomType>

  dispatchRoomTypeGet(id: string): void
  dispatchRoomTypePut(id: string, data: FormData): void

  dispatch(value: FormAction): void
}

class Edit extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchRoomTypeGet(this.props.match.params.id)
  }

  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(ROOM_TYPE_FORM))
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.roomTypePutFetch.pending && this.props.roomTypePutFetch.fulfilled) {
      message.success('Тип помещения сохранен')
      this.props.dispatchRoomTypeGet(this.props.match.params.id)
    }
  }

  @autobind
  public handeSubmit(values: FormData): void {
    this.props.dispatchRoomTypePut(
      this.props.match.params.id,
      values
    )
  }

  public render(): React.ReactNode {
    if (!this.props.roomTypeGetFetch.value) {
      return <Spin/>
    }

    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Тип помещения'
          subTitle='Редактирование'
          extra={[
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Сохранить
            </Button>
          ]}
        />
        <Form
          initialValues={formatToForm(this.props.roomTypeGetFetch.value)}
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'roomType',
        name: 'roomTypeGet'
      },
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'roomTypes', id)
      })
    },
    {
      resource: {
        action: 'roomType',
        name: 'roomTypePut'
      },
      method: 'put',
      request: (id: string, body: RoomTypeFormInterface) => ({
        url: urljoin(config.backendUrl, 'roomTypes', id),
        body
      })
    }
  ]
)(Edit)
