import React from 'react'
// import * as R from 'ramda'
import connect, { PromiseState } from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import { Room, RoomType, RoomTypesConfig } from 'types/models'
import config from 'common/config'

interface RoomsProps {
  roomsFetch: {
    value: Room[];
  }
  roomTypesConfigsFetch: PromiseState<RoomTypesConfig[]>

  dispatchRoomsGet(): void
  dispatchRoomTypesConfigsGet(): void
}

const columns: ColumnProps<Room>[] = [
  {
    key: 'id',
    title: 'id',
    dataIndex: 'id',
    width: 100
  },
  {
    key: 'name',
    title: 'Название',
    dataIndex: 'name',
    render: (text: string, record: Room) => <Link to={`/admin/rooms/${record.id}`}>{text}</Link>
  },
  {
    key: 'type',
    title: 'Тип',
    dataIndex: 'type',
    render: (type: RoomType) => type ? type.name : 'Не указан'
  },
  {
    key: 'isPavilion',
    title: 'Павильон',
    dataIndex: 'isPavilion',
    render: (isPavilion: boolean): string => isPavilion ? 'Да' : 'Нет'
  },
  {
    key: 'squares',
    title: 'Площадь',
    dataIndex: 'squares',
    render: (squares: number) => squares ? squares : 'Не задана'
  },
  {
    key: 'configsString',
    title: 'Конфигурации',
    dataIndex: 'configsString',
    render: (configsString: string) => configsString ? configsString : 'Не заданы'
  },
  /*
  {
    key: 'configs',
    title: 'Конфигурации',
    dataIndex: 'configs',
    render: (configs: RoomTypesConfig[]) => {

      if (configs)
      {
        return configs.map(item => item.name).join(', ');
      }

      return 'Не заданы';

    }
  }
  */
]

class RoomTable extends Table<Room> {}

class Rooms extends React.Component<RoomsProps> {
  public componentDidMount(): void {
    this.props.dispatchRoomsGet()
    this.props.dispatchRoomTypesConfigsGet()
  }

  public render(): React.ReactNode {

    // console.log('this.props.roomTypesConfigsFetch');
    // console.log(this.props.roomTypesConfigsFetch.value);

    // console.log('this.props.roomsFetch');
    // console.log(this.props.roomsFetch.value);

    let roomsClone = Object.create(this.props.roomsFetch);

    Object.defineProperty(roomsClone, 'value', {
      writable: true,
    });
    roomsClone.value = [];

    for (let key in this.props.roomsFetch.value)
    {
      if (!this.props.roomsFetch.value.hasOwnProperty(key)) continue;

      let newConfigs : string[] = [];
      if (this.props.roomsFetch.value[key].configs)
      {
        let tmp = this.props.roomsFetch.value[key].configs.toString().split(',');

        for (let innerKey in this.props.roomTypesConfigsFetch.value)
        {
          if (!this.props.roomTypesConfigsFetch.value.hasOwnProperty(innerKey)) continue;

          if (tmp.includes(this.props.roomTypesConfigsFetch.value[innerKey].id.toString()))
          {
            newConfigs.push(this.props.roomTypesConfigsFetch.value[innerKey].name);
          }
        }
      }   
      
      let roomClone = Object.create(this.props.roomsFetch.value[key]);

      if (newConfigs)
      {
        roomClone.configsString = newConfigs.join(', ').toString();
      } else {
        roomClone.configsString = 'Не заданы'
      }

      roomsClone.value[key] = {};
      roomsClone.value[key] = roomClone;
      // this.props.roomsFetch.value[key] = roomClone;
    }

    return (
      <div>
        <Link to='/admin/rooms/create'>
          <Button type='primary'>
            Новое помещение
          </Button>
        </Link>
        <RoomTable
          columns={columns}
          rowKey='id'
          // dataSource={this.props.roomsFetch.value}
          dataSource={roomsClone.value}
        />
      </div>
    )
  }
}

export default connect([
  {
    resource: 'rooms',
    method: 'get',
    request: {
      url: urljoin(config.backendUrl, 'rooms')
    }
  },
  {
    resource: 'roomTypesConfigs',
    method: 'get',
    request: {
      url: urljoin(config.backendUrl, 'roomTypesConfigs')
    }
  }
])(Rooms)
