import { Button, message, PageHeader, Spin } from 'antd'
import config from 'common/config'
import { RIGHT_TYPE_FORM } from 'constants/RightType'
import { autobind } from 'core-decorators'
import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { FormAction, submit } from 'redux-form'
import { RightType } from 'types/models'
import urljoin from 'url-join'

import Form, { RightTypeFormInterface } from '../_shared/form/Form'
import { formatToForm } from '../_shared/form/helpers'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  rightTypeGetFetch: PromiseState<RightType>
  rightTypePutFetch: PromiseState<RightType>

  dispatchRightTypeGet(id: string): void
  dispatchRightTypePut(id: string, data: FormData): void

  dispatch(value: FormAction): void
}

class Edit extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchRightTypeGet(this.props.match.params.id)
  }

  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(RIGHT_TYPE_FORM))
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.rightTypePutFetch.pending && this.props.rightTypePutFetch.fulfilled) {
      message.success('Предел права сохранен')
      this.props.dispatchRightTypeGet(this.props.match.params.id)
    }
  }

  @autobind
  public handeSubmit(values: FormData): void {
    this.props.dispatchRightTypePut(
      this.props.match.params.id,
      values
    )
  }

  public render(): React.ReactNode {
    if (!this.props.rightTypeGetFetch.value) {
      return <Spin/>
    }

    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Предел права'
          subTitle='Редактирование'
          extra={[
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Сохранить
            </Button>
          ]}
        />
        <Form
          initialValues={formatToForm(this.props.rightTypeGetFetch.value)}
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'rightType',
        name: 'rightTypeGet'
      },
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'rightTypes', id)
      })
    },
    {
      resource: {
        action: 'rightType',
        name: 'rightTypePut'
      },
      method: 'put',
      request: (id: string, body: RightTypeFormInterface) => ({
        url: urljoin(config.backendUrl, 'rightTypes', id),
        body
      })
    }
  ]
)(Edit)
