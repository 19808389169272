import { USER_ROLE } from 'types/models'

export const USER_ROLE_TO_STRING = {
  [USER_ROLE.ADMIN]: 'Администратор',
  [USER_ROLE.MANAGER]: 'Менеджер',
  [USER_ROLE.SUPERVISOR]: 'Руководитель',
  [USER_ROLE.OBSERVER]: 'Наблюдатель'
}

export const USER_FORM: string = 'USER_FORM'
