import * as R from 'ramda'
import { RoomTypeFormInterface } from './Form'
import { RoomType } from 'types/models'

type RoomTypeFormValidation = {
  [F in keyof RoomTypeFormInterface]?: string
}

export function validate(values: RoomTypeFormInterface): RoomTypeFormValidation {
  const errors: RoomTypeFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  return errors
}

export function formatToForm(values: RoomType): RoomTypeFormInterface {
  return {
    ...R.pick(['name', 'description'], values),
    roomTypesConfigId: values.roomTypesConfigId && values.roomTypesConfigId.toString()
  }
}