import { combineReducers } from 'redux'
import { reducer as fetchReducer } from 'react-redux-fetch'
import { reducer as formReducer } from 'redux-form'

import user from './user'

const rootReducer = combineReducers({
  user,
  repository: fetchReducer,
  form: formReducer
})

export default rootReducer
