import React from 'react'
import connect from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import config from 'common/config'
import { IOption } from 'types/models'

interface IOptionsProps {
    optionsFetch: {
        value: IOption[],
    }
    dispatchOptionsGet(): void
}

const columns: ColumnProps<IOption>[] = [
    {
        key: 'id',
        title: 'id',
        dataIndex: 'id',
        width: 100
    },
    {
        key: 'name',
        title: 'Название',
        dataIndex: 'name',
        render: (text: string, record: IOption) => <Link to={`/admin/options/${record.id}`}>{text}</Link>
    },
    {
        key: 'slug',
        title: 'Ключ',
        dataIndex: 'slug',
        render: (text: string) => <span>{text}</span>
    },
    {
        key: 'value',
        title: 'Значение',
        dataIndex: 'value',
        render: (text: string) => <span>{text}</span>
    },
];

class OptionsTable extends Table<IOption> { }

class Options extends React.Component<IOptionsProps> {
    public componentDidMount(): void {
        this.props.dispatchOptionsGet()
    }

    public render(): React.ReactNode {
        return (
            <div>
                <Link to='/admin/options/create'>
                    <Button type='primary'>Новый параметр</Button>
                </Link>

                <OptionsTable
                    columns={columns}
                    rowKey='id'
                    dataSource={this.props.optionsFetch.value}
                />
            </div>
        );
    }
}

export default connect([{
    resource: 'options',
    method: 'get',
    request: {
        url: urljoin(config.backendUrl, 'options')
    }
}])(Options)
