import React from 'react'
import { DateRange } from 'moment-range'
import classnames from 'classnames'
import * as R from 'ramda'
import { Popover } from 'antd'
import moment from 'moment'
import { Booking, EVENT_RESERVATION_TYPE } from 'types/models'
import {
  rangeSelectionToMomentDateRange,
  rangeSelectionToMomentDateRangeAlt,
  backendDateRangeToMomentDateRange,
  intersectDateRanges, rangeSelectionToMomentDateTimeRange, backendDateRangeToMomentDatetimeRange,
  rangeSelectionToMomentDateTimeRangeAlt
} from 'utils/date.utils'

import { BookingFormInterface } from '../../../types/form'

import { default as BookingComponent } from './booking/Booking'
import * as styles from './room.styl'
import { PopoverDateRanges, Props } from './types'

class Room extends React.Component<Props> {
  public isDateInRanges(date: moment.Moment, range: (DateRange | undefined)[]): boolean {
    return R.reject(R.isNil)(range).some((dateRange: DateRange) => dateRange.contains(date))
  }

  public getCurrentBooking = (date: moment.Moment): BookingFormInterface | undefined =>
    this.props.bookings &&
      this.props.bookings.find(
        (booking: BookingFormInterface) =>
          booking.roomId === this.props.room.id &&
          this.isDateInRanges(
            date,
            [
              booking.dates && rangeSelectionToMomentDateRange(booking.dates, this.props.timezone),
              booking.dismantlingDates && rangeSelectionToMomentDateRange(booking.dismantlingDates, this.props.timezone),
              booking.installingDates && rangeSelectionToMomentDateRange(booking.installingDates, this.props.timezone),
              booking.datesAlt && rangeSelectionToMomentDateRangeAlt(booking.datesAlt, this.props.timezone),
              booking.dismantlingDatesAlt && rangeSelectionToMomentDateRangeAlt(booking.dismantlingDatesAlt, this.props.timezone),
              booking.installingDatesAlt && rangeSelectionToMomentDateRangeAlt(booking.installingDatesAlt, this.props.timezone)
            ]
          )
        )

  public isSelection: (date: moment.Moment) => boolean = (date: moment.Moment) => {
    const currentBooking: BookingFormInterface | undefined = this.getCurrentBooking(date)

    return !!currentBooking
  }

  public isMontage: (date: moment.Moment) => boolean = (date: moment.Moment) =>
    this.props.room.bookings.some((booking: Booking) =>
      this.isDateInRanges(
        date,
        [
          backendDateRangeToMomentDateRange(booking.dismantlingDates, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.installingDates, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.dismantlingDatesAlt, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.installingDatesAlt, this.props.timezone)
        ]
      ),
    )

  public isBooked: (date: moment.Moment) => boolean = (date: moment.Moment) =>
    this.props.room.bookings.some((booking: Booking) =>
      backendDateRangeToMomentDateRange(booking.dates).contains(date)
    )

  public isSoftReserved: (date: moment.Moment) => boolean = (date: moment.Moment) =>
    this.props.room.bookings.some((booking: Booking) =>
      booking.event &&
      this.isDateInRanges(
        date,
        [
          backendDateRangeToMomentDateRange(booking.dismantlingDates, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.installingDates, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.dates, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.dismantlingDatesAlt, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.installingDatesAlt, this.props.timezone),
          backendDateRangeToMomentDateRange(booking.datesAlt, this.props.timezone)
        ]
      ) &&
      booking.event.reservationType === EVENT_RESERVATION_TYPE.PRELIMINARY
    )

  public getBookings: (date: moment.Moment) => (Booking | BookingFormInterface)[] = (date: moment.Moment) => {
    const { eventId } = this.props

    return this.props.room.bookings.filter((booking: Booking) => booking.eventId !== eventId && (
        backendDateRangeToMomentDateRange(booking.dismantlingDates, this.props.timezone).contains(date) ||
        backendDateRangeToMomentDateRange(booking.installingDates, this.props.timezone).contains(date) ||
        backendDateRangeToMomentDateRange(booking.dates, this.props.timezone).contains(date) ||
        backendDateRangeToMomentDateRange(booking.dismantlingDatesAlt, this.props.timezone).contains(date) ||
        backendDateRangeToMomentDateRange(booking.installingDatesAlt, this.props.timezone).contains(date) ||
        backendDateRangeToMomentDateRange(booking.datesAlt, this.props.timezone).contains(date)
      )
    )
  }

  public getPopoverDateRanges(
    bookings: Booking[],
    currentBooking: BookingFormInterface | undefined
  ): { currentPopoverDateRanges: PopoverDateRanges | undefined; popoverDateRanges: PopoverDateRanges[] } {
    const currentPopoverDateRanges =
      currentBooking &&
      currentBooking.dates &&
      currentBooking.installingDates &&
      currentBooking.dismantlingDates ?
      {
        dates: {
          range: rangeSelectionToMomentDateTimeRange(currentBooking.dates, this.props.timezone),
          isConflicting: false
        },
        installingDates: {
          range: rangeSelectionToMomentDateTimeRange(currentBooking.installingDates, this.props.timezone),
          isConflicting: false
        },
        dismantlingDates: {
          range: rangeSelectionToMomentDateTimeRange(currentBooking.dismantlingDates, this.props.timezone),
          isConflicting: false
        },
        datesAlt: {
          range: rangeSelectionToMomentDateTimeRangeAlt(currentBooking.datesAlt, this.props.timezone),
          isConflicting: false
        },
        installingDatesAlt: {
          range: rangeSelectionToMomentDateTimeRangeAlt(currentBooking.installingDatesAlt, this.props.timezone),
          isConflicting: false
        },
        dismantlingDatesAlt: {
          range: rangeSelectionToMomentDateTimeRangeAlt(currentBooking.dismantlingDatesAlt, this.props.timezone),
          isConflicting: false
        }
      } :
      undefined

    const popoverDateRanges = R.map(
      (booking: Booking) => ({
        dates: {
          range: backendDateRangeToMomentDatetimeRange(booking.dates, this.props.timezone),
          isConflicting: false
        },
        installingDates: {
          range: backendDateRangeToMomentDatetimeRange(booking.installingDates, this.props.timezone),
          isConflicting: false
        },
        dismantlingDates: {
          range: backendDateRangeToMomentDatetimeRange(booking.dismantlingDates, this.props.timezone),
          isConflicting: false
        },
        datesAlt: {
          range: backendDateRangeToMomentDatetimeRange(booking.dates, this.props.timezone),
          isConflicting: false
        },
        installingDatesAlt: {
          range: backendDateRangeToMomentDatetimeRange(booking.installingDates, this.props.timezone),
          isConflicting: false
        },
        dismantlingDatesAlt: {
          range: backendDateRangeToMomentDatetimeRange(booking.dismantlingDates, this.props.timezone),
          isConflicting: false
        }
      }),
      bookings
    )

    const dateRangePool: DateRange[] = []

    if (currentPopoverDateRanges) {
      dateRangePool.push(currentPopoverDateRanges.dates.range)
      dateRangePool.push(currentPopoverDateRanges.installingDates.range)
      dateRangePool.push(currentPopoverDateRanges.dismantlingDates.range)
      if (currentPopoverDateRanges.datesAlt.range){dateRangePool.push(currentPopoverDateRanges.datesAlt.range)}
      if (currentPopoverDateRanges.installingDatesAlt.range){dateRangePool.push(currentPopoverDateRanges.installingDatesAlt.range)}
      if (currentPopoverDateRanges.dismantlingDatesAlt.range){dateRangePool.push(currentPopoverDateRanges.dismantlingDatesAlt.range)}
    }

    popoverDateRanges.forEach((ranges: PopoverDateRanges) => {
      dateRangePool.push(ranges.dates.range)
      dateRangePool.push(ranges.installingDates.range)
      dateRangePool.push(ranges.dismantlingDates.range)
      if (ranges.datesAlt){dateRangePool.push(ranges.datesAlt.range)}
      if (ranges.installingDatesAlt){dateRangePool.push(ranges.installingDatesAlt.range)}
      if (ranges.dismantlingDatesAlt){dateRangePool.push(ranges.dismantlingDatesAlt.range)}
    })

    const intersectionPool = intersectDateRanges(dateRangePool)

    if (currentPopoverDateRanges) {
      currentPopoverDateRanges.dates.isConflicting = intersectionPool[0]
      currentPopoverDateRanges.installingDates.isConflicting = intersectionPool[1]
      currentPopoverDateRanges.dismantlingDates.isConflicting = intersectionPool[2]
      currentPopoverDateRanges.datesAlt.isConflicting = intersectionPool[3]
      currentPopoverDateRanges.installingDatesAlt.isConflicting = intersectionPool[4]
      currentPopoverDateRanges.dismantlingDatesAlt.isConflicting = intersectionPool[5]
    }

    // for (let i = currentPopoverDateRanges ? 3 : 0; i < dateRangePool.length; i += 3) {
    //   const index = Math.floor((currentPopoverDateRanges ? i - 3 : i) / 3)
    for (let i = currentPopoverDateRanges ? 6 : 0; i < dateRangePool.length; i += 6) {
      const index = Math.floor((currentPopoverDateRanges ? i - 6 : i) / 6)
      popoverDateRanges[index].dates.isConflicting = intersectionPool[i]
      popoverDateRanges[index].installingDates.isConflicting = intersectionPool[i + 1]
      popoverDateRanges[index].dismantlingDates.isConflicting = intersectionPool[i + 2]
      popoverDateRanges[index].datesAlt.isConflicting = intersectionPool[i + 3]
      popoverDateRanges[index].installingDatesAlt.isConflicting = intersectionPool[i + 4]
      popoverDateRanges[index].dismantlingDatesAlt.isConflicting = intersectionPool[i + 5]
    }

    return { currentPopoverDateRanges, popoverDateRanges }
  }

  public getPopoverContent(currentBooking: BookingFormInterface, bookings: Booking[]): React.ReactNode {
    const { currentPopoverDateRanges, popoverDateRanges } = this.getPopoverDateRanges(bookings, currentBooking)

    return (
      <>
        {
          currentBooking && currentPopoverDateRanges &&
            <BookingComponent
              key={0}
              booking={currentBooking}
              dateRanges={currentPopoverDateRanges}
            />
        }
        {
          bookings.map(
            (booking: Booking | BookingFormInterface, i: number) =>
              <BookingComponent
                key={i + 1}
                booking={booking}
                isConflicting={!!currentBooking}
                dateRanges={popoverDateRanges[i]}
              />
          )
        }
      </>
    )

  }

  public render(): React.ReactNode {
    const { room, range, onClick, hidden } = this.props

    if (hidden) {
      return null
    }

    return (
      <div className={styles.room}>
        {
          Array
            .from(range.by('day'))
            .map(
              (realDate: moment.Moment) => {
                const date = realDate.clone().startOf('day')
                const popoverBookings: (Booking | BookingFormInterface | undefined)[] =
                  [this.getCurrentBooking(date), ...this.getBookings(date)]
                const actualBookingsNumber: number = R.reject(R.isNil, popoverBookings).length

                let className :string[] = [];
                if (actualBookingsNumber > 0) {

                  if (this.isMontage(date) && !this.isSoftReserved(date)) {
                    className.push(styles.montage)
                  }

                  if (this.isBooked(date) && !this.isSoftReserved(date)) {
                    if (this.isMontage(date)){
                      className.push(styles.bookedMontag)
                    } else {
                      className.push(styles.booked)
                    }
                  }

                  if (this.isSoftReserved(date)) {
                    if (this.isMontage(date)){
                      className.push(styles.softReserveMontag)
                    } else {
                      className.push(styles.softReserve)
                    }
                  }

                  if (this.isSelection(date)) {
                    className.push(styles.selection)
                  }

                  if (actualBookingsNumber > 1) {
                    if (this.isMontage(date)){
                      className.push(styles.overlapMontag)
                    } else {
                      className.push(styles.overlap)
                    }
                  }
                }

                return (
                  <div
                    className={classnames(styles.block, className)}
                    /*
                    className={classnames(styles.block, {
                      [styles.montage]: this.isMontage(date) && !this.isSoftReserved(date),
                      [styles.booked]: this.isBooked(date) && !this.isSoftReserved(date),
                      [styles.softReserve]: this.isSoftReserved(date),
                      [styles.selection]: this.isSelection(date),
                      [styles.overlap]: actualBookingsNumber > 1
                    })}
                    */
                    role='button'
                    onClick={() => { if (actualBookingsNumber === 0) { onClick(room) } }}
                  >
                    {actualBookingsNumber > 0 ?
                      <Popover
                        className={styles.popover}
                        content={this.getPopoverContent(popoverBookings[0] as BookingFormInterface, popoverBookings.slice(1) as Booking[])}
                      >
                        {date.get('date')}
                      </Popover> :
                      <span>
                        {date.get('date')}
                      </span>
                    }
                  </div>
                )
              }
            )
        }
      </div>
    )
  }
}

export default Room
