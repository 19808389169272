import React from 'react'
import { Switch, Route, RouteComponentProps } from 'react-router'

import { userIsAuthenticatedRedir } from '../../../auth'

import Index from './Index/Index'
import Create from './Create/Create'
import Edit from './Edit/Edit'
import Show from './Show/Show'
import Review from './Review/Review'

const CreateComponent = userIsAuthenticatedRedir(Create)
const EditComponent = userIsAuthenticatedRedir(Edit)
const IndexComponent = userIsAuthenticatedRedir(Index)
const ReviewComponent = userIsAuthenticatedRedir(Review)

class Events extends React.Component<RouteComponentProps> {
  public render(): React.ReactNode {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}/create`} component={CreateComponent} />
        <Route exact path={`${this.props.match.url}/:id(\\d+)`} component={Show} />
        <Route exact path={`${this.props.match.url}/:id(\\d+)/edit`} component={EditComponent} />
        <Route exact path={`${this.props.match.url}/:id(\\d+)/review`} component={ReviewComponent} />
        <Route path={`${this.props.match.url}`} component={IndexComponent} />
      </Switch>
    )
  }
}

export default Events
