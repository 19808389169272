import React from 'react'
import connect from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import { Email } from 'types/models'
import config from 'common/config'

interface Props {
  emailsFetch: {
    value: Email[];
  }

  dispatchEmailsGet(): void
}

const columns: ColumnProps<Email>[] = [
  {
    key: 'id',
    title: 'id',
    dataIndex: 'id',
    width: 100
  },
  {
    key: 'email',
    title: 'Электронный адрес',
    dataIndex: 'email',
    render: (text: string, record: Email) => <Link to={`/admin/emails/${record.id}`}>{text}</Link>
  }
]

class EmailTable extends Table<Email> {}

class Emails extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchEmailsGet()
  }

  public render(): React.ReactNode {
    return (
      <div>
        <Link to='/admin/emails/create'>
          <Button type='primary'>
            Новый адрес
          </Button>
        </Link>
        <EmailTable
          columns={columns}
          rowKey='id'
          dataSource={this.props.emailsFetch.value}
        />
      </div>
    )
  }
}

export default connect([{
  resource: 'emails',
  method: 'get',
  request: {
    url: urljoin(config.backendUrl, 'emails')
  }
}])(Emails)
