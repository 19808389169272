import { EmailFormInterface } from './types'

type EmailFormValidation = {
  [F in keyof EmailFormInterface]?: string
}

const REQUIRED_FIELD = 'Поле обязательно для заполнения'
const NO_SPACES_ALLOWED = 'Пробелы в этом поле запрещены'

export function validate(values: EmailFormInterface): EmailFormValidation {
  const errors: EmailFormValidation = {}

  if (!values.email) {
    errors.email = REQUIRED_FIELD
  }

  if (values.email && values.email.indexOf(' ') !== -1) {
    errors.email = NO_SPACES_ALLOWED
  }

  return errors
}
