import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'

import { StoreInterface } from './store'
import { USER_ROLE } from './types/models'

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
  authenticatedSelector: (state: StoreInterface) => state.user.data !== undefined,
  authenticatingSelector: (state: StoreInterface) => state.user.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  redirectPath: '/login'
})

export const userIsAdminRedir = connectedRouterRedirect({
  authenticatedSelector: (state: StoreInterface) => state.user.data !== undefined && state.user.data.role === USER_ROLE.ADMIN,
  authenticatingSelector: (state: StoreInterface) => state.user.isLoading,
  wrapperDisplayName: 'UserIsAdmin',
  allowRedirectBack: false,
  redirectPath: '/events'
})

const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: (state: StoreInterface) => state.user.data === undefined && state.user.isLoading === false,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  // tslint:disable-next-line:typedef
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/events',
  allowRedirectBack: false
})
