import { UserFormInterface, OwnPropsInterface } from './types'

type UserFormValidation = {
  [F in keyof UserFormInterface]?: string
}

const REQUIRED_FIELD = 'Поле обязательно для заполнения'
const NO_SPACES_ALLOWED = 'Пробелы в этом поле запрещены'

export function validate(values: UserFormInterface, props: OwnPropsInterface): UserFormValidation {
  const errors: UserFormValidation = {}

  if (!values.name) {
    errors.name = REQUIRED_FIELD
  }
  if (!values.login) {
    errors.login = REQUIRED_FIELD
  }
  if (!values.role) {
    errors.role = REQUIRED_FIELD
  }

  if (values.login && values.login.indexOf(' ') !== -1) {
    errors.login = NO_SPACES_ALLOWED
  }

  if (props.passwordRequired && !values.password) {
    errors.password = REQUIRED_FIELD
  }

  if (values.password && values.password.indexOf(' ') !== -1) {
    errors.password = NO_SPACES_ALLOWED
  }

  return errors
}
