import { MealFormat } from 'types/models'

import { MealFormatFormInterface } from './Form'

type MealFormatFormValidation = {
  [F in keyof MealFormatFormInterface]?: string
}

export function validate(values: MealFormatFormInterface): MealFormatFormValidation {
  const errors: MealFormatFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  return errors
}

export function formatToForm(values: MealFormat): MealFormatFormInterface {
  return {
    name: values.name
  }
}
