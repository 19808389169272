import React from 'react'
import { Form, InjectedFormProps, reduxForm, Field } from 'redux-form'
import { Row, Button, Col } from 'antd'
import * as R from 'ramda'
import { TextField, TextAreaField, RadioField, SelectField } from 'redux-form-antd'
import { EVENT_FORM, AGE_RATINGS } from 'constants/Event'
import { EventType, MealFormat, User, USER_ROLE } from 'types/models'
import DatePicker from 'components/shared/form/datePicker/DatePicker'
// import { USER_ROLE } from '../types/models'

import * as styles from '../form.styl'

type OwnProps = {
  eventTypes: EventType[]
  mealFormats: MealFormat[] 
  currentUserRole?: string
  users?: User[]
}
type Props = InjectedFormProps<{}, OwnProps> & OwnProps

const FirstStep: React.FC<Props> = (props: Props) => (
  <Form onSubmit={props.handleSubmit}>
    <Row>
      <Col className={styles.firstStepForm}>
        {
          props.currentUserRole && props.currentUserRole === USER_ROLE.ADMIN &&
          <>
            <label>Менеджер проекта</label>
            <Field
              name='authorId'
              component={SelectField}
              options={
                R.map(
                  (user: User) => ({ label: user.name, value: user.id }),
                  props.users
                )
              }
              placeholder='Менеджер проекта'
            />
          </>
        }
        <label>Название мероприятия</label>
        <Field
          name='name'
          component={TextField}
          placeholder='Самый лучший концерт'
        />
        <label>Тип мероприятия</label>
        <Field
          name='typeId'
          component={SelectField}
          options={
            R.map(
              (eventType: EventType) => ({ label: eventType.name, value: eventType.id }),
              props.eventTypes
            )
          }
          placeholder='Тип мероприятия'
        />
        <label>Краткое описание мероприятия</label>
        <Field className={styles.descriptionTextArea}
          name='description'
          component={TextAreaField}
          placeholder='Написать краткое описание механики. Например: «Однодневное мероприятие: посетители с утра приходят на кофе-брейк, затем посещают на основную программу в главном зале, завершается мероприятие банкетом в фойе второго этажа КЦ. Фотозоны второй этаж, застройки нет, первый этаж зона регистрации.»'
        />
        <label>Целевая аудитория</label>
        <Field
          name='targetAudience'
          component={TextAreaField}
          placeholder='Описание целевой аудитории'
        />
        <label>Количество людей на площадке по дням через запятую</label>
        <Field
          name='participantCount'
          component={TextField}
          style={{ width: '100%' }}
          placeholder='100,200,80'
        />
        <label>Формат питания</label>
        <Field
          name='mealFormatId'
          component={SelectField}
          options={
            R.map(
              (mealFormat: MealFormat) => ({ label: mealFormat.name, value: mealFormat.id }),
              props.mealFormats
            )
          }
          placeholder='Формат питания'
        />
        <label>Количество питающихся людей по предполагаемому формату питания по дням через запятую</label>
        <Field
          name='participantCountMeal'
          component={TextField}
          style={{ width: '100%' }}
          placeholder='20,20,50'
        />
        <label>Сайт</label>
        <Field
          name='website'
          component={TextField}
          placeholder='https://example.com'
        />
        <label>Возрастная категория</label>
        <Field
          name='ageRating'
          component={SelectField}
          options={AGE_RATINGS.map((age: string) => ({
            label: age,
            value: age
          }))}
        />
        <label>Начало мероприятия</label>
        <Field
          name='startDate'
          component={DatePicker}
          hasFeedback={false}
          showTime
        />
        <label>Продажа алкоголя</label>
        <Field
          name='isSellingAlcohol'
          component={RadioField}
          options={[
            {
              label: 'Да',
              value: true
            },
            {
              label: 'Нет',
              value: false
            }
          ]}
          hasFeedback={false}
        />
        {
          props.currentUserRole && props.currentUserRole === USER_ROLE.ADMIN &&
          <>
            <label>Контроль квадратуры</label>
            <Field
              name='enableSquareCheck'
              component={RadioField}
              options={[
                {
                  label: 'Да',
                  value: true
                },
                {
                  label: 'Нет',
                  value: false
                }
              ]}
              hasFeedback={false}
            />
          </>
        }
        <label>ID сделки в CRM</label>
        <Field
          name='crmId'
          component={TextField}
          placeholder='31337'
        />
      </Col>
    </Row>
    <Row>
      <Col className={styles.buttonsWrapper}>
        <Button
          type='primary'
          htmlType='submit'
        >
          Далее
        </Button>
      </Col>
    </Row>
  </Form>
)

export default reduxForm<{}, { eventTypes: EventType[], mealFormats: MealFormat[], users?: User[], currentUserRole?: string }>({
  form: EVENT_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FirstStep)
