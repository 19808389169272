import React from 'react'
import * as R from 'ramda'
import { Table, Button, Popconfirm } from 'antd'
import { EVENT_RESERVATION_TYPE, IUsageFormat, IConfigs, Timezone, Room } from 'types/models'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import axios from 'axios'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'

import { BookingFormInterface } from '../../types/form'
import * as styles from './styles.styl'
import urljoin from 'url-join'
import config from 'common/config'

interface Props {
    items?: BookingFormInterface[]
    rooms: Room[],
    timezone?: any,
    reviewMode?: boolean,
    shortModal?: boolean,
    reservationType?: string,
    onRemove?(id: number): void,
    onEdit?(record: BookingFormInterface): void,
}

interface BookingsStateInterface {
    columns: ColumnProps<BookingFormInterface>[]
    usagesFormatsAxios: IUsageFormat[]
    timezoneAxios: Timezone[]
    configsAxios: IConfigs[]
}

class BookingTable extends Table<BookingFormInterface> { }

class Bookings extends React.Component<Props, BookingsStateInterface> {
    private readonly columnsReviewConcluding: ColumnProps<BookingFormInterface>[];
    // private readonly columnConfigs: ColumnProps<BookingFormInterface>[];
    public state: BookingsStateInterface = {
        columns: [
            {
                title: 'Помещение',
                dataIndex: 'roomId',
                key: 'roomId',
                render: (value: number) => {
                    const room: Room = R.find(R.propEq('id', value))(this.props.rooms)
                    return room && room.name
                }
            },
            {
                title: 'Сколько занимаем',
                dataIndex: 'squares',
                key: 'squares',
                render: (squares: string | undefined, record: BookingFormInterface) => {
                    // const room: Room = R.find(R.propEq('id', record.roomId))(this.props.rooms)
                    // return room.isPavilion ? squares : '-'
                    return squares ? squares : '-'
                }
            },
            {
                title: "Даты мероприятия",
                children: [
                    {
                        title: 'Даты проведения',
                        dataIndex: 'dates',
                        key: 'dates',
                        render: (text: string, record: BookingFormInterface,) => {
                            let output: string = '',
                                outputAlt: string = '';

                            if (record && record.dates) {
                                output = [
                                    this.props.timezone ? moment(record.dates[0]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.dates[0]).format(HUMAN_DATETIME_FORMAT),
                                    this.props.timezone ? moment(record.dates[1]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.dates[1]).format(HUMAN_DATETIME_FORMAT)
                                ].join(' - ');
                            }

                            if (record && record.datesAlt && record.datesAlt.length == 2) {
                                outputAlt = [
                                    this.props.timezone ? moment(record.datesAlt[0]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.datesAlt[0]).format(HUMAN_DATETIME_FORMAT),
                                    this.props.timezone ? moment(record.datesAlt[1]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.datesAlt[1]).format(HUMAN_DATETIME_FORMAT)
                                ].join(' - ');
                            }

                            if (output != '') {

                                if (outputAlt == '') {
                                    return output;
                                } else {
                                    return (<div><u className={styles.underline}>Оснв.:</u>{output}<br /><u className={styles.underline}>Альт.:</u>{outputAlt}</div>);
                                }
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        title: 'Даты монтажа',
                        dataIndex: 'installingDates',
                        key: 'installingDates',
                        render: (text: string, record: BookingFormInterface,) => {
                            let output: string = '',
                                outputAlt: string = '';

                            if (record && record.installingDates) {
                                output = [
                                    this.props.timezone ? moment(record.installingDates[0]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.installingDates[0]).format(HUMAN_DATETIME_FORMAT),
                                    this.props.timezone ? moment(record.installingDates[1]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.installingDates[1]).format(HUMAN_DATETIME_FORMAT)
                                ].join(' - ');
                            }

                            if (record && record.installingDatesAlt && record.installingDatesAlt.length == 2) {
                                outputAlt = [
                                    this.props.timezone ? moment(record.installingDatesAlt[0]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.installingDatesAlt[0]).format(HUMAN_DATETIME_FORMAT),
                                    this.props.timezone ? moment(record.installingDatesAlt[1]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.installingDatesAlt[1]).format(HUMAN_DATETIME_FORMAT)
                                ].join(' - ');
                            }

                            if (output != '') {

                                if (outputAlt == '') {
                                    return output;
                                } else {
                                    return (<div><u className={styles.underline}>Оснв.:</u>{output}<br /><u className={styles.underline}>Альт.:</u>{outputAlt}</div>);
                                }
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        title: 'Даты демонтажа',
                        dataIndex: 'dismantlingDates',
                        key: 'dismantlingDates',

                        render: (text: string, record: BookingFormInterface,) => {
                            let output: string = '',
                                outputAlt: string = '';

                            if (record && record.dismantlingDates) {
                                output = [
                                    this.props.timezone ? moment(record.dismantlingDates[0]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.dismantlingDates[0]).format(HUMAN_DATETIME_FORMAT),
                                    this.props.timezone ? moment(record.dismantlingDates[1]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.dismantlingDates[1]).format(HUMAN_DATETIME_FORMAT)
                                ].join(' - ');
                            }

                            if (record && record.dismantlingDatesAlt && record.dismantlingDatesAlt.length == 2) {
                                outputAlt = [
                                    this.props.timezone ? moment(record.dismantlingDatesAlt[0]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.dismantlingDatesAlt[0]).format(HUMAN_DATETIME_FORMAT),
                                    this.props.timezone ? moment(record.dismantlingDatesAlt[1]).utcOffset(this.props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(record.dismantlingDatesAlt[1]).format(HUMAN_DATETIME_FORMAT)
                                ].join(' - ');
                            }

                            if (output != '') {

                                if (outputAlt == '') {
                                    return output;
                                } else {
                                    return (<div><u className={styles.underline}>Оснв.:</u>{output}<br /><u className={styles.underline}>Альт.:</u>{outputAlt}</div>);
                                }
                            } else {
                                return '';
                            }
                        }
                    },
                ],
            },
            {
                key: 'configs',
                title: "Конфигурации",
                render: (text: string, record: BookingFormInterface) => {
                    if (record.configs && record.configs.length > 0) {
                        const checkeds: any[] = record.configs;

                        const configs = this.state.configsAxios.filter(({id}) => {
                            return record.configs && checkeds.includes(id);
                        });

                        return configs.map(item => item.name).join(', ');
                    }

                    return <div>Ничего не выбрано</div>;
                }
            },
        ],
        usagesFormatsAxios: [],
        timezoneAxios: [],
        configsAxios: []
    }

    constructor(props: Props) {
        super(props);

        this.getUsageFormats();
        this.getConfigs();
        this.getTimezone();

        /*
        this.columnConfigs = [
            {
                key: 'configs',
                title: "Конфигурации",
                render: (text: string, record: BookingFormInterface) => {
                    if (record.configs && record.configs.length > 0) {
                        const checkeds: any[] = record.configs;

                        const configs = this.state.configsAxios.filter(({id}) => {
                            return record.configs && checkeds.includes(id);
                        });

                        return configs.map(item => item.name).join(', ');
                    }

                    return <div>Ничего не выбрано</div>;
                }
            },
        ];
        */

        this.columnsReviewConcluding = [
            {
                key: 'usageFormats',
                title: "Формат использования",
                render: (text: string, record: BookingFormInterface) => {
                    if (record.usageFormats && record.usageFormats.length > 0) {
                        const checkeds: any[] = record.usageFormats;

                        const usagesFormats = this.state.usagesFormatsAxios.filter(({id}) => {
                            return record.usageFormats && checkeds.includes(id);
                        });

                        return usagesFormats.map(item => item.name).join(', ');
                    }

                    // return <div className='table-error' style={{color: 'red'}}>Ничего не выбрано</div>;
                    return 'Ничего не выбрано';
                }
            },
            {
                key: 'countPeople',
                title: "Кол-во посетителей",
                render: (text: string, record: BookingFormInterface) => {
                    if ( record.countPeople ) {
                        return record.countPeople;
                    }
                    // return <div className='table-error' style={{color: 'red'}}>{0}</div>
                    return <span>{0}</span>
                }
            }
        ];
    }

    public async getUsageFormats() {
        const req = await axios.get(urljoin(config.backendUrl, 'usage-formats'));
        const data: IUsageFormat[] = req.statusText == 'OK' ? req.data : [];

        this.setState({usagesFormatsAxios: data});
    }

    public async getTimezone() {
        const req = await axios.get(urljoin(config.backendUrl, 'timezone'));
        const data: Timezone[] = req.statusText == 'OK' ? req.data.value : [];
        this.setState({timezoneAxios: data});
    }

    public async getConfigs() {
        const req = await axios.get(urljoin(config.backendUrl, 'roomTypesConfigs'));
        const data: IConfigs[] = req.statusText == 'OK' ? req.data : [];

        this.setState({configsAxios: data});
    }

    componentDidMount(): void {
        const isReviewConcluding = this.props.reviewMode && this.props.reservationType === EVENT_RESERVATION_TYPE.CONCLUDING

        if (isReviewConcluding) {
            if ( this.props.onEdit && this.props.shortModal ) {
                this.setState(({ columns }) => {
                    return {
                        columns: [
                            ...columns,
                            // ...this.columnConfigs,
                            ...this.columnsReviewConcluding,
                            {
                                title: null,
                                dataIndex: 'edit',
                                key: 'edit',
                                render: (text: string, record: BookingFormInterface) =>
                                    this.props.onEdit &&
                                    <Button
                                        type='primary'
                                        onClick={() => { if (this.props.onEdit) { this.props.onEdit(record) } }}
                                    >
                                        Изменить
                                    </Button>
                            }
                        ]
                    }
                });
            } else {
                this.setState(({ columns }) => {
                    return {
                        columns: [
                            ...columns,
                            // ...this.columnConfigs,
                            ...this.columnsReviewConcluding
                        ]
                    }
                });
            }
        } else {
            const removeColumns = [...this.columnsReviewConcluding.map(({ key }) => key), 'edit'];
            this.setState(({ columns }) => {
                return {
                    columns: [
                        ...columns.filter(({ key }) => !removeColumns.includes(key)),
                        // ...this.columnConfigs,
                    ]
                }
            });
        }

        if ( this.props.onEdit ) {

            if ( this.props.shortModal ) {
                this.setState(({ columns }) => {
                    const removeEditAndRemove: (React.Key | undefined)[] = ['edit', 'actions'];
                    return {
                        columns: [
                            ...columns.filter(({key}) => !removeEditAndRemove.includes(key)),
                        ]
                    }
                });
            } else {
                this.setState(({ columns }) => {
                    return {
                        columns: [
                            ...columns,
                            // ...this.columnConfigs,
                            {
                                title: null,
                                dataIndex: 'edit',
                                key: 'edit',
                                render: (text: string, record: BookingFormInterface) =>
                                    this.props.onEdit &&
                                    <Button
                                        type='primary'
                                        onClick={() => { if (this.props.onEdit) { this.props.onEdit(record) } }}
                                    >
                                        Изменить
                                    </Button>
                            }
                        ]
                    }
                });
            }
        }

        if ( this.props.onRemove && !this.props.shortModal ) {
            this.setState(({ columns }) => {
                return {
                    columns: [
                        ...columns,
                        {
                            title: null,
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (text: string, record: BookingFormInterface) =>
                                this.props.onRemove &&
                                <Popconfirm
                                    title='Удалить данное бронирование?'
                                    onConfirm={() => { if (this.props.onRemove) { this.props.onRemove(record.roomId) } }}
                                >
                                    <Button
                                        type='danger'
                                    >
                                        Удалить
                                    </Button>
                                </Popconfirm>
                        }
                    ]
                }
            });
        }
    }

    componentDidUpdate(prevProps: Props): void {
        const isReviewConcluding = this.props.reviewMode && this.props.reservationType === EVENT_RESERVATION_TYPE.CONCLUDING

        if (prevProps.reservationType !== this.props.reservationType) {
            if (isReviewConcluding) {
                if ( this.props.onEdit && this.props.shortModal ) {
                    this.setState(({ columns }) => {
                        return {
                            columns: [
                                ...columns,
                                // ...this.columnConfigs,
                                ...this.columnsReviewConcluding,
                                {
                                    title: null,
                                    dataIndex: 'edit',
                                    key: 'edit',
                                    render: (text: string, record: BookingFormInterface) =>
                                        this.props.onEdit &&
                                        <Button
                                            type='primary'
                                            onClick={() => { if (this.props.onEdit) { this.props.onEdit(record) } }}
                                        >
                                            Изменить
                                        </Button>
                                }
                            ]
                        }
                    });
                } else {
                    this.setState(({ columns }) => {
                        return {
                            columns: [
                                ...columns,
                                // ...this.columnConfigs,
                                ...this.columnsReviewConcluding
                            ]
                        }
                    });
                }
            } else {
                const removeColumns = [...this.columnsReviewConcluding.map(({ key }) => key), 'edit'];

                this.setState(({ columns }) => {
                    return {
                        columns: columns.filter(({ key }) => !removeColumns.includes(key))
                    }
                });
            }
        }
    }

    public render(): React.ReactNode {

        return (
            <BookingTable
                bordered
                dataSource={this.props.items}
                columns={this.state.columns}
            />
        )
    }
}

export default Bookings
