exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContentListModal__modal___19uhL {\n  top: 50px !important;\n}\n.ContentListModal__slider___gReNF {\n  width: 100%;\n}\n.ContentListModal__slider___gReNF .slick-arrow {\n  color: #000 !important;\n  font-size: 16px !important;\n  width: auto !important;\n  z-index: 15;\n  padding: 0 16px !important;\n}\n.ContentListModal__imageSlide___20IM0 {\n  margin: 0 auto;\n  text-align: center;\n  max-height: 70vh;\n  max-width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "ContentListModal__modal___19uhL",
	"slider": "ContentListModal__slider___gReNF",
	"imageSlide": "ContentListModal__imageSlide___20IM0"
};