import React from 'react'
import Form, { FormComponentProps } from 'antd/lib/form'
import { Col, DatePicker, Row, Input, Select } from 'antd'
import { Moment } from 'moment'
import { EVENT_RESERVATION_TYPE, Room } from 'types/models'
import urljoin from 'url-join'
import config from 'common/config'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'
import axios from 'axios'
import { rangeSelectionToMomentDateRange } from 'utils/date.utils'
import moment from 'moment'

import * as styles from './styles.styl'
import { IUsageFormat } from './../../../../../../../types/models';
import { IConfigs } from './../../../../../../../types/models';
import { NULLABLE } from 'components/pages/Events/Index/types'

const FIELD_REQUIRED_MESSAGE = 'Поле обязательно для заполнения'
const DATES_SHOULD_NOT_BE_EQUAL_MESSAGE = 'Даты не должны совпадать'

export const EVENT_BOOKING_FORM = 'BOOKING_FORM'

export interface EventBookingFormInterface {
    id: number,
    dismantlingDates?: [Moment, Moment]
    installingDates?: [Moment, Moment]
    dates?: [Moment, Moment]
    squares?: string
    dismantlingDatesAlt?: [Moment, Moment]
    installingDatesAlt?: [Moment, Moment]
    datesAlt?: [Moment, Moment]
    countPeople?: number,
    usageFormats?: any[],
    configs?: any[],
}
interface OwnPropsInterface {
    enableSquareCheck: Boolean
    currentValues?: EventBookingFormInterface
    room: Room
    bookingsExclude?: number[]
    shortModal?: boolean
    timezone?: any
    onSuccess(data: EventBookingFormInterface): void
    onEditSuccess(dataOld: EventBookingFormInterface, dataNew: EventBookingFormInterface): void
}
export interface FormComponentInterface extends FormComponentProps<EventBookingFormInterface>, OwnPropsInterface {
}

// tslint:disable-next-line:no-any
function validateSquare(rule: any, value: any, callback: (message?: string) => {}): void {
    if (!value) {
        callback(FIELD_REQUIRED_MESSAGE)
        return
    }

    callback()
}

function validateCountPeople (rule: any, value: number, callback: (message?: string) => {}): void {
    // if ( !value || value === 0 ) {
    //     callback(FIELD_REQUIRED_MESSAGE)
    //     return
    // }

    callback()
}

function validateUsageFormats (rule: any, value: number[], callback: (message?: string) => {}): void {
    // if ( value.length <= 0 ) {
    //     callback(FIELD_REQUIRED_MESSAGE)
    //     return
    // }

    callback();
    return
}

function validateConfigs (rule: any, value: number[], callback: (message?: string) => {}): void {
    // if ( value.length <= 0 ) {
    //     callback(FIELD_REQUIRED_MESSAGE)
    //     return
    // }

    callback();
    return
}

// tslint:disable-next-line:no-any
function validator(rule: any, value: [Moment, Moment] | undefined, callback: (message?: string) => {}): void {
    if (!value) {
        callback(FIELD_REQUIRED_MESSAGE)
        return
    }

    if (value[0].diff(value[1]) === 0) {
        callback(DATES_SHOULD_NOT_BE_EQUAL_MESSAGE)
        return
    }

    callback()
}

interface BookingFormInterface {
    usagesFormatsAxios?: IUsageFormat[],
    configsAxios?: IConfigs[],
}

class BookingForm extends React.Component<FormComponentInterface, BookingFormInterface> {
    public state: BookingFormInterface = {
        usagesFormatsAxios: [],
        configsAxios: []
    }

    componentDidMount() {
        this.getConfigs();
        if ( this.props.shortModal ) {
            this.getUsageFormats();  
        }
    }

    public getEventIdFromUrl(){
        let eventId,
            isNum = false,
            pathname = window.location.pathname.split('/');

        if (pathname[2]){
          eventId = pathname[2];
          isNum = /^\d+$/.test(eventId);
        }

        // if (!isNaN(eventId) || eventId == 'create'){
        if (!isNum || eventId == 'create'){
          eventId = 0;
        }

        return eventId;
    }

    public async getDatesConflit(dates)
    {
        // let range = rangeSelectionToMomentDateRange(dates),
            // datesStart = range.start.startOf('day').toISOString(),
            // datesEnd = range.end.endOf('day').toISOString(),
          
        let datesStart = moment(dates[0]).toISOString(),
            datesEnd = moment(dates[1]).toISOString(),
            eventId = this.getEventIdFromUrl();

        // let datesStart = moment(dates[0]).subtract(5, 'hours').toISOString(),
        //     datesEnd = moment(dates[1]).subtract(5, 'hours').toISOString(),
        //     eventId = this.getEventIdFromUrl();

        return await axios.get(urljoin(config.backendUrl, 'events'), {
            params: {
                room: this.props.room.id,
                excludeEvent: eventId,
                checkAllDatesStart: datesStart,
                checkAllDatesEnd: datesEnd,
                archivedAt: NULLABLE.NULL,
                excludeBookings: (this.props.bookingsExclude) ? this.props.bookingsExclude : [],
                // eventReservation: EVENT_RESERVATION_TYPE.CONCLUDING
            }
        });
    }

    public processConflictResponse(response) {

        let error,
            errorArr: string[] = [];

        response.data.forEach(event => {
            errorArr.push('- ' + event.name);
        });

        if (errorArr.length > 0) {
            error = new Error("Обнаружены конфликты с мероприятиями:\r\n" + errorArr.join(";\r\n"))
        }

        return error;

    }

    public async getRoomBookings(values) {

        let datesStart, datesEnd, datesStartAlt, datesEndAlt,
            eventId = this.getEventIdFromUrl();

        if (values.dates && values.dates[0] && values.dates[1]) {
            let rangeDates = rangeSelectionToMomentDateRange(values.dates);
            datesStart = rangeDates.start.startOf('day').toISOString();
            datesEnd = rangeDates.end.endOf('day').toISOString();
        }

        if (values.datesAlt && values.datesAlt[0] && values.datesAlt[1]) {
            let rangeDatesAlt = rangeSelectionToMomentDateRange(values.dates);
            datesStartAlt = rangeDatesAlt.start.startOf('day').toISOString();
            datesEndAlt = rangeDatesAlt.end.endOf('day').toISOString();
        }

        return await axios.get(urljoin(config.backendUrl, 'events'), {
            params: {
                room: this.props.room.id,
                excludeEvent: eventId,
                bookingDatesStart: datesStart,
                bookingDatesEnd: datesEnd,
                bookingDatesStartAlt: datesStartAlt,
                bookingDatesEndAlt: datesEndAlt,
                eventReservation: EVENT_RESERVATION_TYPE.CONCLUDING,
                archivedAt: NULLABLE.NULL,
                excludeBookings: (this.props.bookingsExclude) ? this.props.bookingsExclude : [],
            }
        });
    }

    public async getUsageFormats() {
        const req = await axios.get(urljoin(config.backendUrl, 'usage-formats'));
        const data: IUsageFormat[] = req.statusText == 'OK' ? req.data : [];

        this.setState({usagesFormatsAxios: data});
    }

    public async getConfigs() {
        const req = await axios.get(urljoin(config.backendUrl, 'roomTypesConfigs'));
        const data: IConfigs[] = req.statusText == 'OK' ? req.data : [];

        this.setState({configsAxios: data});
    }

    public handleSubmit = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        e.preventDefault()
        // tslint:disable-next-line: no-any
        this.props.form.validateFields((err: any, values: EventBookingFormInterface) => {

            let datesToCheck = {},
                counter = 0,
                mapKeys = {};

            if (values.dates && values.dates[0] && values.dates[1]) {
                datesToCheck['dates'] = values.dates;
                mapKeys[counter] = 'dates';
                counter++;
            }
            if (values.datesAlt && values.datesAlt[0] && values.datesAlt[1]) {
                datesToCheck['datesAlt'] = values.datesAlt;
                mapKeys[counter] = 'datesAlt';
                counter++;
            }
            /*
            if (values.dismantlingDates && values.dismantlingDates[0] && values.dismantlingDates[1]) {
                datesToCheck['dismantlingDates'] = values.dismantlingDates;
                mapKeys[counter] = 'dismantlingDates';
                counter++;
            }
            if (values.dismantlingDatesAlt && values.dismantlingDatesAlt[0] && values.dismantlingDatesAlt[1]) {
                datesToCheck['dismantlingDatesAlt'] = values.dismantlingDatesAlt;
                mapKeys[counter] = 'dismantlingDatesAlt';
                counter++;
            }
            if (values.installingDates && values.installingDates[0] && values.installingDates[1]) {
                datesToCheck['installingDates'] = values.installingDates;
                mapKeys[counter] = 'installingDates';
                counter++;
            }
            if (values.installingDatesAlt && values.installingDatesAlt[0] && values.installingDatesAlt[1]) {
                datesToCheck['installingDatesAlt'] = values.installingDatesAlt;
                mapKeys[counter] = 'installingDatesAlt';
                counter++;
            }
            */

            if (
                values.squares &&
                values.squares != undefined &&
                this.props.room.squares &&
                this.props.room.squares !== null &&
                this.props.room.squares != undefined
            ) {
                datesToCheck['squares'] = values.squares;
                mapKeys[counter] = 'squares';
            }

            let promises: Promise<any>[] = [];
            for (var key in datesToCheck) {
                if (key == 'squares') {
                    if (this.props.enableSquareCheck.toString() == 'true') {
                        promises.push(this.getRoomBookings(values));
                    }
                } else {
                    promises.push(this.getDatesConflit(datesToCheck[key]));
                }
            }

            Promise.all(promises)
                .then((responses) => {
                    let fieldObj = {};

                    for (var respKey in responses) {
                        if (mapKeys[respKey] == 'squares') {

                            if (responses[respKey].data && values.squares) {
                                let squaresSumm = 0,
                                    squaresCurrent = parseInt(values.squares),
                                    squaresMax = this.props.room.squares,
                                    errorArr: string[] = [];

                                responses[respKey].data.forEach(event => {
                                    if (event.bookings) {
                                        event.bookings.forEach(booking => {
                                            if (booking.squares) {
                                                squaresSumm = squaresSumm + parseInt(booking.squares);
                                                errorArr.push('- ' + event.name + ' - ' + booking.squares + ' кв.');
                                            }
                                        });
                                    }
                                });

                                // squaresMax = 23; // For testing purposes
                                if (squaresMax && squaresSumm + squaresCurrent > squaresMax) {
                                    err = true;
                                    let squaresLeft = squaresMax - squaresSumm;
                                    fieldObj[mapKeys[respKey]] = {
                                        errors: [new Error("Всего кв. в павильоне: " + squaresMax + ". Осталось: " + squaresLeft + ".\r\n" + errorArr.join(";\r\n"))]
                                    }
                                }
                            }

                        } else {
                            if (responses[respKey].data) {
                                let error = this.processConflictResponse(responses[respKey]);

                                if (error) {
                                    err = true;
                                    fieldObj[mapKeys[respKey]] = {
                                        errors: [error]
                                    }
                                }
                            }
                        }
                    }

                    if (fieldObj) {
                        this.props.form.setFields(fieldObj);
                    }

                    if (!err) {
                        if (this.props.currentValues) {
                            this.props.onEditSuccess(this.props.currentValues, values)
                        } else {
                            this.props.onSuccess(values)
                        }
                    }
                })
                .catch(error => {
                    console.log("Axios err: ", error)
                });

            /*
            if (!err) {
              if (this.props.currentValues){
                this.props.onEditSuccess(this.props.currentValues, values)
              } else {
                this.props.onSuccess(values)
              }
            }
            */
        })
    }

    public getImage(): React.ReactNode {
        const { room } = this.props

        return (
            <img
                src={urljoin(config.backendUrl, room.schemaUrl || '')}
                alt='Схема'
                width='100%'
            />
        )
    }

    public getForm(isPavilion: boolean): React.ReactNode {
        const { currentValues, timezone, form: { getFieldDecorator } } = this.props

        let defSquares = '',
            defInstallingDates: Moment[] = [],
            defDismantlingDates: Moment[] = [],
            defDates: Moment[] = [],
            defInstallingDatesAlt: Moment[] = [],
            defDismantlingDatesAlt: Moment[] = [],
            defDatesAlt: Moment[] = [];

        let defCountPeople = 0;
        let defUsageFormats: any[] = [];
        let defConfigs: any[] = [];
        let checkedUsageFormats: any[] = [];
        let checkedConfigs: any[] = [];

        if (currentValues) {
            if (currentValues.squares) { defSquares = currentValues.squares; }
            if (currentValues.dates) {
                defDates = currentValues.dates;

                if (timezone && defDates[0] && defDates[1]) {
                    defDates[0] = defDates[0].utcOffset(timezone);
                    defDates[1] = defDates[1].utcOffset(timezone);
                }
            }
            if (currentValues.installingDates) {
                defInstallingDates = currentValues.installingDates;

                if (timezone && defInstallingDates[0] && defInstallingDates[1]) {
                    defInstallingDates[0] = defInstallingDates[0].utcOffset(timezone);
                    defInstallingDates[1] = defInstallingDates[1].utcOffset(timezone);
                }
            }
            if (currentValues.dismantlingDates) {
                defDismantlingDates = currentValues.dismantlingDates;

                if (timezone && defDismantlingDates[0] && defDismantlingDates[1]) {
                    defDismantlingDates[0] = defDismantlingDates[0].utcOffset(timezone);
                    defDismantlingDates[1] = defDismantlingDates[1].utcOffset(timezone);
                }
            }
            if (currentValues.datesAlt) {
                defDatesAlt = currentValues.datesAlt;

                if (timezone && defDatesAlt[0] && defDatesAlt[1]) {
                    defDatesAlt[0] = defDatesAlt[0].utcOffset(timezone);
                    defDatesAlt[1] = defDatesAlt[1].utcOffset(timezone);
                }
            }
            if (currentValues.installingDatesAlt) {
                defInstallingDatesAlt = currentValues.installingDatesAlt;

                if (timezone && defInstallingDatesAlt[0] && defInstallingDatesAlt[1]) {
                    defInstallingDatesAlt[0] = defInstallingDatesAlt[0].utcOffset(timezone);
                    defInstallingDatesAlt[1] = defInstallingDatesAlt[1].utcOffset(timezone);
                }
            }
            if (currentValues.dismantlingDatesAlt) {
                defDismantlingDatesAlt = currentValues.dismantlingDatesAlt;

                if (timezone && defDismantlingDatesAlt[0] && defDismantlingDatesAlt[1]) {
                    defDismantlingDatesAlt[0] = defDismantlingDatesAlt[0].utcOffset(timezone);
                    defDismantlingDatesAlt[1] = defDismantlingDatesAlt[1].utcOffset(timezone);
                }
            }

            if (this.props.shortModal) {
                if (currentValues.countPeople) defCountPeople = currentValues.countPeople
                if ( currentValues.usageFormats ) checkedUsageFormats = currentValues.usageFormats

                if ( this.state.usagesFormatsAxios ) {
                    defUsageFormats = this.state.usagesFormatsAxios;
                }
            }

            if ( currentValues.configs ) checkedConfigs = currentValues.configs
        }

        if ( this.state.configsAxios && this.state.configsAxios != undefined && this.props.room.configs)
        {
            let allowedConfigs = this.props.room.configs.toString().split(','),
                axiosConfigs = this.state.configsAxios;

            Object.keys(this.state.configsAxios).forEach(key => {
                if (allowedConfigs.includes(axiosConfigs[key].id.toString()))
                defConfigs.push(axiosConfigs[key]);
            });
        }

        return (
            <Form onSubmit={this.handleSubmit} id={EVENT_BOOKING_FORM}>
                <h3>Основные даты</h3>
                <Form.Item label='Даты монтажа' className={styles.errorNewLine} required>
                    {
                        getFieldDecorator('installingDates', {
                            initialValue: defInstallingDates,
                            rules: [{ validator }]
                        })(
                            <DatePicker.RangePicker
                                format={HUMAN_DATETIME_FORMAT}
                                className={styles.rangePicker}
                                showTime
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label='Даты проведения' className={styles.errorNewLine} required>
                    {
                        getFieldDecorator('dates', {
                            initialValue: defDates,
                            rules: [{ validator }]
                        })(
                            <DatePicker.RangePicker
                                format={HUMAN_DATETIME_FORMAT}
                                className={styles.rangePicker}
                                showTime
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label='Даты демонтажа' className={styles.errorNewLine} required>
                    {
                        getFieldDecorator('dismantlingDates', {
                            initialValue: defDismantlingDates,
                            rules: [{ validator }]
                        })(
                            <DatePicker.RangePicker
                                format={HUMAN_DATETIME_FORMAT}
                                className={styles.rangePicker}
                                showTime
                            />
                        )
                    }
                </Form.Item>
                <h3>Альтернативные даты</h3>
                <Form.Item label='Даты монтажа' className={styles.errorNewLine}>
                    {
                        getFieldDecorator('installingDatesAlt', {
                            initialValue: defInstallingDatesAlt,
                            rules: [{ required: false }]
                        })(
                            <DatePicker.RangePicker
                                format={HUMAN_DATETIME_FORMAT}
                                className={styles.rangePicker}
                                showTime
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label='Даты проведения' className={styles.errorNewLine}>
                    {
                        getFieldDecorator('datesAlt', {
                            initialValue: defDatesAlt,
                            rules: [{ required: false }]
                        })(
                            <DatePicker.RangePicker
                                format={HUMAN_DATETIME_FORMAT}
                                className={styles.rangePicker}
                                showTime
                            />
                        )
                    }
                </Form.Item>
                <Form.Item label='Даты демонтажа' className={styles.errorNewLine}>
                    {
                        getFieldDecorator('dismantlingDatesAlt', {
                            initialValue: defDismantlingDatesAlt,
                            rules: [{ required: false }]
                        })(
                            <DatePicker.RangePicker
                                format={HUMAN_DATETIME_FORMAT}
                                className={styles.rangePicker}
                                showTime
                            />
                        )
                    }
                </Form.Item>
                {
                    isPavilion && !this.props.shortModal &&
                    <Form.Item label='Квадраты' className={styles.errorNewLine} required>
                        {
                            getFieldDecorator('squares', {
                                initialValue: defSquares,
                                // rules: [{ required: true }]
                                rules: [{ validator: validateSquare }]
                            })(
                                <Input />
                            )
                        }
                    </Form.Item>
                }
                {
                    this.props.shortModal &&
                    <Form.Item label='Кол-во посетителей' className={styles.errorNewLine}>
                        {
                            getFieldDecorator('countPeople', {
                                initialValue: defCountPeople,
                                rules: [{ validator: validateCountPeople }]
                            })(
                                <Input />
                            )
                        }
                    </Form.Item>
                }

                {
                    this.props.shortModal && defUsageFormats &&
                    <Form.Item label='Форматы использования' className={styles.errorNewLine}>
                        {
                        getFieldDecorator('usageFormats', {
                            initialValue: checkedUsageFormats,

                            rules: [{ validator: validateUsageFormats }]
                        })(<Select
                            mode="multiple"
                            placeholder="Форматы использования"
                        >
                            {
                                defUsageFormats.map(item =>
                                    <Select.Option
                                        key={item.id}

                                        value={item.id}>{`${item.name}`}</Select.Option>
                                )
                            }
                        </Select>)
                        }
                    </Form.Item>
                }

                {
                    defConfigs &&
                    <Form.Item label='Конфигурации' className={styles.errorNewLine}>
                        {
                        getFieldDecorator('configs', {
                            initialValue: checkedConfigs,

                            rules: [{ validator: validateConfigs }]
                        })(<Select
                            mode="multiple"
                            placeholder="Конфигурации"
                        >
                            {
                                defConfigs.map(item =>
                                    <Select.Option
                                        key={item.id}

                                        value={item.id}>{`${item.name}`}</Select.Option>
                                )
                            }
                        </Select>)
                        }
                    </Form.Item>
                }
            </Form>
        )
    }

    public render(): React.ReactNode {
        const { room } = this.props

        if (room.isPavilion) {
            return (
                <Row gutter={16}>
                    {
                        !this.props.shortModal &&
                        <Col span={16}>
                            {this.getImage()}
                        </Col>
                    }
                    <Col span={room.isPavilion ? (!this.props.shortModal) ? 8 : 24 : (!this.props.shortModal) ? 8 : 24}>
                        {this.getForm(room.isPavilion)}
                    </Col>
                </Row>
            )
        } else {
            return (
                this.getForm(room.isPavilion)
            )
        }
    }
}

export default Form.create<FormComponentInterface>({ name: EVENT_BOOKING_FORM })(BookingForm)
