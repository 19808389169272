import React from 'react'
import moment from 'moment'
import Axios from 'axios'
import urljoin from 'url-join'
import download from 'downloadjs'
import config from 'common/config'
import { Button } from 'antd'
import { HUMAN_DATE_FORMAT } from 'constants/DateTime'

interface Props {
  startDate: moment.Moment
  endDate: moment.Moment
}

interface State {
  isDownloading: boolean
  isDownloadingSimple: boolean
}

class ReportDownloader extends React.Component<Props, State> {
  public state: State = {
    isDownloading: false,
    isDownloadingSimple: false
  }

  public render(): React.ReactNode {
    const { startDate, endDate } = this.props
    const { isDownloading, isDownloadingSimple } = this.state

    return (
      <>
        <Button
          onClick={() => this.downloadReport(startDate, endDate)}
          loading={isDownloading}
          disabled={isDownloading}
        >
          Экспорт в XLSX
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          onClick={() => this.downloadReportSimple(startDate, endDate)}
          loading={isDownloadingSimple}
          disabled={isDownloadingSimple}
        >
          Экспорт в XLSX (упрощённый)
        </Button>
      </>
    )
  }

  private async downloadReport(startDate: moment.Moment, endDate: moment.Moment): Promise<void > {
    if (this.state.isDownloading) {
      return
    }

    this.setState({ isDownloading: true }, async () => {
      try {
        const response = await Axios.get(
          urljoin(config.backendUrl, `/rooms/report`),
          {
            params: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD')
            },
            responseType: 'arraybuffer'
          }
        )

        download(
          response.data,
          `calendarReport${startDate.format(HUMAN_DATE_FORMAT)}-${endDate.format(HUMAN_DATE_FORMAT)}.xlsx`
        )

        this.setState({ isDownloading: false })
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error)
      }
    })
  }

  private async downloadReportSimple(startDate: moment.Moment, endDate: moment.Moment): Promise<void > {
    if (this.state.isDownloadingSimple) {
      return
    }

    this.setState({ isDownloadingSimple: true }, async () => {
      try {
        const response = await Axios.get(
          urljoin(config.backendUrl, `/rooms/report-simple`),
          {
            params: {
              startDate: startDate.format('YYYY-MM-DD'),
              endDate: endDate.format('YYYY-MM-DD')
            },
            responseType: 'arraybuffer'
          }
        )

        download(
          response.data,
          `calendarReportSimple${startDate.format(HUMAN_DATE_FORMAT)}-${endDate.format(HUMAN_DATE_FORMAT)}.xlsx`
        )

        this.setState({ isDownloadingSimple: false })
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error)
      }
    })
  }
}

export default ReportDownloader
