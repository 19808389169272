export enum Tabs {
  OWN = 'own',
  ALL = 'all',
  PENDING = 'pending',
  ARCHIVE = 'archive'
}

export const TAB_TO_TAB_NAME = {
  [Tabs.ALL]: 'Все мероприятия',
  [Tabs.OWN]: 'Мои мероприятия',
  [Tabs.PENDING]: 'На согласовании',
  [Tabs.ARCHIVE]: 'Архив'
}

export const scrollXWidth: number = 1800
export const sizeOfPaddings: number = 104
export const sizeOfPadginationControl: number = 75
