import React from 'react'
import * as R from 'ramda'
import { reduxForm, Field, Form } from 'redux-form'
import { SelectField } from 'redux-form-antd'
import { autobind } from 'core-decorators'
import { TIMEZONE_FORM } from 'constants/Timezone'

import { TimezoneFormInterface, Props } from './types'

export interface TimezoneValues {
    value: string
    label: string
}

const timezoneValues = [
    {
        value: '+02:00',
        label: "UTC +03:00 - Калининград"
    },
    {
        value: '+03:00',
        label: "UTC +03:00 - Москва"
    },
    {
        value: '+04:00',
        label: "UTC +04:00 - Астрахань"
    },
    {
        value: '+05:00',
        label: "UTC +05:00 - Екатеринбург"
    },
    {
        value: '+06:00',
        label: "UTC +06:00 - Омск"
    },
    {
        value: '+07:00',
        label: "UTC +07:00 - Новосибирск/Абакан"
    },
    {
        value: '+08:00',
        label: "UTC +08:00 - Улан-Удэ"
    },
    {
        value: '+09:00',
        label: "UTC +09:00 - Чита/Якутск"
    },
    {
        value: '+10:00',
        label: "UTC +10:00 - Хабаровск"
    },
    {
        value: '+11:00',
        label: "UTC +11:00 - Магадан/Южно-Сахалинск"
    },
    {
        value: '+12:00',
        label: "UTC +12:00 - Анадырь/Петропавловск-Камчатский"
    },
]

class TimezoneForm extends React.Component<Props> {
    @autobind
    public handleSubmit(values: TimezoneFormInterface): void {
        this.props.onSubmit(values)
    }

    public render(): React.ReactNode {
        return (
            <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                <label>Значение</label>
                <Field
                    name='value'
                    component={SelectField}
                    options={
                        R.map(
                            (timezoneValues: TimezoneValues) => ({ label: timezoneValues.label, value: timezoneValues.value }),
                            timezoneValues || []
                        )
                    }
                    placeholder='Не задано'
                />
                
            </Form>
        )
    }
}

export default reduxForm<{}>(
    {
        form: TIMEZONE_FORM,
        enableReinitialize: true,
    }
)(TimezoneForm)
