import { EVENT_STATUS, EVENT_RESERVATION_TYPE } from 'types/models'

export const EVENT_FORM: string = 'EVENT_FORM'

export const STATUS_TO_STRING = {
  [EVENT_STATUS.APPROVED]: 'Согласовано',
  [EVENT_STATUS.DECLINED]: 'Отклонено',
  [EVENT_STATUS.PENDING]: 'На рассмотрении'
}

export const RESERVATION_TYPE_TO_STRING = {
  [EVENT_RESERVATION_TYPE.CONCLUDING]: 'Железная',
  [EVENT_RESERVATION_TYPE.PRELIMINARY]: 'Предварительная'
}

export const EVENT_REVIEW_FORM: string = 'EVENT_REVIEW_FORM'

export const AGE_RATINGS = ['0+', '6+', '12+', '16+', '18+']
