import { autobind } from 'core-decorators'
import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { PageHeader, Button, message } from 'antd'
import urljoin from 'url-join'
import { submit } from 'redux-form'
import { Action } from 'redux'
import { Email } from 'types/models'
import { EMAIL_FORM } from 'constants/Email'
import config from 'common/config'

import Form from '../_shared/form/Form'
import { EmailFormInterface } from '../_shared/form/types'

interface Props extends RouteComponentProps {
  emailPostFetch: PromiseState<Email>

  dispatchEmailPost(data: EmailFormInterface): void

  dispatch(value: Action): void
}

class Create extends React.Component<Props> {
  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(EMAIL_FORM))
  }

  @autobind
  public handeSubmit(values: EmailFormInterface): void {
    this.props.dispatchEmailPost(values)
  }

  public componentDidUpdate(prevProps: Props): void {
    const fetch = this.props.emailPostFetch
    if (prevProps.emailPostFetch.pending && fetch.fulfilled && fetch.value) {
      message.success('Электронный адрес создан')
      this.props.history.push(`/admin/emails/${fetch.value.id}`)
    }
  }

  public render(): React.ReactNode {
    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Электронный адрес'
          subTitle='Создание'
          extra={[
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Создать
            </Button>
          ]}
        />
        <Form
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'email',
        name: 'emailPost'
      },
      method: 'post',
      request: (body: EmailFormInterface) => ({
        url: urljoin(config.backendUrl, 'emails'),
        body
      })
    }
  ]
)(Create)
