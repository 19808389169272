import React from 'react'
import { RcFile } from 'antd/lib/upload'
import { Table, Button, Popconfirm } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'
import urljoin from 'url-join'
import config from 'common/config'
import { autobind } from 'core-decorators'
import ContentListModal from './ContentListModal'

import { ContentFormInterface } from '../../types/form'

interface Props {
  rightTypes?: any[]
  fileMethods?: any[]
  timezone?: any
  items?: ContentFormInterface[]
  onRemove?(id: string): void
  onEdit?(record: ContentFormInterface): void,
}

interface State {
  modalOpen: boolean,
  selectedSlide: number
}

interface SliderFiles {
  name: string
}

class ContentTable extends Table<ContentFormInterface> {}

class ContentList extends React.Component<Props, State> {

  public state: State = {
      modalOpen: false,
      selectedSlide: 0
  }

  @autobind
  public handleModalClose(): void {
    this.setState({ modalOpen: false })
  }

  @autobind
  public handleModalOpen(slide: number): void {
    this.setState({ selectedSlide: slide, modalOpen: true, })
  }

  private readonly columns: ColumnProps<ContentFormInterface>[]

  // public handleClick = (e: React.SyntheticEvent) => {
  public handleClick = (slide: number) => {
    this.handleModalOpen(slide);
  }

  public getFiles(items: ContentFormInterface[] | undefined){

      let files: SliderFiles[] = [];

      if (items){
          items.forEach((item) => {

              if (item.file && item.file[0] && item.file[0].name && item.file[0].name.match(/.(jpg|jpeg|png|gif|svg|bmp|ico|webp)$/i)){

                  let filename = item.file[0].name,
                      countUpload = (filename.match(/uploads/g) || []).length;

                  if (countUpload > 0){
                      let nameArr = filename.split('/');

                      files.push({
                          name: nameArr[nameArr.length - 1]
                      });
                  }
              }
          });
      }

      return files;
  }

  constructor(props: Props) {

    super(props)
    let files = this.getFiles(this.props.items);

    this.columns = [
      {
        title: 'Прикреплённый файл',
        dataIndex: 'file',
        key: 'file',
        render: (file: RcFile) => {

          if (file)
          {
            if (file[0]){
              file = file[0];
            }

            let filename = file.name,
                countUpload = (filename.match(/uploads/g) || []).length;

            if (countUpload > 0){
              let nameArr = filename.split('/'),
                  url = urljoin(config.backendUrl, filename),
                  linkTarget="_blank";

              if (filename.match(/.(jpg|jpeg|png|gif|svg|bmp|ico|webp)$/i)){

                let slideNumber:number = 0;
                files.forEach((item, index) => {
                  if (item.name == nameArr[nameArr.length - 1]){
                    slideNumber = index;
                  }
                });

                return <a onClick={(e) => (e.preventDefault(), this.handleClick(slideNumber))} target={linkTarget} href={url}>{nameArr[nameArr.length - 1]}</a>
              } else {
                return <a target={linkTarget} href={url}>{nameArr[nameArr.length - 1]}</a>
              }
              
            } else {
              return filename;
            }
          }
        }
      },
      {
        title: 'Пределы прав',
        dataIndex: 'rightTypeId',
        key: 'rightTypeId',
        render: (rightTypeId: any) => {

          let result = rightTypeId;

          if (props.rightTypes)
          {
            props.rightTypes.forEach((element) => 
            {
              if (element.id.toString() === rightTypeId.toString())
              {
                result = element.name;
              }
            })
          }

          return result;
        }
      },
      {
        title: 'Способы',
        dataIndex: 'fileMethodId',
        key: 'fileMethodId',
        render: (fileMethodId: any) => {

          let result = fileMethodId;

          if (typeof fileMethodId === 'string'){
            fileMethodId = fileMethodId.split(',');
          }

          if (props.fileMethods && fileMethodId)
          {
            result = '';
            for(const methodId of fileMethodId){
              for(const element of props.fileMethods){
                if (element.id.toString() === methodId.toString())
                {
                  let delimiter = ', ';
                  if (result == ''){delimiter = '';}
                  result = result + delimiter + element.name;
                }
              }
            }
          }

          return result;
        }
      },
      {
        title: 'Сроки использования',
        dataIndex: 'usageDates',
        key: 'usageDates',
        render: ([start, end]: [moment.Moment, moment.Moment]) =>
          [
            props.timezone ? moment(start).utcOffset(props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(start).format(HUMAN_DATETIME_FORMAT),
            props.timezone ? moment(end).utcOffset(props.timezone).format(HUMAN_DATETIME_FORMAT) : moment(end).format(HUMAN_DATETIME_FORMAT)
          ].join(' - ')
      },
      {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        render: (text: string, record: ContentFormInterface) =>
          this.props.onEdit &&
            <Button
              type='primary'
              onClick={() => { if (this.props.onEdit) { this.props.onEdit(record) }}}
            >
              Изменить
            </Button>
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (text: string, record: ContentFormInterface) =>
          this.props.onRemove &&
            <Popconfirm
              title='Удалить данный контент?'
              onConfirm={() => { if (this.props.onRemove && record && record.contentId) { this.props.onRemove(record.contentId) }}}
            >
              <Button
                type='danger'
              >
                Удалить
              </Button>
            </Popconfirm>
      }
    ]
  }

  public render(): React.ReactNode {

    const { modalOpen, selectedSlide } = this.state

    return (
      <>
        <ContentTable
          rowKey="contentId"
          columns={this.columns}
          dataSource={this.props.items}
        />
        <ContentListModal
            items={this.props.items}
            visible={modalOpen}
            slide={selectedSlide}
            onClose={this.handleModalClose}
        />
      </>
    )
  }
}

export default ContentList