import { createComponent, customMap } from 'redux-form-antd'
import { DatePicker } from 'antd'
import { InputProps } from 'antd/lib/input'
import { DatePickerProps } from 'antd/lib/date-picker/interface'

function mapProps(props: DatePickerProps & InputProps): DatePickerProps & InputProps {
  return {
    ...props,
    onBlur: props.onBlur
      // tslint:disable-next-line:no-any
      ? () => { if (props.onBlur) { props.onBlur(props.value as any) } }
      : props.onBlur
  }
}

export default createComponent(DatePicker, customMap(mapProps))
