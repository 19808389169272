exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rooms__rooms___6hIS7 {\n  margin-bottom: 24px;\n}\n.rooms__scrollablePane___1eE2L {\n  overflow-x: auto;\n  overflow-y: hidden;\n  background-color: #fff;\n}\n.rooms__hideScrollbar___1gGB5 {\n  scrollbar-width: none;\n}\n.rooms__hideScrollbar___1gGB5::-webkit-scrollbar-thumb,\n.rooms__hideScrollbar___1gGB5::-webkit-scrollbar-track {\n  background: 0 0 !important;\n  -ms-overflow-style: none;\n  overflow: -moz-scrollbars-none;\n  -webkit-overflow-scrolling: touch;\n}\n.rooms__hideScrollbar___1gGB5::-webkit-scrollbar {\n  width: 0px;\n  height: 0px;\n  background: transparent;\n}\n.rooms__sideRow___1wIj2 {\n  height: 32px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  font-weight: 600;\n  line-height: 32px;\n  text-align: right;\n  padding-right: 24px;\n}\n.rooms__sideRow___1wIj2.rooms__spoiler___2ViLS {\n  cursor: pointer;\n  color: #1890ff;\n}\n.rooms__spoilerRow___3ByZG {\n  white-space: nowrap;\n}\n.rooms__spoilerRow___3ByZG .rooms__spoilerBlock___2ga1M {\n  border: 1px solid #f0f2f5;\n  background-color: #fafafa;\n  color: #000;\n  text-align: center;\n  line-height: 32px;\n  content: '';\n  display: inline-block;\n  width: 51.77px;\n  height: 32px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n.rooms__sideRowHeader___3h5zq {\n  line-height: 32px;\n  text-align: right;\n  padding-top: 8px;\n  padding-bottom: 16px;\n  padding-right: 24px;\n  background-color: #fff;\n}\n.rooms__sideRowHeader___3h5zq:first-child {\n  font-weight: 600;\n}\n.rooms__header___3SN0o {\n  white-space: nowrap;\n  background-color: #fff;\n}\n.rooms__headerBlock___edK30 {\n  line-height: 28px;\n  display: inline-block;\n  width: 51.77px;\n  text-transform: capitalize;\n  font-weight: 600;\n  text-align: center;\n}\n.rooms__weekend___2Ywl7 {\n  color: #f00;\n}\n", ""]);

// exports
exports.locals = {
	"rooms": "rooms__rooms___6hIS7",
	"scrollablePane": "rooms__scrollablePane___1eE2L",
	"hideScrollbar": "rooms__hideScrollbar___1gGB5",
	"sideRow": "rooms__sideRow___1wIj2",
	"spoiler": "rooms__spoiler___2ViLS",
	"spoilerRow": "rooms__spoilerRow___3ByZG",
	"spoilerBlock": "rooms__spoilerBlock___2ga1M",
	"sideRowHeader": "rooms__sideRowHeader___3h5zq",
	"header": "rooms__header___3SN0o",
	"headerBlock": "rooms__headerBlock___edK30",
	"weekend": "rooms__weekend___2Ywl7"
};