exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__root___1Beyf > * {\n  margin-bottom: 12px;\n}\n.styles__filter___Eb7gA {\n  margin-top: 0px;\n}\n.styles__switcher___2vkMb {\n  margin-bottom: 16px;\n}\n.styles__filterRange___1jeAj .ant-calendar-picker-input {\n  top: -4px;\n}\n.styles__roomPopover___1ZbRZ {\n  max-width: 500px;\n}\n.styles__roomPopover___1ZbRZ .ant-descriptions-item-content {\n  vertical-align: top;\n}\n.styles__roomPopover___1ZbRZ .ant-descriptions-item-label {\n  vertical-align: top;\n}\n.styles__linkPopup___240xt {\n  -webkit-text-decoration: underline dotted;\n          text-decoration: underline dotted;\n}\n.styles__linkPopup___240xt:hover {\n  -webkit-text-decoration: underline dotted;\n          text-decoration: underline dotted;\n}\n.styles__linkPopup___240xt:active {\n  color: #40a9ff;\n}\n.styles__wrappedText___2aiZy {\n  word-break: break-all;\n}\n.styles__tableWrapper___1YqrO .ant-table-body {\n  overflow: auto !important;\n}\n.styles__actionButtons___1DTzE {\n  text-align: right;\n}\n.styles__actionButtons___1DTzE > :not(:first-child) {\n  margin-left: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"root": "styles__root___1Beyf",
	"filter": "styles__filter___Eb7gA",
	"switcher": "styles__switcher___2vkMb",
	"filterRange": "styles__filterRange___1jeAj",
	"roomPopover": "styles__roomPopover___1ZbRZ",
	"linkPopup": "styles__linkPopup___240xt",
	"wrappedText": "styles__wrappedText___2aiZy",
	"tableWrapper": "styles__tableWrapper___1YqrO",
	"actionButtons": "styles__actionButtons___1DTzE"
};