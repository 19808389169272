import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import { RightType } from 'types/models'
import config from 'common/config'

interface Props {
  rightTypesFetch: PromiseState<RightType[]>

  dispatchRightTypesGet(): void
}

const columns: ColumnProps<RightType>[] = [
  {
    key: 'id',
    title: 'id',
    width: 100,
    dataIndex: 'id'
  },
  {
    key: 'name',
    title: 'Имя',
    dataIndex: 'name',
    render: (text: string, record: RightType) => <Link to={`/admin/rightTypes/${record.id}`}>{text}</Link>
  }
]

class RightTable extends Table<RightType> {}

class Index extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchRightTypesGet()
  }

  public render(): React.ReactNode {
    return (
      <div>
        <Link to='/admin/rightTypes/create'>
          <Button type='primary'>
            Новый предел прав
          </Button>
        </Link>
        <RightTable
          columns={columns}
          rowKey='id'
          dataSource={this.props.rightTypesFetch.value}
        />
      </div>
    )
  }
}

export default connect([{
  resource: 'rightTypes',
  method: 'get',
  request: {
    url: urljoin(config.backendUrl, 'rightTypes')
  }
}])(Index)
