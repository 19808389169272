import React from 'react'
import Dragger from 'antd/lib/upload/Dragger'
import { Icon } from 'antd'
import { autobind } from 'core-decorators'
import { RcFile } from 'antd/lib/upload'
import { WrappedFieldProps } from 'redux-form'

type Props = WrappedFieldProps

class Dropzone extends React.Component<Props> {
  @autobind
  public beforeUpload(file: RcFile): boolean {
    this.props.input.onChange(file)

    return false
  }

  public render(): React.ReactNode {
    return (
      <Dragger
        multiple={false}
        onChange={() => void 0}
        beforeUpload={this.beforeUpload}
      >
        <p className='ant-upload-drag-icon'>
          <Icon type='inbox'/>
        </p>
        <p className='ant-upload-text'>Нажмите или переместите сюда изображение для загрузки</p>
      </Dragger>
    )
  }

}

export default Dropzone
