import React from 'react'
import { reduxForm, Field, Form } from 'redux-form'
import { TextField } from 'redux-form-antd'
import { autobind } from 'core-decorators'
import { USAGE_FORMAT_FORM } from 'constants/UsageFormat'

import { UsageFormatFormInterface, Props } from './types'
import { validate } from './helpers'

class UsageFormatForm extends React.Component<Props> {
    @autobind
    public handleSubmit(values: UsageFormatFormInterface): void {
        this.props.onSubmit(values)
    }

    public render(): React.ReactNode {
        return (
            <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                <label>Название</label>
                <Field
                    name='name'
                    component={TextField}
                />
            </Form>
        )
    }
}

export default reduxForm<{}>(
    {
        form: USAGE_FORMAT_FORM,
        enableReinitialize: true,
        validate
    }
)(UsageFormatForm)
