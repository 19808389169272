import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import { RoomType, RoomTypesConfig } from 'types/models'
import config from 'common/config'

interface Props {
  roomTypesFetch: PromiseState<RoomType[]>

  dispatchRoomTypesGet(): void
}

const columns: ColumnProps<RoomType>[] = [
  {
    key: 'id',
    title: 'id',
    width: 100,
    dataIndex: 'id'
  },
  {
    key: 'name',
    title: 'Имя',
    dataIndex: 'name',
    render: (text: string, record: RoomType) => <Link to={`/admin/roomTypes/${record.id}`}>{text}</Link>
  },
  {
    key: 'description',
    title: 'Описание',
    dataIndex: 'description',
    render: (description: string) => description ? description : ''
  },
  {
    key: 'roomTypesConfig',
    title: 'Конфигурация',
    dataIndex: 'roomTypesConfig',
    render: (type: RoomTypesConfig) => type ? type.name : 'Не задана'
  },
]

class RoomTable extends Table<RoomType> {}

class Index extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchRoomTypesGet()
  }

  public render(): React.ReactNode {
    return (
      <div>
        <Link to='/admin/roomTypes/create'>
          <Button type='primary'>
            Новый тип помещения
          </Button>
        </Link>
        <RoomTable
          columns={columns}
          rowKey='id'
          dataSource={this.props.roomTypesFetch.value}
        />
      </div>
    )
  }
}

export default connect([{
  resource: 'roomTypes',
  method: 'get',
  request: {
    url: urljoin(config.backendUrl, 'roomTypes')
  }
}])(Index)
