export enum EVENT_RESERVATION_TYPE {
  PRELIMINARY = 'PRELIMINARY',
  CONCLUDING = 'CONCLUDING'
}

export enum EVENT_RESERVATION_LOCALIZED {
  PRELIMINARY = 'Предварительная',
  CONCLUDING = 'Железная'
}

export enum EVENT_STATUS {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED'
}

export enum EVENT_STATUS_LOCALIZED {
  PENDING = 'Ожидает',
  DECLINED = 'Отклонена',
  APPROVED = 'Подтверждена'
}

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  SUPERVISOR = 'SUPERVISOR',
  MANAGER = 'MANAGER',
  OBSERVER = 'OBSERVER'
}

export enum ALT_DATES {
  MAIN = 'MAIN',
  ALT = 'ALT'
}

export enum ALT_DATES_LOCALIZED {
  MAIN = 'Сохранить основные даты',
  ALT = 'Сохранить альтернативные даты'
}

export enum FILTER_NEW {
  ALL = 'ALL',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}

export enum FILTER_NEW_LOCALIZED {
  ALL = 'Все',
  DAY = 'За день',
  WEEK = 'За неделю',
  MONTH = 'За месяц'
}

export type DateRangeDatumType = {
  value: string;
  inclusive: boolean;
}

export type DateRangeType = DateRangeDatumType[]

export interface Room {
  id: number
  name: string
  schemaUrl: string
  isPavilion: boolean
  typeId: number,
  configs: [],
  configsString?: string,
  squares?: number
  bookings: Booking[]
  type: RoomType
}

export interface User {
  id: number
  name: string
  login: string
  role: USER_ROLE
}

export interface Email {
  id: number
  email: string
}

export interface StatusReason {
  date: string
  status: EVENT_STATUS
  reason: string
  reservationType: EVENT_RESERVATION_TYPE
}

export interface Event {
  id: number
  name: string
  typeId: number
  type?: { name: string }
  mealFormatId: number
  mealFormat?: { name: string }
  description: string
  targetAudience: string
  participantCount: number
  participantCountMeal: number
  website: string
  ageRating: string
  isSellingAlcohol: boolean
  enableSquareCheck: boolean
  startDate: string
  crmId: string
  reservationType?: EVENT_RESERVATION_TYPE
  removeDates?: ALT_DATES
  status: EVENT_STATUS
  authorId: number
  author?: User
  reviewerId: number
  reviewer: User
  bookings?: Booking[]
  contents?: Content[]
  archivedAt?: string
  statusReason: StatusReason[]
}

export interface Booking {
  id: number
  roomId: number
  room?: Room
  eventId: number
  event?: Event
  dates: DateRangeType
  installingDates: DateRangeType
  dismantlingDates: DateRangeType
  squares: string,
  usageFormats?: any[],
  configs?: any[],
  countPeople?: number,
  datesAlt?: DateRangeType | null
  installingDatesAlt?: DateRangeType | null
  dismantlingDatesAlt?: DateRangeType | null
}

export interface EventType {
  id: number
  name: string
  isSendableToCrm: boolean
}

export interface MealFormat {
  id: number
  name: string
}

export interface RoomType {
  id: number
  name: string
  description: string
  roomTypesConfigId: number,
  roomTypesConfig: RoomTypesConfig
}

export interface RoomTypesConfig {
  id: number
  name: string
}

export interface RightType {
  id: number
  name: string
}

export interface FileMethod {
  id: number
  name: string
}

export interface Content {
  id?: number
  contentId?: string
  // eventId?: number
  eventId?: string
  event?: Event
  rightTypeId?: number
  rightType?: { name: string }
  usageDates: DateRangeType
  file?: string,
  fileString?: string,
  fileBinary?: string | ArrayBuffer | null | any
  fileMethodId?: string
}

export interface IOption {
    id: number,
    name: string,
    slug: string,
    value: string,
}

export interface IUsageFormat {
    id: number,
    name: string
}

export interface IConfigs {
  id: number,
  name: string
}

export interface Timezone {
  id: number,
  value: string,
}