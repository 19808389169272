import { Room, Booking, Timezone } from 'types/models'
import queryString from 'query-string'
import classnames from 'classnames'
import connect, { PromiseState } from 'react-redux-fetch'
import urljoin from 'url-join'
import * as R from 'ramda'
import React from 'react'
import { DateRange } from 'moment-range'
import moment from 'moment'
import { autobind } from 'core-decorators'
import { DispatchProp } from 'react-redux'
import { change, formValueSelector, WrappedFieldProps } from 'redux-form'
import { EVENT_FORM } from 'constants/Event'
import { Store } from 'antd/es/table/createStore'
import config from 'common/config'
import { rangeSelectionToMomentDateRange } from 'utils/date.utils'
import { Spin, Button } from 'antd'

import { BookingFormInterface } from '../types/form'

import { default as RoomsComponent } from './rooms/Rooms'
import * as styles from './calendar.styl'
import BookingModal from './bookingModal/BookingModal'
import BookingModalMass from './bookingModalMass/BookingModalMass'
import Bookings from './bookings/Bookings'

import { modifyDateRangeAccordingToLocalTimezone } from 'utils/date.utils'

interface Legend {
  style: string
  text: string
}

const legends: Legend[] = [
  {
    style: styles.montage,
    text: 'Монтаж/Демонтаж'
  },
  {
    style: styles.booked,
    text: 'Проведение мероприятия'
  },
  {
    style: styles.softReserve,
    text: 'Предварительная бронь'
  },
  {
    style: styles.selection,
    text: 'Выбрано вами'
  },
  {
    style: styles.overlap,
    text: 'Конфликт'
  }
]

interface Props extends DispatchProp, WrappedFieldProps {
  eventId: number
  dates: [moment.Moment, moment.Moment]
  enableSquareCheck: Boolean
  bookings: BookingFormInterface[]
  roomsForCalendarFetch: PromiseState<Room[]>
  dispatchRoomsForCalendarGet(range: DateRange, force?: boolean): void
  timezoneFetch: PromiseState<Timezone>
  dispatchTimezoneGet(): void
}

interface State {
  modalOpen: boolean
  modalMassOpen: boolean
  currentValues?: BookingFormInterface
  room?: Room
  expandedGroups: (string | null)[]
  roomFilter?: string,
  bookingsExclude?: number[],
}

class Calendar extends React.Component<Props, State> {
  public state: State = {
    modalOpen: false,
    modalMassOpen: false,
    expandedGroups: ['null'],
    roomFilter: undefined
  }

  public memoizedRangeSelectionToMomentDateRange: (dates: [string | moment.Moment, string | moment.Moment]) => DateRange =
    R.memoizeWith(R.identity, rangeSelectionToMomentDateRange)

  @autobind
  public handleBookingAdd(values: BookingFormInterface): void {
    const { bookings, meta: { form }, input: { name } } = this.props

    if (this.props.timezoneFetch && this.props.timezoneFetch.value && this.props.timezoneFetch.value.value) {

      let tz = this.props.timezoneFetch.value.value;

      if(values.dates && values.dates[0] && values.dates[1]) {
        values.dates = modifyDateRangeAccordingToLocalTimezone(values.dates, tz);
      }
      if(values.dismantlingDates && values.dismantlingDates[0] && values.dismantlingDates[1]) {
        values.dismantlingDates = modifyDateRangeAccordingToLocalTimezone(values.dismantlingDates, tz);
      }
      if(values.installingDates && values.installingDates[0] && values.installingDates[1]) {
        values.installingDates = modifyDateRangeAccordingToLocalTimezone(values.installingDates, tz);
      }
      if(values.datesAlt && values.datesAlt[0] && values.datesAlt[1]) {
        values.datesAlt = modifyDateRangeAccordingToLocalTimezone(values.datesAlt, tz);
      }
      if(values.dismantlingDatesAlt && values.dismantlingDatesAlt[0] && values.dismantlingDatesAlt[1]) {
        values.dismantlingDatesAlt = modifyDateRangeAccordingToLocalTimezone(values.dismantlingDatesAlt, tz);
      }
      if(values.installingDatesAlt && values.installingDatesAlt[0] && values.installingDatesAlt[1]) {
        values.installingDatesAlt = modifyDateRangeAccordingToLocalTimezone(values.installingDatesAlt, tz);
      }
    }

    this.props.dispatch(change(
      form,
      name,
      [
        ...(bookings || []),
        values
      ]
    ))

    this.handleModalClose()
  }

  @autobind
  public handleBookingEdit(recordOld: BookingFormInterface, recordNew: BookingFormInterface): void {
    const { bookings, meta: { form }, input: { name } } = this.props
    this.props.dispatch(change(
      form,
      name,
      R.map(R.when(R.whereEq({
        'id': recordOld.id,
        'roomId': recordOld.roomId,
        'dates': recordOld.dates,
        'dismantlingDates': recordOld.dismantlingDates,
        'installingDates': recordOld.installingDates,
        'squares': recordOld.squares,
        'datesAlt': recordOld.datesAlt,
        'dismantlingDatesAlt': recordOld.dismantlingDatesAlt,
        'installingDatesAlt': recordOld.installingDatesAlt,
        'configs': recordOld.configs,
      }),
      R.pipe(
        R.assoc('id', recordNew.id),
        R.assoc('squares', recordNew.squares),
        R.assoc('installingDates', recordNew.installingDates),
        R.assoc('dismantlingDates', recordNew.dismantlingDates),
        R.assoc('dates', recordNew.dates),
        R.assoc('installingDatesAlt', recordNew.installingDatesAlt),
        R.assoc('dismantlingDatesAlt', recordNew.dismantlingDatesAlt),
        R.assoc('datesAlt', recordNew.datesAlt),
        R.assoc('configs', recordNew.configs),
      )
      ))(bookings)
    ))

    this.handleModalClose()
  }

  @autobind
  public handleBookingEditAction(record: BookingFormInterface): void {
    // const { bookings, meta: { form }, input: { name } } = this.props
    let room = R.find(R.propEq('id', record.roomId), this.props.roomsForCalendarFetch.value)
    this.setState({ modalOpen: true, currentValues: record, room: room })
  }

  @autobind
  public handleBookingRemove(roomId: number): void {
    const { bookings, meta: { form }, input: { name } } = this.props

    const excludeBookings: BookingFormInterface[] = bookings.filter(({roomId: oldRoomId}) => roomId === oldRoomId);
    this.setState((state) => {
        return {
            ...state,
            bookingsExclude: excludeBookings.map(({id}) => id)
        }
    });

    this.props.dispatch(change(
      form,
      name,
      R.reject(R.propEq('roomId', roomId))(bookings)
    ))
  }

  @autobind
  public handleModalClose(): void {
    this.setState({ modalOpen: false, room: undefined, currentValues: undefined })
  }

  @autobind
  public handleModalOpen(room: Room): void {
    this.setState({ modalOpen: true, room: room, currentValues: undefined })
  }

  @autobind
  public handleModalMassOpen(): void {
    this.setState({ modalMassOpen: true, room: undefined, currentValues: undefined })
  }

  @autobind
  public handleModalMassClose(): void {
    this.setState({ modalMassOpen: false, room: undefined, currentValues: undefined })
  }

  @autobind
  public handleBookingAddMass(values: BookingFormInterface[]): void {
    const { bookings, meta: { form }, input: { name } } = this.props

    if (this.props.timezoneFetch && this.props.timezoneFetch.value && this.props.timezoneFetch.value.value) {
      let tz = this.props.timezoneFetch.value.value;

      values.forEach((value, index) => {
        if (index == 0) {
          if(value.dates && value.dates[0] && value.dates[1]) {
            values[index].dates = modifyDateRangeAccordingToLocalTimezone(value.dates, tz);
          }
          if(value.dismantlingDates && value.dismantlingDates[0] && value.dismantlingDates[1]) {
            values[index].dismantlingDates = modifyDateRangeAccordingToLocalTimezone(value.dismantlingDates, tz);
          }
          if(value.installingDates && value.installingDates[0] && value.installingDates[1]) {
            values[index].installingDates = modifyDateRangeAccordingToLocalTimezone(value.installingDates, tz);
          }
          if(value.datesAlt && value.datesAlt[0] && value.datesAlt[1]) {
            values[index].datesAlt = modifyDateRangeAccordingToLocalTimezone(value.datesAlt, tz);
          }
          if(value.dismantlingDatesAlt && value.dismantlingDatesAlt[0] && value.dismantlingDatesAlt[1]) {
            values[index].dismantlingDatesAlt = modifyDateRangeAccordingToLocalTimezone(value.dismantlingDatesAlt, tz);
          }
          if(value.installingDatesAlt && value.installingDatesAlt[0] && value.installingDatesAlt[1]) {
            values[index].installingDatesAlt = modifyDateRangeAccordingToLocalTimezone(value.installingDatesAlt, tz);
          }
        }
      })
    }

    let index = 0;
    if (bookings){
      index = bookings.length
    }

    this.props.dispatch(change(
      form,
      name,
      R.insertAll(index, values, (bookings || []))
    ))

    this.handleModalMassClose()
  }

  public expandGroup = (id: string | null) => {
    this.setState(
      (state: State) => ({ expandedGroups: [...state.expandedGroups, id] })
    )
  }

  public toggleGroup = (id: string | null) => {
    this.setState(
      (state: State) => {
        if (state.expandedGroups.includes(id)) {
          return { expandedGroups: R.reject(R.equals(id), state.expandedGroups) }
        }

        return { expandedGroups: [...state.expandedGroups, id] }
      }
    )
  }

  public roomFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      { roomFilter: event.target.value.toLocaleLowerCase() },
      () => {
        if (!this.state.roomFilter || !this.props.roomsForCalendarFetch.value) {
          return
        }

        this.setState({
          expandedGroups: R.uniq([
            ...this.state.expandedGroups,
            ...this.props.roomsForCalendarFetch.value
              .filter(
                (room: Room) => room.name.toLocaleLowerCase().includes(this.state.roomFilter as string)
              )
              .map(
                (room: Room) => String(room.typeId)
              )
          ])
        })
      })
  }

  public componentDidMount(): void {
    this.props.dispatchRoomsForCalendarGet(rangeSelectionToMomentDateRange(this.props.dates), true)
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!R.equals(prevProps.dates, this.props.dates)) {
      this.props.dispatchRoomsForCalendarGet(rangeSelectionToMomentDateRange(this.props.dates))
    }

    if (
      prevProps.roomsForCalendarFetch.pending
      && this.props.roomsForCalendarFetch.value
      && this.props.roomsForCalendarFetch.fulfilled
      && this.props.bookings
    ) {
      const types: string[] =
        R.pipe(
          R.map((booking: Booking) => booking && booking.roomId),
          R.map((roomId: number) => R.find(R.propEq('id', roomId), this.props.roomsForCalendarFetch.value)),
          R.reject(R.isNil),
          R.map((room: Room) => room.typeId),
          String,
          R.uniq
        )(this.props.bookings)

      types.forEach(this.expandGroup)
    }
  }

  public render(): React.ReactNode {
    const { roomsForCalendarFetch, timezoneFetch, dates, enableSquareCheck, bookings, eventId, meta: { error, touched } } = this.props
    const { currentValues, room, modalOpen, modalMassOpen } = this.state

    // const timezone = null;

    if (roomsForCalendarFetch.pending || timezoneFetch.pending || !roomsForCalendarFetch.value) {
      return <Spin />
    }

    return (
      <div>
        Легенда:
        {legends.map((legend: Legend) => <div key={legend.text} className={classnames(styles.block, legend.style)}>{legend.text}</div>)}
        <div className={styles.rooms}>
          {roomsForCalendarFetch.fulfilled && roomsForCalendarFetch.value &&
            <RoomsComponent
              timezone={timezoneFetch && timezoneFetch.value ? timezoneFetch.value.value : null}
              eventId={eventId}
              onClick={this.handleModalOpen}
              rooms={roomsForCalendarFetch.value}
              range={this.memoizedRangeSelectionToMomentDateRange(dates)}
              bookings={bookings}
              toggleGroup={this.toggleGroup}
              expandedGroups={this.state.expandedGroups}
              roomFilter={this.state.roomFilter}
              roomFilterChange={this.roomFilterChange}
            />
          }
        </div>
        {
          room &&
            <BookingModal
              timezone={timezoneFetch && timezoneFetch.value ? timezoneFetch.value.value : null}
              visible={modalOpen}
              currentValues={currentValues}
              room={room}
              onAdd={this.handleBookingAdd}
              onEdit={this.handleBookingEdit}
              onClose={this.handleModalClose}
              bookingsExclude={this.state.bookingsExclude}
              enableSquareCheck={enableSquareCheck}
            />
        }
        {
          <Button className={styles.massAddButton} key='massAdd' type='primary' onClick={this.handleModalMassOpen}>
            Добавить несколько
          </Button>
        }
        {
          roomsForCalendarFetch.value &&
            <BookingModalMass
              timezone={timezoneFetch && timezoneFetch.value ? timezoneFetch.value.value : null}
              rooms={roomsForCalendarFetch.value}
              visible={modalMassOpen}
              onAdd={this.handleBookingAddMass}
              onClose={this.handleModalMassClose}
            />
        }
        {
          bookings && roomsForCalendarFetch.value &&
            <Bookings
              items={bookings}
              timezone={timezoneFetch && timezoneFetch.value ? timezoneFetch.value.value : null}
              onEdit={this.handleBookingEditAction}
              onRemove={this.handleBookingRemove}
              rooms={roomsForCalendarFetch.value}
            />
        }
        {
          touched && error &&
            <span className={styles.error}>{error}</span>
        }
      </div>
    )
  }
}

const selector = formValueSelector(EVENT_FORM)

const mapStateToProps = (state: Store) => ({
  eventId: selector(state, 'id'),
  bookings: selector(state, 'bookings')
})

export default connect<
  Pick<Props, 'bookings' | 'eventId'>,
  Pick<Props, 'dispatchRoomsForCalendarGet' | 'dispatch' | 'roomsForCalendarFetch'>
>(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource: 'roomsForCalendar',
      method: 'get',
      request: (range: DateRange, force?: boolean) => {
        const query: string = queryString.stringify({
          scope: 'CALENDAR',
          startDate: range.start.startOf('day').toISOString(),
          endDate: range.end.endOf('day').toISOString(),
          excludeArchived: true
        })

        return {
          url: urljoin(config.backendUrl, 'rooms', `?${query}`),
          comparison: query,
          force
        }
      }
    }
  ],
  mapStateToProps
)(Calendar)
