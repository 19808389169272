import moment from 'moment'
import { DateRange, extendMoment } from 'moment-range'
import { DateRangeType } from 'types/models'

import { HUMAN_DATETIME_FORMAT } from '../constants/DateTime'

const momentRange = extendMoment(moment)
const fakestart = "19990912"
const faseend = "19991031"

export function modifyDateRangeAccordingToLocalTimezone(dates: [moment.Moment, moment.Moment], timezone: any = null):[moment.Moment, moment.Moment]
{
  if (timezone != '' && timezone != null) {
    if (dates[0]) {
      dates[0] = modifyDateAccordingToLocalTimezone(dates[0], timezone);
    }

    if (dates[1]) {
      dates[1] = modifyDateAccordingToLocalTimezone(dates[1], timezone);
    }
  }

  return [dates[0], dates[1]];
}

export function modifyDateAccordingToLocalTimezone(date: moment.Moment, timezone: any = null):moment.Moment
{
  // timezone = '+05:00';
  if (timezone != '' && timezone != null) {
    let timezoneOffset = moment().utcOffset(timezone).utcOffset();

    const dt = new Date();
    let diffTZ = dt.getTimezoneOffset();
    if (diffTZ < 0) {
      diffTZ = Math.abs(diffTZ)
    }
    else {
      diffTZ = diffTZ * -1;
    }

    let minutesToAdd = 0;
    let minutesToSubstract = 0;
    if (diffTZ != timezoneOffset) {
      if (diffTZ > timezoneOffset) {
        minutesToAdd = diffTZ - timezoneOffset;
      }
      else {
        minutesToSubstract = timezoneOffset - diffTZ;
      }
    }

    if (minutesToAdd > 0) {
      date = date.add(minutesToAdd, 'minutes');
    }
  
    if (minutesToSubstract > 0) {
      date = date.subtract(minutesToSubstract, 'minutes');
    }
  }

  return date;
}

export function backendDateRangeToMomentDatetimeRange(dates: DateRangeType | null | undefined, timezone:any = null): DateRange {

  if (dates && dates[0] && dates[1] && dates[0].value && dates[1].value){
    return momentRange.range(
      timezone ? moment(dates[0].value).utcOffset(timezone) : moment(dates[0].value),
      timezone ? moment(dates[1].value).utcOffset(timezone) : moment(dates[1].value)
    )  
  } else {
    return momentRange.range(
      timezone ? moment(fakestart, "YYYYMMDD").utcOffset(timezone).startOf('day') : moment(fakestart, "YYYYMMDD").startOf('day'),
      timezone ? moment(faseend, "YYYYMMDD").utcOffset(timezone).endOf('day') : moment(faseend, "YYYYMMDD").endOf('day')
    )  
  }
}

export function backendDateRangeToMomentDateRange(dates: DateRangeType | null | undefined, timezone:any = null): DateRange {

  if (dates && dates[0] && dates[1] && dates[0].value && dates[1].value){
    return momentRange.range(
      timezone ? moment(dates[0].value).utcOffset(timezone).startOf('day') : moment(dates[0].value).startOf('day'),
      timezone ? moment(dates[1].value).utcOffset(timezone).endOf('day') : moment(dates[1].value).endOf('day')
    )
  } else {
    return momentRange.range(
      timezone ? moment("19990912", "YYYYMMDD").utcOffset(timezone).startOf('day') : moment("19990912", "YYYYMMDD").startOf('day'),
      timezone ? moment("19991031", "YYYYMMDD").utcOffset(timezone).endOf('day') : moment("19991031", "YYYYMMDD").endOf('day')
    );
  }
}

export function rangeSelectionToMomentDateTimeRange(dates: [moment.Moment | string, moment.Moment | string], timezone:any = null): DateRange {
  return momentRange.range([
    timezone ? moment(dates[0]).utcOffset(timezone).clone() : moment(dates[0]).clone(),
    timezone ? moment(dates[1]).utcOffset(timezone).clone() : moment(dates[1]).clone()
  ])
}

export function rangeSelectionToMomentDateTimeRangeAlt(dates: [moment.Moment | string, moment.Moment | string] | null | undefined, timezone:any = null): DateRange {

  if (dates && dates[0] && dates[1]){
    return momentRange.range(
      timezone ? moment(dates[0]).utcOffset(timezone).clone() : moment(dates[0]).clone(),
      timezone ? moment(dates[1]).utcOffset(timezone).clone() : moment(dates[1]).clone()
    )
  } else {
    return momentRange.range(
      timezone ? moment("19990912", "YYYYMMDD").utcOffset(timezone).startOf('day') : moment("19990912", "YYYYMMDD").startOf('day'),
      timezone ? moment("19991031", "YYYYMMDD").utcOffset(timezone).endOf('day') : moment("19991031", "YYYYMMDD").endOf('day')
    );
  }
}

export function rangeSelectionToMomentDateRange(dates: [moment.Moment | string, moment.Moment | string], timezone:any = null): DateRange {
  return momentRange.range([
    timezone ? moment(dates[0]).clone().utcOffset(timezone).startOf('day') : moment(dates[0]).clone().startOf('day'),
    timezone ? moment(dates[1]).clone().utcOffset(timezone).endOf('day') : moment(dates[1]).clone().endOf('day')
  ])
}
export function rangeSelectionToMomentDateRangeAlt(dates: [moment.Moment | string, moment.Moment | string] | null | undefined, timezone:any = null): DateRange {
  if (dates && dates[0] && dates[1]){
    return momentRange.range(
      timezone ? moment(dates[0]).utcOffset(timezone).startOf('day') : moment(dates[0]).startOf('day'),
      timezone ? moment(dates[1]).utcOffset(timezone).endOf('day') : moment(dates[1]).endOf('day')
    )
  } else {
    return momentRange.range(
      timezone ? moment("19990912", "YYYYMMDD").utcOffset(timezone).startOf('day') : moment("19990912", "YYYYMMDD").startOf('day'),
      timezone ? moment("19991031", "YYYYMMDD").utcOffset(timezone).endOf('day') : moment("19991031", "YYYYMMDD").endOf('day')
    );
  }
}

export function intersectDateRanges(dateRanges: DateRange[]): boolean[] {
  //tslint:disable-next-line:prefer-array-literal
  const result = new Array(dateRanges.length).fill(false)

  for (let i = 0; i < dateRanges.length - 1; i++) {
    for (let j = i + 1; j < dateRanges.length; j++) {
      if (result[i] && result[j]) {
        continue
      }

      if (dateRanges[i].intersect(dateRanges[j])) {
        result[i] = true
        result[j] = true
      }
    }
  }

  return result
}

export function formatDateRange(dateRange: DateRange): string {
  return [dateRange.start.format(HUMAN_DATETIME_FORMAT), dateRange.end.format(HUMAN_DATETIME_FORMAT)].join(' - ')
}

export function checkDateRange(dateRange: DateRange): boolean {
  if (dateRange.start.format('YYYY') == '1999' || dateRange.end.format('YYYY') == '1999'){
    return false;
  } else {
    return true;
  }
}