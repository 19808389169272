import React from 'react'
// import moment from 'moment'
import { Form, reduxForm, Field } from 'redux-form'
import { Row, Button, Col } from 'antd'
import { EVENT_FORM } from 'constants/Event'
import ContentPeople from '../contentPeople/ContentPeople'
import * as styles from '../form.styl'

import { ComponentPropsInterface, ComponentStateInterface } from './types'

class ContentStep extends React.Component<ComponentPropsInterface, ComponentStateInterface> {

  public render(): React.ReactNode {
    const { handleSubmit, onBack, rightTypes, fileMethods, timezone } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Field
            name='contents'
            component={ContentPeople}
            {...{
              rightTypes: rightTypes,
              fileMethods: fileMethods,
              timezone: timezone,
            }}
          />
        </Row>
        <Row>
          <Col className={styles.buttonsWrapper}>
            <Button
              type='ghost'
              onClick={onBack}
            >
              Назад
            </Button>
            <Button
              type='primary'
              htmlType='submit'
            >
              Далее
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default reduxForm<{}, Partial<ComponentPropsInterface>>({
  form: EVENT_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(ContentStep)
