import { DispatchProp } from 'react-redux'
import { Button } from 'antd'
import { change, formValueSelector, WrappedFieldProps } from 'redux-form'
import * as R from 'ramda'
import React from 'react'
import { autobind } from 'core-decorators'
import { EVENT_FORM } from 'constants/Event'
import { Store } from 'antd/es/table/createStore'
import connect from 'react-redux-fetch'

import { ContentFormInterface } from '../types/form'

import * as styles from './contentPeople.styl'
import ContentModal from './contentModal/ContentModal'
import ContentList from './contentList/ContentList'

interface Props extends DispatchProp, WrappedFieldProps {
    eventId: number
    contents: ContentFormInterface[],
    rightTypes: any
    fileMethods: any
    timezone: any | undefined
}

interface State {
  currentValues?: ContentFormInterface
  modalOpen: boolean
}

class ContentPeople extends React.Component<Props, State> {
    public state: State = {
        modalOpen: false
    }

    @autobind
    public handleContentAdd(values: ContentFormInterface): void {
      const { contents, meta: { form }, input: { name } } = this.props

      this.props.dispatch(change(
        form,
        name,
        [
          ...(contents || []),
          values
        ]
      ))

      this.handleModalClose()
    }

    @autobind
    public handleContentEdit(recordOld: ContentFormInterface, recordNew: ContentFormInterface): void {
      const { contents, meta: { form }, input: { name } } = this.props

      if (recordOld.id)
      {
        this.props.dispatch(change(
          form,
          name,
          R.map(R.when(R.whereEq({
            'id': recordOld.id
          }),
          R.pipe(
            R.assoc('rightTypeId', recordNew.rightTypeId),
            R.assoc('fileMethodId', recordNew.fileMethodId),
            R.assoc('usageDates', recordNew.usageDates),
            R.assoc('file', recordNew.file),
            R.assoc('fileBinary', recordNew.fileBinary),
          )
          ))(contents)
        ))

      } else {
        this.props.dispatch(change(
          form,
          name,
          R.map(R.when(R.whereEq({
            'contentId': recordOld.contentId,
            'rightTypeId': recordOld.rightTypeId,
            'fileMethodId': recordOld.fileMethodId,
            'usageDates': recordOld.usageDates,
          }),
          R.pipe(
            R.assoc('rightTypeId', recordNew.rightTypeId),
            R.assoc('fileMethodId', recordNew.fileMethodId),
            R.assoc('usageDates', recordNew.usageDates),
            R.assoc('fileBinary', recordNew.fileBinary),
          )
          ))(contents)
        ))
      }

      this.handleModalClose()
    }

    @autobind
    public handleContentEditAction(record: ContentFormInterface): void {
      this.setState({ modalOpen: true, currentValues: record })
    }

    @autobind
    public handleContentRemove(id: string): void {
      const { contents, meta: { form }, input: { name } } = this.props
      this.props.dispatch(change(
        form,
        name,
        R.reject(R.propEq('contentId', id))(contents)
      ))
    }

    @autobind
    public handleModalClose(): void {
      this.setState({ modalOpen: false, currentValues: undefined })
    }

    @autobind
    public handleModalOpen(): void {
      this.setState({ modalOpen: true, currentValues: undefined })
    }

    public render(): React.ReactNode {

        const { contents, rightTypes, fileMethods, timezone, meta: { error, touched } } = this.props
        const { modalOpen, currentValues } = this.state

        return (
          <div className={styles.wrapper}>
              <Button
                className={styles.addButton}
                type='ghost'
                onClick={this.handleModalOpen}
              >
                Добавить контент 3-их лиц
              </Button>
              <ContentModal
                  currentValues={currentValues}
                  rightTypes={rightTypes}
                  fileMethods={fileMethods}
                  timezone={timezone}
                  visible={modalOpen}
                  onAdd={this.handleContentAdd}
                  onEdit={this.handleContentEdit}
                  onClose={this.handleModalClose}
              />
            {
              contents &&
                <ContentList
                  rightTypes={rightTypes}
                  fileMethods={fileMethods}
                  timezone={timezone}
                  items={contents}
                  onEdit={this.handleContentEditAction}
                  onRemove={this.handleContentRemove}
                />
            }
            {
              touched && error &&
                <span className={styles.error}>{error}</span>
            }
          </div>
        )
    }
}

const selector = formValueSelector(EVENT_FORM)

const mapStateToProps = (state: Store) => ({
    contents: selector(state, 'contents')
})
  
export default connect<
  Pick<Props, 'contents'>
>([],
  mapStateToProps
)(ContentPeople)