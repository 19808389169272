import React from 'react'
import { Switch, Route, RouteComponentProps } from 'react-router'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect'

import RoomsIndex from './Rooms/Index/Index'
import RoomEdit from './Rooms/Edit/Edit'
import RoomCreate from './Rooms/Create/Create'
import UsersIndex from './Users/Index/Index'
import UserEdit from './Users/Edit/Edit'
import UserCreate from './Users/Create/Create'
import EventTypesIndex from './EventTypes/Index/Index'
import EventTypesEdit from './EventTypes/Edit/Edit'
import EventTypesCreate from './EventTypes/Create/Create'
import MealFormatsIndex from './MealFormats/Index/Index'
import MealFormatsEdit from './MealFormats/Edit/Edit'
import MealFormatsCreate from './MealFormats/Create/Create'
import RoomTypesIndex from './RoomTypes/Index/Index'
import RoomTypesEdit from './RoomTypes/Edit/Edit'
import RoomTypesCreate from './RoomTypes/Create/Create'
import RoomTypesConfigsIndex from './RoomTypesConfigs/Index/Index'
import RoomTypesConfigsEdit from './RoomTypesConfigs/Edit/Edit'
import RoomTypesConfigsCreate from './RoomTypesConfigs/Create/Create'
import RightTypesIndex from './RightTypes/Index/Index'
import RightTypesEdit from './RightTypes/Edit/Edit'
import RightTypesCreate from './RightTypes/Create/Create'
import FileMethodsIndex from './FileMethods/Index/Index'
import FileMethodsEdit from './FileMethods/Edit/Edit'
import FileMethodsCreate from './FileMethods/Create/Create'
import EmailsIndex from './Emails/Index/Index'
import EmailEdit from './Emails/Edit/Edit'
import EmailCreate from './Emails/Create/Create'
import OptionsIndex from './Options/Index/Index'
import OptionEdit from './Options/Edit/Edit'
import OptionCreate from './Options/Create/Create'
import Timezone from './Timezone/Index'

import UsageFormatsIndex from './UsageFormats/Index/Index'
import UsageFormatEdit from './UsageFormats/Edit/Edit'
import UsageFormatCreate from './UsageFormats/Create/Create'

import * as styles from './admin.styl'

const { Content, Sider } = Layout

class Admin extends React.Component<RouteComponentProps & InjectedAuthRouterProps> {
  public render(): React.ReactNode {
    return (
      <Layout>
        <Sider className={styles.sider}>
          <Menu
            className={styles.menu}
            selectedKeys={[this.getMenuKey()]}
          >
            <Menu.Item key='users'>
              <Link to='/admin/users'>Пользователи</Link>
            </Menu.Item>
            <Menu.Item key='emails'>
              <Link to='/admin/emails'>Уведомления</Link>
            </Menu.Item>
            <Menu.Item key='rooms'>
              <Link to='/admin/rooms'>Помещения</Link>
            </Menu.Item>
            <Menu.Item key='roomTypes'>
              <Link to='/admin/roomTypes'>Типы помещений</Link>
            </Menu.Item>
            <Menu.Item key='roomTypesConfigs'>
              <Link to='/admin/roomTypesConfigs'>Конфигурации типов помещений</Link>
            </Menu.Item>
            <Menu.Item key='eventTypes'>
              <Link to='/admin/eventTypes'>Типы мероприятий</Link>
            </Menu.Item>
            <Menu.Item key='mealFormats'>
              <Link to='/admin/mealFormats'>Форматы питания</Link>
            </Menu.Item>
            <Menu.Item key='rightTypes'>
              <Link to='/admin/rightTypes'>Пределы прав</Link>
            </Menu.Item>
            <Menu.Item key='fileMethods'>
              <Link to='/admin/fileMethods'>Способы</Link>
            </Menu.Item>
            <Menu.Item key='usage-formats'>
              <Link to='/admin/usage-formats'>Форматы использования</Link>
            </Menu.Item>
            <Menu.Item key='options'>
              <Link to='/admin/options'>Настройки</Link>
            </Menu.Item>
            <Menu.Item key='timezone'>
              <Link to='/admin/timezone'>Часовой пояс</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content className={styles.content}>
          <Switch>
            <Route exact path={`${this.props.match.url}/rooms`} component={RoomsIndex} />
            <Route exact path={`${this.props.match.url}/rooms/:id(\\d+)`} component={RoomEdit} />
            <Route exact path={`${this.props.match.url}/rooms/create`} component={RoomCreate} />

            <Route exact path={`${this.props.match.url}/users`} component={UsersIndex} />
            <Route exact path={`${this.props.match.url}/users/:id(\\d+)`} component={UserEdit} />
            <Route exact path={`${this.props.match.url}/users/create`} component={UserCreate} />

            <Route exact path={`${this.props.match.url}/emails`} component={EmailsIndex} />
            <Route exact path={`${this.props.match.url}/emails/:id(\\d+)`} component={EmailEdit} />
            <Route exact path={`${this.props.match.url}/emails/create`} component={EmailCreate} />

            <Route exact path={`${this.props.match.url}/roomTypes`} component={RoomTypesIndex} />
            <Route exact path={`${this.props.match.url}/roomTypes/:id(\\d+)`} component={RoomTypesEdit} />
            <Route exact path={`${this.props.match.url}/roomTypes/create`} component={RoomTypesCreate} />

            <Route exact path={`${this.props.match.url}/roomTypesConfigs`} component={RoomTypesConfigsIndex} />
            <Route exact path={`${this.props.match.url}/roomTypesConfigs/:id(\\d+)`} component={RoomTypesConfigsEdit} />
            <Route exact path={`${this.props.match.url}/roomTypesConfigs/create`} component={RoomTypesConfigsCreate} />

            <Route exact path={`${this.props.match.url}/eventTypes`} component={EventTypesIndex} />
            <Route exact path={`${this.props.match.url}/eventTypes/:id(\\d+)`} component={EventTypesEdit} />
            <Route exact path={`${this.props.match.url}/eventTypes/create`} component={EventTypesCreate} />

            <Route exact path={`${this.props.match.url}/mealFormats`} component={MealFormatsIndex} />
            <Route exact path={`${this.props.match.url}/mealFormats/:id(\\d+)`} component={MealFormatsEdit} />
            <Route exact path={`${this.props.match.url}/mealFormats/create`} component={MealFormatsCreate} />

            <Route exact path={`${this.props.match.url}/rightTypes`} component={RightTypesIndex} />
            <Route exact path={`${this.props.match.url}/rightTypes/:id(\\d+)`} component={RightTypesEdit} />
            <Route exact path={`${this.props.match.url}/rightTypes/create`} component={RightTypesCreate} />

            <Route exact path={`${this.props.match.url}/fileMethods`} component={FileMethodsIndex} />
            <Route exact path={`${this.props.match.url}/fileMethods/:id(\\d+)`} component={FileMethodsEdit} />
            <Route exact path={`${this.props.match.url}/fileMethods/create`} component={FileMethodsCreate} />

            <Route exact path={`${this.props.match.url}/options`} component={OptionsIndex} />
            <Route exact path={`${this.props.match.url}/options/:id(\\d+)`} component={OptionEdit} />
            <Route exact path={`${this.props.match.url}/options/create`} component={OptionCreate} />

            <Route exact path={`${this.props.match.url}/usage-formats`} component={UsageFormatsIndex} />
            <Route exact path={`${this.props.match.url}/usage-formats/:id(\\d+)`} component={UsageFormatEdit} />
            <Route exact path={`${this.props.match.url}/usage-formats/create`} component={UsageFormatCreate} />

            <Route exact path={`${this.props.match.url}/timezone`} component={Timezone} />
          </Switch>
        </Content>
      </Layout>
    )
  }

  private getMenuKey(): string {
    return this.props.location.pathname.split('/')[2]
  }
}

export default Admin
