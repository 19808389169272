import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import config from 'common/config'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect'
import urljoin from 'url-join'
import { Event, Timezone } from 'types/models'
import { message, Spin } from 'antd'
import { RouteComponentProps } from 'react-router'
import * as styles from './styles.styl'

// import moment from 'moment'
import { modifyDateAccordingToLocalTimezone } from 'utils/date.utils'

import EventForm from '../_shared/form/Form'
import { EventFormInterface } from '../_shared/form/types/form'

interface Props extends RouteComponentProps {
  eventFetch: PromiseState<Event>
  timezoneFetch: PromiseState<Timezone>
  dispatchEventPost(body: EventFormInterface): void
  dispatchTimezoneGet(): void
}

class Create extends React.Component<Props & InjectedAuthRouterProps> {
  public handleSubmit = (body: EventFormInterface) => {
    let timezone = '';
    if (this.props.timezoneFetch.value && this.props.timezoneFetch.value.value) {
      timezone = this.props.timezoneFetch.value.value;
    }

    body.startDate = modifyDateAccordingToLocalTimezone(body.startDate, timezone);

    this.props.dispatchEventPost(body)
  }

  public componentDidMount(): void {
    const { dispatchTimezoneGet } = this.props
    dispatchTimezoneGet()
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.eventFetch.pending && this.props.eventFetch.fulfilled) {
      message.success('Мероприятие отправлено на согласование')
      this.props.history.push('/events')
    }
  }

  public render(): React.ReactNode {

    const { timezoneFetch } = this.props

    return (
      <>
        {
          timezoneFetch.pending && (
            <div className={styles.spinWrapper}>
              <Spin />
            </div>
          )
        }
        {
          timezoneFetch.fulfilled && (
            <EventForm
              reviewMode={false}
              createMode={true}
              onSubmit={this.handleSubmit}
              submitBlocked={this.props.eventFetch.pending}
            />
          )
        }
      </>
    )
  }
}

export default connect(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource: 'event',
      method: 'post',
      request: (body: EventFormInterface) => ({
        url: urljoin(config.backendUrl, 'events'),
        body
      })
    }
  ]
)(Create)
