import React from 'react'
import { Modal, Button, Carousel } from 'antd'
// import { Modal, Button } from 'antd'
// import Slider from "react-slick";
import urljoin from 'url-join'
import config from 'common/config'
import * as styles from './ContentListModal.styl'

import { ContentFormInterface } from '../../types/form'

interface Props {
    items?: ContentFormInterface[]
    visible: boolean,
    slide: number,
    onClose(): void
}

interface SliderItems {
    url: string,
    name: string
}

class ContentListModal extends React.Component<Props, SliderItems> {

    slider: any;

    public getFiles(items: ContentFormInterface[] | undefined){

        let files: SliderItems[] = [];

        if (items){
            items.forEach((item) => {

                if (item.file && item.file[0] && item.file[0].name && item.file[0].name.match(/.(jpg|jpeg|png|gif|svg|bmp|ico|webp)$/i)){

                    let filename = item.file[0].name,
                        countUpload = (filename.match(/uploads/g) || []).length;

                    if (countUpload > 0){
                        let nameArr = filename.split('/'),
                            url = urljoin(config.backendUrl, filename);

                        files.push({
                            url: url.toString(),
                            name: nameArr[nameArr.length - 1]
                        });
                    }
                }
            });
        }

        return files;
    }

    public shouldComponentUpdate(nextProps, nextState): boolean {
        if (this.slider){
            this.slider.goTo(nextProps.slide);
        }
        return true;
    }

    public render(): React.ReactNode {
        const { visible, items, onClose } = this.props
        const width: string = '70vw';

        let files = this.getFiles(items);

        return (
            <Modal className={styles.modal}
                title={<div>Контент 3-их лиц</div>}
                onCancel={onClose}
                visible={visible}
                maskClosable={false}
                footer={[
                <Button key='back' onClick={onClose}>Закрыть</Button>
                ]}
                width={width}
            >
                <Carousel
                    draggable={true}
                    initialSlide={this.props.slide}
                    arrows
                    dots={false}
                    className={styles.slider}
                    ref={slider => (this.slider = slider)}
                >
                    {files.length > 0 && files.map((item, idx) => <div key={idx}><img className={styles.imageSlide} src={item.url} /></div>)}
                </Carousel>
            </Modal>
        );
    }
}

export default ContentListModal
