import React from 'react'
import connect from 'react-redux-fetch'
import * as R from 'ramda'
import config from 'common/config'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect'
import urljoin from 'url-join'
import { message, Spin } from 'antd'
import moment from 'moment'
import axios from 'axios'
import { NULLABLE } from 'components/pages/Events/Index/types'
import { Booking, Content, EVENT_RESERVATION_TYPE, EVENT_STATUS, USER_ROLE, User } from 'types/models'

import EventForm from '../_shared/form/Form'
import { EventFormInterface, BookingFormInterface, ContentFormInterface } from '../_shared/form/types/form'

import { ComponentPropsInterface } from './types'
import * as styles from './styles.styl'

import { StoreInterface } from 'store'

interface Props {
  user?: User
}

class Review extends React.Component<ComponentPropsInterface & InjectedAuthRouterProps & Props> {
  private readonly formatBookingsToBookingForm: (bookings: Booking[] | undefined, timezone: any | undefined) => BookingFormInterface[] =
    R.pipe(
      R.defaultTo([]),
      R.map(
        (booking: Booking, timezone) => ({
          id: booking.id,
          dates: [
            timezone ? moment(booking.dates[0].value).utcOffset(timezone) : moment(booking.dates[0].value),
            timezone ? moment(booking.dates[1].value).utcOffset(timezone) : moment(booking.dates[1].value)
          ],
          installingDates: [
            timezone ? moment(booking.installingDates[0].value).utcOffset(timezone) : moment(booking.installingDates[0].value),
            timezone ? moment(booking.installingDates[1].value).utcOffset(timezone) : moment(booking.installingDates[1].value)
          ],
          dismantlingDates: [
            timezone ? moment(booking.dismantlingDates[0].value).utcOffset(timezone) : moment(booking.dismantlingDates[0].value),
            timezone ? moment(booking.dismantlingDates[1].value).utcOffset(timezone) : moment(booking.dismantlingDates[1].value)
          ],
          squares: booking.squares,
          roomId: booking.roomId,
          usageFormats: booking.usageFormats ? booking.usageFormats : [],
          configs: booking.configs ? booking.configs : [],
          countPeople: booking.countPeople ? booking.countPeople : 0,
          datesAlt: (booking.datesAlt && booking.datesAlt.length == 2) ? [
            timezone ? moment(booking.datesAlt[0].value).utcOffset(timezone) : moment(booking.datesAlt[0].value),
            timezone ? moment(booking.datesAlt[1].value).utcOffset(timezone) : moment(booking.datesAlt[1].value)
          ] : null,
          installingDatesAlt: (booking.installingDatesAlt && booking.installingDatesAlt.length == 2) ? [
            timezone ? moment(booking.installingDatesAlt[0].value).utcOffset(timezone) : moment(booking.installingDatesAlt[0].value),
            timezone ? moment(booking.installingDatesAlt[1].value).utcOffset(timezone) : moment(booking.installingDatesAlt[1].value)
          ] : null,
          dismantlingDatesAlt: (booking.dismantlingDatesAlt && booking.dismantlingDatesAlt.length == 2) ? [
            timezone ? moment(booking.dismantlingDatesAlt[0].value).utcOffset(timezone) : moment(booking.dismantlingDatesAlt[0].value),
            timezone ? moment(booking.dismantlingDatesAlt[1].value).utcOffset(timezone) : moment(booking.dismantlingDatesAlt[1].value)
          ] : null
        })
      )
    )

  private readonly formatContentsToContentForm: (contents: Content[] | undefined, timezone: any | undefined) => ContentFormInterface[] =
    R.pipe(
      R.defaultTo([]),
      R.map(
        (content: Content, timezone) => ({
          id: content.id,
          contentId: content.id,
          usageDates: [
            timezone ? moment(content.usageDates[0].value).utcOffset(timezone) : moment(content.usageDates[0].value),
            timezone ? moment(content.usageDates[1].value).utcOffset(timezone) : moment(content.usageDates[1].value)
          ],
          file: {
            0:{name: content.file}
          },
          rightTypeId: content.rightTypeId && content.rightTypeId.toString(),
          fileMethodId: content.fileMethodId && content.fileMethodId.toString(),
        })
      )
    )

  public getEventIdFromUrl(){
    let eventId,
        isNum = false,
        pathname = window.location.pathname.split('/');

    if (pathname[2]){
      eventId = pathname[2];
      isNum = /^\d+$/.test(eventId);
    }

    if (!isNum || eventId == 'create'){
      eventId = 0;
    }

    return eventId;
  }

  public async getDatesConflit(dates)
  {
      let datesStart = moment(dates['dates'][0]).toISOString(),
          datesEnd = moment(dates['dates'][1]).toISOString(),
          eventId = this.getEventIdFromUrl();

      return await axios.get(urljoin(config.backendUrl, 'events'), {
          params: {
              room: dates['roomId'],
              excludeEvent: eventId,
              checkAllDatesStart: datesStart,
              checkAllDatesEnd: datesEnd,
              archivedAt: NULLABLE.NULL,
              excludeBookings: [],
          }
      });
  }

  public componentDidMount(): void {
    const { dispatchTimezoneGet, dispatchEventGet, match: { params } } = this.props
    dispatchEventGet(params.id)
    dispatchTimezoneGet()
  }

  public handleSubmit = (body: EventFormInterface) => {

    let canReview = true,
        allowBookings = true;

    if (body.status == EVENT_STATUS.APPROVED && body.reservationType == EVENT_RESERVATION_TYPE.CONCLUDING)
    {
      if (body.bookings)
      {
        body.bookings.forEach(function(booking)
        {
          // if ( !booking.countPeople || (booking.countPeople && booking.countPeople == 0) )
          // {
          //   canReview = false;
          // }

          // if ( !booking.usageFormats || (booking.usageFormats && booking.usageFormats == []) )
          // {
          //   canReview = false;
          // }
        })
      }
    }

    if (body.bookings)
    {
      // let datesToCheck = {};
      let promises: Promise<any>[] = [];

      body.bookings.forEach((item) => {

        if (item.dismantlingDates && item.dismantlingDates[0] && item.dismantlingDates[1])
        {
          // datesToCheck['dismantlingDates'] = {
          //   'roomId': item.roomId,
          //   'dates': item.dismantlingDates
          // };
          promises.push(this.getDatesConflit({
            'roomId': item.roomId,
            'dates': item.dismantlingDates
          }));
        }
        if (item.dismantlingDatesAlt && item.dismantlingDatesAlt[0] && item.dismantlingDatesAlt[1])
        {
          // datesToCheck['dismantlingDatesAlt'] = {
          //   'roomId': item.roomId,
          //   'dates': item.dismantlingDatesAlt
          // };
          promises.push(this.getDatesConflit({
            'roomId': item.roomId,
            'dates': item.dismantlingDatesAlt
          }));
        }
        if (item.installingDates && item.installingDates[0] && item.installingDates[1])
        {
          // datesToCheck['installingDates'] = {
          //   'roomId': item.roomId,
          //   'dates': item.installingDates
          // };
          promises.push(this.getDatesConflit({
            'roomId': item.roomId,
            'dates': item.installingDates
          }));
        }
        if (item.installingDatesAlt && item.installingDatesAlt[0] && item.installingDatesAlt[1])
        {
          // datesToCheck['installingDatesAlt'] = {
          //   'roomId': item.roomId,
          //   'dates': item.installingDatesAlt
          // };
          promises.push(this.getDatesConflit({
            'roomId': item.roomId,
            'dates': item.installingDatesAlt
          }));
        }
      });

      // let promises: Promise<any>[] = [];
      // for (var key in datesToCheck) {
      //   promises.push(this.getDatesConflit(datesToCheck[key]));
      // }

      Promise.all(promises)
        .then((responses) => {

          let isAdmin = false;
          // if (this.props && this.props.eventFetch && this.props.eventFetch.value && this.props.eventFetch.value.author && this.props.eventFetch.value.author.role && this.props.eventFetch.value.author.role === USER_ROLE.ADMIN) {
          if (this.props && this.props.user && this.props.user.role && this.props.user.role === USER_ROLE.ADMIN) {
            isAdmin = true;
          }

            // Запрещаем согласование, если есть конфликты дат монтажа/демонтажа (для всех, кроме админов)
            if (responses) {
                responses.forEach(function(singleResponse) {
                    if (singleResponse.data.length > 0 && !isAdmin) {
                        allowBookings = false;
                    }
                })
            }

          // Incorrect
          // if (responses && responses.length > 0 && !isAdmin) {
          //   allowBookings = false;
          // }

          if (canReview && allowBookings)
          {
            const { dispatchEventPut, match: { params } } = this.props
            dispatchEventPut(params.id, body)
          } else {

            if (!allowBookings)
            {
              message.error('Согласование мероприятий с конфликтами дат монтажа/демонтажа запрещено!');
            } else {

              if (!canReview)
              {
                message.error('Заполните «Кол-во посетителей»!');
              }
            }
          }

        })
        .catch(error => {
            message.error('Ошибка axios: ' + error);
        });
    } else {

      if (canReview)
      {
        const { dispatchEventPut, match: { params } } = this.props
        dispatchEventPut(params.id, body)
      } else {
        // message.error('Заполните «Формат использования» и «Кол-во посетителей»!');
        message.error('Заполните «Кол-во посетителей»!');
      }
    }
  }

  public componentDidUpdate(prevProps: ComponentPropsInterface): void {
    if (prevProps.eventPutFetch.pending && this.props.eventPutFetch.fulfilled) {
      message.success('Мероприятие согласовано')
      this.props.history.push('/events')
    }
  }

  public render(): React.ReactNode {
    const { eventFetch, eventPutFetch, timezoneFetch } = this.props

    return (
      <>
        {
          eventFetch.pending && (
            <div className={styles.spinWrapper}>
              <Spin />
            </div>
          )
        }
        {
          eventFetch.fulfilled && eventFetch.value && timezoneFetch.fulfilled && (
            <>
              <div className={styles.authorWrapper}>
                <span className={styles.author}>Автор</span>{' '}
                {eventFetch.value.author && eventFetch.value.author.name}
              </div>
              <EventForm
			    createMode={false}
                reviewMode={true}
                initialValues={{
                  id: eventFetch.value.id,
                  authorId: eventFetch.value.authorId && eventFetch.value.authorId.toString(),
                  name: eventFetch.value.name,
                  typeId: eventFetch.value.typeId && eventFetch.value.typeId.toString(),
                  mealFormatId: eventFetch.value.mealFormatId && eventFetch.value.mealFormatId.toString(),
                  description: eventFetch.value.description,
                  targetAudience: eventFetch.value.targetAudience,
                  participantCount: eventFetch.value.participantCount,
                  participantCountMeal: eventFetch.value.participantCountMeal,
                  website: eventFetch.value.website,
                  ageRating: eventFetch.value.ageRating,
                  isSellingAlcohol: eventFetch.value.isSellingAlcohol.toString(),
                  enableSquareCheck: eventFetch.value.enableSquareCheck.toString(),
                  crmId: eventFetch.value.crmId,
                  bookings: timezoneFetch.value ? this.formatBookingsToBookingForm(eventFetch.value.bookings, timezoneFetch.value.value) : this.formatBookingsToBookingForm(eventFetch.value.bookings, null),
                  contents: timezoneFetch.value ? this.formatContentsToContentForm(eventFetch.value.contents, timezoneFetch.value.value) : this.formatBookingsToBookingForm(eventFetch.value.bookings, null),
                  startDate: timezoneFetch.value ? moment(eventFetch.value.startDate).utcOffset(timezoneFetch.value.value) : moment(eventFetch.value.startDate)
                }}
                onSubmit={this.handleSubmit}
                submitBlocked={eventPutFetch.pending}
              />
            </>
          )
        }
      </>
    )
  }
}

const mapStateToProps = (store: StoreInterface) => ({
  user: store.user.data
})

export default connect(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource:  'event',
      method: 'get',
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'events', id)
      })
    },
    {
      resource: {
        action: 'event',
        name: 'eventPut'
      },
      method: 'put',
      request: (id: string, body: EventFormInterface) => ({
        url: urljoin(config.backendUrl, 'events', id, 'review'),
        body
      })
    }
  ],
  mapStateToProps
)(Review)
