exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".booking__root___2SshY {\n  padding: 16px;\n}\n.booking__root___2SshY:not(:last-child) {\n  border-bottom: 1px solid #808080;\n}\n.booking__label___2wXWT {\n  font-weight: 600;\n}\n.booking__highlighted___8qDuD {\n  color: #f00;\n}\n", ""]);

// exports
exports.locals = {
	"root": "booking__root___2SshY",
	"label": "booking__label___2wXWT",
	"highlighted": "booking__highlighted___8qDuD"
};