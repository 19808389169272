import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import urljoin from 'url-join'
import { PageHeader, Button, Popconfirm, Spin, message } from 'antd'
import { autobind } from 'core-decorators'
import { IUsageFormat } from 'types/models'
import { submit, FormAction } from 'redux-form'
import { USAGE_FORMAT_FORM } from 'constants/UsageFormat'
import config from 'common/config'

import Form from '../_shared/form/Form'
import { UsageFormatFormInterface } from '../_shared/form/types'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  usageFormatGetFetch: PromiseState<IUsageFormat>
  usageFormatPutFetch: PromiseState<IUsageFormat>
  usageFormatDeleteFetch: PromiseState

  dispatchUsageFormatGet(id: string): void
  dispatchUsageFormatPut(id: string, data: UsageFormatFormInterface): void
  dispatchUsageFormatDelete(id: string): void

  dispatch(value: FormAction): void
}

class Edit extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchUsageFormatGet(this.props.match.params.id)
  }

  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(USAGE_FORMAT_FORM))
  }

  @autobind
  public handleDelete(): void {
    if (this.props.usageFormatGetFetch.value) {
      this.props.dispatchUsageFormatDelete(String(this.props.usageFormatGetFetch.value.id))
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.usageFormatDeleteFetch.pending && this.props.usageFormatDeleteFetch.fulfilled) {
      message.success('Параметр удален')
      this.props.history.push(`/admin/usage-formats`)
    }

    if (prevProps.usageFormatPutFetch.pending && this.props.usageFormatPutFetch.fulfilled) {
      message.success('Параметр сохранен')
      this.props.dispatchUsageFormatGet(this.props.match.params.id)
    }
  }

  @autobind
  public handeSubmit(values: UsageFormatFormInterface): void {
    this.props.dispatchUsageFormatPut(
      this.props.match.params.id,
      values
    )
  }

  public render(): React.ReactNode {
    if (!this.props.usageFormatGetFetch.value) {
      return <Spin/>
    }

    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Параметр'
          subTitle='Редактирование'
          extra={[
            <Popconfirm
              key='delete'
              title='Действительно удалить параметр?'
              onConfirm={this.handleDelete}
              okText='Удалить'
              cancelText='Отмена'
            >
              <Button
                type='danger'
              >
                Удалить
              </Button>
            </Popconfirm>,
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Сохранить
            </Button>
          ]}
          />
        <Form
          initialValues={this.props.usageFormatGetFetch.value}
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'usageFormat',
        name: 'usageFormatGet'
      },
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'usage-formats', id)
      })
    },
    {
      resource: {
        action: 'usageFormat',
        name: 'usageFormatPut'
      },
      method: 'put',
      request: (id: string, body: UsageFormatFormInterface) => ({
        url: urljoin(config.backendUrl, 'usage-formats', id),
        body
      })
    },
    {
      resource: {
        action: 'usageFormat',
        name: 'usageFormatDelete'
      },
      method: 'delete',
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'usage-formats', id)
      })
    }
  ]
)(Edit)
