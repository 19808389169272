import React from 'react'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect'
import { connect } from 'react-redux'
import { Form, Button, Input, Icon, Card, Alert } from 'antd'
import { Dispatch } from 'redux'

import actions from '../../../actions/User'
import { StoreInterface } from '../../../store'

import styles from './styles.styl'

type LoginParams = {
  login: string;
  password: string;
}

interface Props extends InjectedAuthRouterProps {
  error?: string
  login(data: LoginParams): void
  restoreToken(): void
}

type State = LoginParams

class Login extends React.Component<Props, State> {
  public state: State = {
    login: '',
    password: ''
  }

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.props.login({ login: this.state.login, password: this.state.password })
  }

  public handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name

    // tslint:disable-next-line:no-any
    this.setState({ [name]: value } as any)
  }

  public componentDidMount = () => {
    this.props.restoreToken()
  }

  public render = () => (
    <div className={styles.loginWrap}>
      <Card title='Календарь мероприятий' className={styles.login}>
        {this.props.error && <Alert message={this.props.error} type='error'/>}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            <Input
              name='login'
              prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Логин'
              onChange={this.handleInputChange}
              value={this.state.login}
            />
          </Form.Item>
          <Form.Item>
            <Input
              name='password'
              prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
              type='password'
              placeholder='Пароль'
              onChange={this.handleInputChange}
              value={this.state.password}
            />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            Войти
          </Button>
        </Form>
      </Card>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: (payload: LoginParams) => { dispatch(actions.login(payload.login, payload.password)) },
    restoreToken: () => { dispatch(actions.restoreToken()) }
  }
}

const mapStateToProps = (state: StoreInterface) => {
  if (state.user.error === 'Request failed with status code 401') {
    return {
      error: 'Неверное имя пользователя или пароль'
    }
  }

  return {
    error: state.user.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
