import React from 'react'
import * as Moment from 'moment'
import { StatusReason, EVENT_STATUS_LOCALIZED, EVENT_RESERVATION_LOCALIZED } from 'types/models'
import { Popover, List, Descriptions } from 'antd'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'

import * as styles from './styles.styl'

interface ComponentPropsInterface {
  id: number
  statusReason: StatusReason[]
  title: string
}

const StatusReasonPopover: React.FC<ComponentPropsInterface> = ({ id, title, statusReason }: ComponentPropsInterface) => (
  <Popover
    trigger='hover'
    key={id}
    title={title}
    content={
      <List className={styles.root}>
        {
          statusReason.length > 0 ?
            statusReason.map((item: StatusReason, idx: number) => (
              <List.Item key={idx}>
                <Descriptions size='small' column={2}>
                  <Descriptions.Item label='Статус согласования'>
                    {EVENT_STATUS_LOCALIZED[item.status]}
                  </Descriptions.Item>
                  <Descriptions.Item label='Статус брони'>
                    {EVENT_RESERVATION_LOCALIZED[item.reservationType]}
                  </Descriptions.Item>
                  <Descriptions.Item label='Причина'>
                    {item.reason}
                  </Descriptions.Item>
                  <Descriptions.Item label='Дата согласования'>
                    {item.date && Moment(item.date).format(HUMAN_DATETIME_FORMAT)}
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>
            )) :
            'Нет истории согласований'
        }
      </List>
      }
  >
    {/* tslint:disable-next-line: react-a11y-anchors */}
    <a className={styles.linkPopup}>{title}</a>
  </Popover>
)

export default StatusReasonPopover
