import { Row } from 'antd'
import { FILE_METHOD_FORM } from 'constants/FileMethod'
import { autobind } from 'core-decorators'
import React from 'react'
import { connect } from 'react-redux'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import { TextField } from 'redux-form-antd'

import { validate } from './helpers'

interface Props extends InjectedFormProps {
  onSubmit(values: FileMethodFormInterface): void
}

export interface FileMethodFormInterface {
  name: string
}

class FileMethodForm extends React.Component<Props> {
  @autobind
  public handleSubmit(values: FileMethodFormInterface): void {
    this.props.onSubmit(values)
  }

  public render(): React.ReactNode {
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <Row>
          <label>Название</label>
          <Field
            name='name'
            component={TextField}
            placeholder='Способ'
          />
        </Row>
      </Form>
    )
  }
}

export default connect()(reduxForm(
  {
    form: FILE_METHOD_FORM,
    enableReinitialize: true,
    // tslint:disable-next-line:no-any
    validate: validate as any
  }
)(FileMethodForm))
