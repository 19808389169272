import React from 'react'
import { DateRange } from 'moment-range'
import moment from 'moment'
import * as R from 'ramda'
import classnames from 'classnames'
import { Row, Col, Input, Popover, Affix, Icon, Empty } from 'antd'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { Room, Event } from 'types/models'

import { default as RoomComponent } from './room/Room'
import * as styles from './rooms.styl'

interface Props {
  timezone?: any
  rooms: Room[]
  range: DateRange
  selectedEvent?: Event
  expandedGroups: (string | null)[]
  roomFilter?: string
  toggleGroup(key: string | null): void
  roomFilterChange(value: React.ChangeEvent<HTMLInputElement>): void
}

//this code looks like shit, but its perfomant on huge lists, sorry
class Rooms extends React.Component<Props> {
  public groupRooms: (rooms: Room[]) => Record<string, Room[]> = R.groupBy(R.prop('typeId'))

  private readonly CALENDAR_SCROLL_GROUP: string = 'calendar'

  public isRoomVisibleByFilter = (room: Room) =>
    this.props.roomFilter ? room.name.toLocaleLowerCase().includes(this.props.roomFilter) : true

  public isGroupVisibleByFilter = (rooms: Room[]) =>
    rooms.some(this.isRoomVisibleByFilter)

  public isAnyGroupVisibleByFilter = (groups: Record<string, Room[]>) =>
    Object
      .values(groups)
      .some(this.isGroupVisibleByFilter)

  // tslint:disable-next-line:max-func-body-length
  public render(): React.ReactNode {
    const {
      timezone,
      rooms,
      range,
      selectedEvent,
      expandedGroups,
      roomFilterChange,
      toggleGroup
    } = this.props

    const groupedRooms: Record<string, Room[]> = this.groupRooms(rooms)

    return (
      <Row>
        <Col span={4}>
          <Affix offsetTop={0}>
            <div className={styles.sideRowHeader}>
              <Input onChange={roomFilterChange} placeholder='Название помещения'/>
            </div>
          </Affix>
          {
            R.pipe(
              R.mapObjIndexed(
                (value: Room[], key: string | null) => {
                  return [
                    // tslint:disable-next-line:react-a11y-event-has-role
                    this.isGroupVisibleByFilter(value) && <div
                      className={classnames(styles.sideRow, styles.spoiler)}
                      onClick={() => { toggleGroup(key) }}
                    >
                      {value[0].type ? value[0].type.name : 'Без типа'}{' '}
                      {
                        expandedGroups.includes(key) ?
                          <Icon type='down' /> :
                          <Icon type='line' />
                      }
                    </div>,
                    ...(expandedGroups.includes(key) ?
                    value.map((room: Room) => this.isRoomVisibleByFilter(room) && (
                      <Popover key={room.id} content={room.name}>
                        <div className={styles.sideRow}>
                          { room.name }
                        </div>
                      </Popover>
                    )) :
                    [])
                  ]
                }
              ),
              R.values
            )(groupedRooms)
          }
        </Col>
        <Col span={20} className={styles.rooms}>
          <ScrollSync>
            <div>
              <Affix offsetTop={0}>
                <ScrollSyncPane group={this.CALENDAR_SCROLL_GROUP}>
                  <div className={classnames(styles.scrollablePane, styles.hideScrollbar)}>
                    <div className={styles.header}>
                      {
                        Array
                          .from(range.by('day'))
                          .map(
                            (date: moment.Moment, i: number) => (
                              <div key={i} className={styles.headerBlock}>
                                {(i === 0 || date.get('date') === 1) && date.format('MMMM')}
                              </div>
                            )
                          )
                        }
                    </div>
                    <div className={styles.header}>
                      {
                        Array
                          .from(range.by('day'))
                          .map(
                            (date: moment.Moment, i: number) => (
                              <div
                                key={i}
                                className={classnames(
                                  styles.headerBlock,
                                  { [styles.weekend]: [0, 6].includes(date.day()) }
                                )}
                              >
                                {date.format('ddd')}
                              </div>
                            )
                          )
                        }
                    </div>
                  </div>
                </ScrollSyncPane>
              </Affix>
              <ScrollSyncPane group={this.CALENDAR_SCROLL_GROUP}>
                <div className={styles.scrollablePane}>
                  {!this.isAnyGroupVisibleByFilter(groupedRooms) && <Empty />}
                  {
                    R.pipe(
                      R.mapObjIndexed(
                        (value: Room[], key: string | null) => {
                          return [
                            this.isGroupVisibleByFilter(value) && <div
                              className={styles.spoilerRow}
                              key={`header-${key}`}
                            >
                              {
                                Array
                                  .from(range.by('day'))
                                  .map(
                                    (date: moment.Moment, i: number) => (
                                      <div
                                        key={`spoilerline-${i}-${key}`}
                                        className={styles.spoilerBlock}
                                      >
                                        -
                                      </div>
                                    )
                                  )
                                }
                            </div>,
                            // tslint:disable-next-line:use-simple-attributes
                            ...value.map((room: Room) => <RoomComponent
                              key={`room-${room.id}`}
                              room={room}
                              range={range}
                              selectedEvent={selectedEvent}
                              hidden={!expandedGroups.includes(key) || !this.isRoomVisibleByFilter(room)}
                              timezone={timezone}
                            />)
                          ]
                        }
                      ),
                      R.values,
                      R.flatten
                    )(groupedRooms)
                  }
                </div>
              </ScrollSyncPane>
            </div>
          </ScrollSync>
        </Col>
      </Row>
    )
  }
}

export default Rooms
