exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__descLabel___3aeM_ {\n  white-space: break-spaces !important;\n}\n", ""]);

// exports
exports.locals = {
	"descLabel": "styles__descLabel___3aeM_"
};