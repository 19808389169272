export enum Permissions {
  EVENT_READ = 'EVENT_READ',
  EVENT_CREATE = 'EVENT_CREATE',
  EVENT_EDIT = 'EVENT_EDIT',
  EVENT_DELETE = 'EVENT_DELETE',
  EVENT_REVIEW = 'EVENT_REVIEW',
  EVENT_ARCHIVE = 'EVENT_ARCHIVE',

  ROOM_READ = 'ROOM_READ',
  ROOM_CREATE = 'ROOM_CREATE',
  ROOM_EDIT = 'ROOM_EDIT',
  ROOM_DELETE = 'ROOM_DELETE',

  USER_READ = 'USER_READ',
  USER_CREATE = 'USER_CREATE',
  USER_EDIT = 'USER_EDIT',
  USER_DELETE = 'USER_DELETE'
}
