import { RadioChangeEvent } from 'antd/lib/radio'
import { Radio, Table, Button, Row, Col, message, Input, Select, Checkbox } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { autobind } from 'core-decorators'
import { AllowedTo, AbacContext, AbacContextProps } from 'react-abac'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect'
import urljoin from 'url-join'
import connect from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { Permissions } from 'permissions/permissions'
import { Event, EVENT_STATUS, EVENT_STATUS_LOCALIZED, EVENT_RESERVATION_TYPE, EVENT_RESERVATION_LOCALIZED, FILTER_NEW, FILTER_NEW_LOCALIZED } from 'types/models'
import config from 'common/config'
import { StoreInterface } from 'store'
// import { ColumnProps, SorterResult, PaginationConfig } from 'antd/lib/table'
import { SorterResult, PaginationConfig } from 'antd/lib/table'
import { HUMAN_DATE_FORMAT } from 'constants/DateTime'
import moment from 'moment'
import RangePicker from 'components/shared/form/rangePicker/RangePicker'
import { rangeSelectionToMomentDateRange } from 'utils/date.utils'

import getColumns from './getColumns'
import { QueryInterface, MapStateToPropsType, StateInterface, ComponentPropsInterface, NULLABLE } from './types'
import { Tabs, scrollXWidth, sizeOfPadginationControl, sizeOfPaddings, TAB_TO_TAB_NAME } from './constants'
import * as styles from './styles.styl'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const { Search } = Input;

class Index extends React.Component<ComponentPropsInterface & InjectedAuthRouterProps, StateInterface> {
    // tslint:disable-next-line:no-any
    public static contextType: React.Context<AbacContextProps<any>> = AbacContext
    public tableWrapperRef: React.RefObject<HTMLDivElement> = React.createRef()

    public readonly defaultPagination: PaginationConfig = {
        total: undefined,
        current: 1,
        pageSize: 10,
        hideOnSinglePage: true
    }

    public state: StateInterface = {
        searchString: undefined,
        tab: Tabs.ALL,
        pagination: this.defaultPagination,
        availableHeight: undefined,
        dates: [moment().startOf('year'), moment().endOf('year')],
        eventTypes: [],
        mealFormats: [],
        eventStatus: [],
        eventReservation: [],
        manager: [],
        room: [],
        overdue: false,
        overdueDays: '0',
        newRange: 'ALL',
    }

    // public timezone = this.props.timezoneFetch.value ? this.props.timezoneFetch.value.value : null;
    // private readonly columns: ColumnProps<Event>[] = getColumns(this.handleArchive, this.timezone)

    public componentDidMount(): void {
        this.props.dispatchEventTypesGet();
        this.props.dispatchMealFormatsGet();
        this.props.dispatchUsersGet();
        this.props.dispatchRoomsGet();
        this.props.dispatchOptionTimeGet();
        this.props.dispatchTimezoneGet();

        this.setState(
            { tab: this.context.userHasPermissions(Permissions.EVENT_CREATE) ? Tabs.OWN : Tabs.ALL },
            () => {
                this.getAvailableHeight()
                this.fetchEventsCount()
                this.fetchEvents()
            }
        )
    }

    public componentWillReceiveProps(nextProps: ComponentPropsInterface): void {
        if (
            this.props.eventsCountFetch.pending && !nextProps.eventsCountFetch.pending ||
            this.props.optionTimeFetch.pending && !nextProps.optionTimeFetch.pending
           ) {
            this.setState((state: StateInterface): StateInterface => ({
                ...state,
                overdueDays: (nextProps.optionTimeFetch.value ) ? (() => {
                    const item = nextProps.optionTimeFetch.value.find(i => i.slug === 'check_time_booking')
                    return (item) ? item.value.toString() : '0'
                })() : '0',
                pagination: {
                    ...state.pagination,
                    total: nextProps.eventsCountFetch.value
                }
            }))
        }

        if (this.props.eventArchiveFetch.pending && !nextProps.eventArchiveFetch.pending) {
            message.info('Событие отправлено в архив')
            this.fetchEventsCount()
            this.fetchEvents()
        }
    }

    public render(): React.ReactNode {
        const { eventsFetch, eventsCountFetch, eventTypesFetch, mealFormatsFetch, usersFetch, roomsFetch, optionTimeFetch, timezoneFetch } = this.props
        const { tab, pagination, availableHeight } = this.state

        const pending = eventsFetch.pending || eventsCountFetch.pending || eventTypesFetch.pending || mealFormatsFetch.pending || usersFetch.pending || roomsFetch.pending || optionTimeFetch.pending || timezoneFetch.pending

        return (
            <div className={styles.root}>
                <Row type='flex' align='middle'>
                    <Col span={7}>
                        <Row>
                            <Radio.Group
                                value={tab}
                                onChange={this.radioGroupChangeHandler}
                                className={styles.switcher}
                                buttonStyle='solid'
                            >
                                <AllowedTo perform={Permissions.EVENT_CREATE}>
                                    <Radio.Button value={Tabs.OWN}>{TAB_TO_TAB_NAME[Tabs.OWN]}</Radio.Button>
                                </AllowedTo>
                                <Radio.Button value={Tabs.ALL}>{TAB_TO_TAB_NAME[Tabs.ALL]}</Radio.Button>
                                <Radio.Button value={Tabs.PENDING}>{TAB_TO_TAB_NAME[Tabs.PENDING]}</Radio.Button>
                                <Radio.Button value={Tabs.ARCHIVE}>{TAB_TO_TAB_NAME[Tabs.ARCHIVE]}</Radio.Button>
                            </Radio.Group>
                        </Row>
                    </Col>
                    <Col span={5} >
                        {
                            this.props.optionTimeFetch.value &&
                            <>
                                <Checkbox
                                    onChange={this.handlerOverdueDate}
                                    checked={this.state.overdue}>Просрочена дата перевода в железную бронь ({this.state.overdueDays} д)</Checkbox>
                            </>
                        }
                    </Col>
                    <Col style={{ marginLeft: 'auto' }} >
                        <AllowedTo
                            perform={Permissions.EVENT_CREATE}
                        >
                            <div>
                                <Link to='/events/create'>
                                    <Button type='primary'>Новое мероприятие</Button>
                                </Link>
                            </div>
                        </AllowedTo>
                    </Col>
                </Row>
                <Row type='flex' justify='start' gutter={8}>
                    {
                        (this.props.usersFetch.value) &&
                        <Col>
                            <Select
                                mode="multiple"
                                placeholder="Менеджер"
                                style={{ width: 260 }}
                                onChange={this.handleManagerChange}
                            >
                                {this.props.usersFetch.value.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
                            </Select>
                        </Col>
                    }
                    {
                        this.props.eventTypesFetch.value &&
                        <Col>
                            <Select
                                mode="multiple"
                                placeholder="Тип мероприятия"
                                style={{ width: 260 }}
                                onChange={this.handleEventTypeChange}
                            >
                                {this.props.eventTypesFetch.value.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
                            </Select>
                        </Col>
                    }
                    {
                        this.props.mealFormatsFetch.value &&
                        <Col>
                            <Select
                                mode="multiple"
                                placeholder="Формат питания"
                                style={{ width: 260 }}
                                onChange={this.handleMealFormatChange}
                            >
                                {this.props.mealFormatsFetch.value.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
                            </Select>
                        </Col>
                    }
                    {
                        tab !== Tabs.PENDING &&
                        <Col>
                            <Select
                                mode="multiple"
                                placeholder="Cтатус согласования"
                                style={{ width: 260 }}
                                onChange={this.handleStatusChange}
                            >
                                {[EVENT_STATUS.DECLINED, EVENT_STATUS.APPROVED].map((value: EVENT_STATUS) => <Select.Option key={value} value={value}>{`${EVENT_STATUS_LOCALIZED[value]}`}</Select.Option>)}
                            </Select>
                        </Col>
                    }
                    {
                        !this.state.overdue && <Col>
                            <Select
                                mode="multiple"
                                placeholder="Cтатус брони"
                                style={{ width: 260 }}
                                onChange={this.handleReservationChange}
                            >
                                {[EVENT_RESERVATION_TYPE.CONCLUDING, EVENT_RESERVATION_TYPE.PRELIMINARY].map((value: EVENT_RESERVATION_TYPE) => <Select.Option key={value} value={value}>{`${EVENT_RESERVATION_LOCALIZED[value]}`}</Select.Option>)}
                            </Select>
                        </Col>
                    }
                    {
                        this.props.roomsFetch.value &&
                        <Col>
                            <Select
                                mode="multiple"
                                placeholder="Помещение"
                                style={{ width: 260 }}
                                onChange={this.handleRoomChange}
                            >
                                {this.props.roomsFetch.value.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
                            </Select>
                        </Col>
                    }
                </Row>
                <Row type='flex' justify='start' gutter={8}>
                    <Col>
                        <Search
                            allowClear
                            style={{ width: 400 }}
                            placeholder="Поиск по названию"
                            enterButton="Искать"
                            onSearch={this.searchByNameHandler}
                            onChange={this.searchClearHandler}
                        />
                    </Col>
                    {
                        !this.state.overdue && <Col>
                            <RangePicker
                                className={styles.filterRange}
                                format={HUMAN_DATE_FORMAT}
                                defaultValue={[
                                    timezoneFetch.value ? moment().startOf('year').utcOffset(timezoneFetch.value.value) : moment().startOf('year'),
                                    timezoneFetch.value ? moment().endOf('year').utcOffset(timezoneFetch.value.value) : moment().endOf('year')
                                ]}
                                onChange={this.handleRangeChange}
                                allowClear={false}
                                ranges={{
                                    'Этот месяц': [
                                        timezoneFetch.value ? moment().startOf('month').utcOffset(timezoneFetch.value.value) : moment().startOf('month'),
                                        timezoneFetch.value ? moment().endOf('month').utcOffset(timezoneFetch.value.value) : moment().endOf('month')
                                    ],
                                    'Следующий месяц': [
                                        timezoneFetch.value ? moment().add(1, 'month').startOf('month').utcOffset(timezoneFetch.value.value) : moment().add(1, 'month').startOf('month'),
                                        timezoneFetch.value ? moment().add(1, 'month').endOf('month').utcOffset(timezoneFetch.value.value) : moment().add(1, 'month').endOf('month')
                                    ]
                                }}
                            />
                        </Col>
                    }
                    <Col>
                        <Select
                            placeholder="Новые"
                            style={{ width: 260 }}
                            onChange={this.handleNewChange}
                        >
                            {[FILTER_NEW.ALL, FILTER_NEW.DAY, FILTER_NEW.WEEK, FILTER_NEW.MONTH].map((value: FILTER_NEW) => <Select.Option key={value} value={value}>{`${FILTER_NEW_LOCALIZED[value]}`}</Select.Option>)}
                        </Select>
                    </Col>
                </Row>
                <div
                    ref={this.tableWrapperRef}
                    className={styles.tableWrapper}
                >
                    {availableHeight && (
                        <Table
                            rowKey={this.getRowKey}
                            // columns={this.columns}
                            columns={timezoneFetch.value ? getColumns(this.handleArchive, timezoneFetch.value.value) : getColumns(this.handleArchive)}
                            loading={pending}
                            dataSource={eventsFetch.value}
                            pagination={pagination}
                            onChange={this.tableChangeHandler}
                            scroll={{ x: scrollXWidth, y: availableHeight }}
                        />
                    )}
                </div>
            </div>
        )
    }

    @autobind
    public handleNewChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                newRange: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    public handleRoomChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                room: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    public handleManagerChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                manager: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    public handleReservationChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                eventReservation: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    public handleStatusChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                eventStatus: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    public handleEventTypeChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                eventTypes: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    public handleMealFormatChange(value): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                mealFormats: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    public handleRangeChange = (dates: [moment.Moment, moment.Moment]) => {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                dates: dates,
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    private searchByNameHandler(value): void {

        value = value.replace(/\s/g, '').toLowerCase();
        if (value == '') { value = undefined }

        this.setState(
            (state: StateInterface) => ({
                ...state,
                pagination: this.defaultPagination,
                searchString: value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    };

    @autobind
    private searchClearHandler(e): void {

        let value = e.target.value;

        if (!value || value == '') {
            this.setState(
                (state: StateInterface) => ({
                    ...state,
                    pagination: this.defaultPagination,
                    searchString: undefined
                }),
                () => {
                    this.fetchEventsCount()
                    this.fetchEvents()
                })
        }
    };

    @autobind
    private handleArchive(event: Event): void {
        this.props.dispatchEventArchivePut(event.id)
    }

    @autobind
    private getAvailableHeight(): void {
        if (!this.tableWrapperRef.current) {
            requestAnimationFrame(this.getAvailableHeight)
        } else {
            this.setState({
                availableHeight: window.innerHeight - (this.tableWrapperRef.current.offsetTop + sizeOfPadginationControl + sizeOfPaddings)
            })
        }
    }

    @autobind
    private getRowKey(record: Event): string {
        return record.id.toString()
    }

    @autobind
    private radioGroupChangeHandler(e: RadioChangeEvent): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                pagination: this.defaultPagination,
                tab: e.target.value
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    private handlerOverdueDate(e: CheckboxChangeEvent): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                pagination: this.defaultPagination,
                overdue: e.target.checked
            }),
            () => {
                this.fetchEventsCount()
                this.fetchEvents()
            })
    }

    @autobind
    private tableChangeHandler(
        pagination: PaginationConfig,
        filters: Record<keyof Event, string[]>,
        sorter: SorterResult<Event>
    ): void {
        this.setState(
            (state: StateInterface) => ({
                ...state,
                pagination,
                sorter: sorter.columnKey ? sorter : undefined
            }),
            () => {
                this.fetchEvents()
            }
        )
    }

    @autobind
    private fetchEvents(): void {
        const { pagination, sorter } = this.state

        this.props.dispatchEventsGet({
            ...this.getFilterQuery(),
            pageSize: pagination.pageSize,
            page: pagination.current,
            ...(sorter
                ? {
                    sortColumn: sorter.columnKey,
                    sortOrder: sorter.order.slice(0, -3) // ascend, descend to asc, desc
                }
                : {})
        })
    }

    @autobind
    private getFilterQuery(): Partial<QueryInterface> {
        const { currentUserId } = this.props
        const { searchString, tab, dates, eventTypes, mealFormats, eventStatus, eventReservation, manager, room, overdue, overdueDays, newRange } = this.state

        let range = rangeSelectionToMomentDateRange(dates);

        let eventStatusVal: string[] = [];
        if (tab !== Tabs.PENDING && eventStatus) {
            eventStatusVal = eventStatus
        }

        return {
            authorId: tab === Tabs.OWN ? currentUserId : undefined,
            status: tab === Tabs.PENDING ? EVENT_STATUS.PENDING : undefined,
            archivedAt: tab === Tabs.ARCHIVE ? NULLABLE.NOT_NULL : NULLABLE.NULL,
            searchString: searchString,
            startDate: range.start.startOf('day').toISOString(),
            endDate: range.end.endOf('day').toISOString(),
            eventTypes: eventTypes,
            mealFormats: mealFormats,
            eventStatus: eventStatusVal,
            eventReservation: eventReservation,
            manager: manager,
            room: room,
            overdue: overdue.toString(),
            overdueDays: overdueDays,
            newRange: newRange,
        }
    }

    @autobind
    private fetchEventsCount(): void {
        this.props.dispatchEventsCountGet(this.getFilterQuery())
    }
}

const mapStateToProps: MapStateToPropsType = (state: StoreInterface) => ({
    currentUserId: state.user.data && state.user.data.id
})

export default connect(
    [
        {
            resource:  'timezone',
            method: 'get',
            request: () => ({
                url: urljoin(config.backendUrl, 'timezone')
            })
        },
        {
            resource: 'rooms',
            method: 'get',
            request: {
                url: urljoin(config.backendUrl, 'rooms')
            }
        },
        {
            resource: 'users',
            method: 'get',
            request: {
                url: urljoin(config.backendUrl, 'users')
            }
        },
        {
            resource: 'eventTypes',
            method: 'get',
            request: () => ({
                url: urljoin(config.backendUrl, 'eventTypes')
            })
        },
        {
            resource: 'mealFormats',
            method: 'get',
            request: () => ({
                url: urljoin(config.backendUrl, 'mealFormats')
            })
        },
        {
            resource: 'optionTime',
            method: 'get',
            request: () => ({
                url: urljoin(config.backendUrl, 'options?slug=check_time_booking'),
            })
        },
        {
            resource: {
                action: 'events',
                name: 'events'
            },
            method: 'GET',
            request: (query: Partial<QueryInterface>) => ({
                url: urljoin(config.backendUrl, `events?${queryString.stringify(query)}`)
            })
        },
        {
            resource: {
                action: 'eventsCount',
                name: 'eventsCount'
            },
            method: 'GET',
            request: (query: Pick<QueryInterface, 'authorId' | 'status'>) => ({
                url: urljoin(config.backendUrl, `events/count?${queryString.stringify(query)}`)
            })
        },
        {
            resource: {
                action: 'eventArchive',
                name: 'eventArchive'
            },
            method: 'PUT',
            request: (eventId: number) => ({
                url: urljoin(config.backendUrl, 'events', String(eventId), 'archive'),
                comparison: eventId
            })
        }
    ],
    mapStateToProps
)(Index)
