import { Button, message, PageHeader, Spin } from 'antd'
import config from 'common/config'
import { MEAL_FORMAT_FORM } from 'constants/MealFormat'
import { autobind } from 'core-decorators'
import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { FormAction, submit } from 'redux-form'
import { MealFormat } from 'types/models'
import urljoin from 'url-join'

import Form, { MealFormatFormInterface } from '../_shared/form/Form'
import { formatToForm } from '../_shared/form/helpers'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  mealFormatGetFetch: PromiseState<MealFormat>
  mealFormatPutFetch: PromiseState<MealFormat>

  dispatchMealFormatGet(id: string): void
  dispatchMealFormatPut(id: string, data: FormData): void

  dispatch(value: FormAction): void
}

class Edit extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchMealFormatGet(this.props.match.params.id)
  }

  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(MEAL_FORMAT_FORM))
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.mealFormatPutFetch.pending && this.props.mealFormatPutFetch.fulfilled) {
      message.success('Формат питания сохранен')
      this.props.dispatchMealFormatGet(this.props.match.params.id)
    }
  }

  @autobind
  public handeSubmit(values: FormData): void {
    this.props.dispatchMealFormatPut(
      this.props.match.params.id,
      values
    )
  }

  public render(): React.ReactNode {
    if (!this.props.mealFormatGetFetch.value) {
      return <Spin/>
    }

    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Формат питания'
          subTitle='Редактирование'
          extra={[
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Сохранить
            </Button>
          ]}
        />
        <Form
          initialValues={formatToForm(this.props.mealFormatGetFetch.value)}
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'mealFormat',
        name: 'mealFormatGet'
      },
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'mealFormats', id)
      })
    },
    {
      resource: {
        action: 'mealFormat',
        name: 'mealFormatPut'
      },
      method: 'put',
      request: (id: string, body: MealFormatFormInterface) => ({
        url: urljoin(config.backendUrl, 'mealFormats', id),
        body
      })
    }
  ]
)(Edit)
