import { RoomTypesConfig } from 'types/models'

import { RoomTypesConfigFormInterface } from './Form'

type RoomTypesConfigFormValidation = {
  [F in keyof RoomTypesConfigFormInterface]?: string
}

export function validate(values: RoomTypesConfigFormInterface): RoomTypesConfigFormValidation {
  const errors: RoomTypesConfigFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  return errors
}

export function formatToForm(values: RoomTypesConfig): RoomTypesConfigFormInterface {
  return {
    name: values.name,
  }
}
