import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
import 'antd/dist/antd.css'
import { ConfigProvider } from 'antd'
import { default as ru } from 'antd/es/locale/ru_RU'

import * as serviceWorker from './serviceWorker'
import store from './store'
import browserHistory from './browserHistory'

ReactDOM.render(
  <ConfigProvider locale={ru}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <App />
      </Router>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
