exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__rangePicker___1svEH {\n  width: 450px !important;\n}\n", ""]);

// exports
exports.locals = {
	"rangePicker": "styles__rangePicker___1svEH"
};