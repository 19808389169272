import { FileMethod } from 'types/models'

import { FileMethodFormInterface } from './Form'

type FileMethodFormValidation = {
  [F in keyof FileMethodFormInterface]?: string
}

export function validate(values: FileMethodFormInterface): FileMethodFormValidation {
  const errors: FileMethodFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  return errors
}

export function formatToForm(values: FileMethod): FileMethodFormInterface {
  return {
    name: values.name,
  }
}
