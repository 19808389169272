import React from 'react'
import * as R from 'ramda'
import connect, { PromiseState } from 'react-redux-fetch'
import { reduxForm, InjectedFormProps, Field, Form, formValueSelector } from 'redux-form'
import { TextField, RadioField, SelectField } from 'redux-form-antd'
import { autobind } from 'core-decorators'
import urljoin from 'url-join'
import { RcFile } from 'antd/lib/upload'
import { Form as AntForm, Row, Col, Spin } from 'antd'
import { Store } from 'redux'
import Dropzone from 'components/shared/form/dropzone/Dropzone'
import { ROOM_FORM } from 'constants/Room'
import config from 'common/config'
import { RoomType, RoomTypesConfig } from 'types/models'

import { formatFromForm, validate } from './helpers'
import * as styles from './form.styl'

interface Props extends InjectedFormProps {
  isPavilion: string
  schemaUrl: string
  roomTypesFetch: PromiseState<RoomType[]>
  roomTypesConfigsFetch: PromiseState<RoomTypesConfig[]>

  onSubmit(values: FormData): void
  dispatchRoomTypesGet(): void
  dispatchRoomTypesConfigsGet(): void
}

export interface RoomFormInterface {
  name: string
  isPavilion: string
  schema?: RcFile
  typeId: number
  squares?: number
  configs?: []
}

class RoomForm extends React.Component<Props> {
  @autobind
  public handleSubmit(values: RoomFormInterface): void {
    this.props.onSubmit(formatFromForm(values))
  }

  public componentDidMount(): void {
    this.props.dispatchRoomTypesGet()
    this.props.dispatchRoomTypesConfigsGet()
  }

  public render(): React.ReactNode {

    // console.log('this.props');
    // console.log(this.props);

    // console.log('roomTypesConfigsFetch');
    // console.log(this.props.roomTypesConfigsFetch.value);

    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <Row>
          <Col span={7}>
            <label>Название</label>
            <Field
              name='name'
              component={TextField}
              placeholder='Павильон №2'
            />
          </Col>
          <Col span={7} offset={1}>
            <label>Тип помещения</label>
            <Spin spinning={this.props.roomTypesFetch.pending}>
              <Field
                name='typeId'
                component={SelectField}
                options={
                  R.map(
                    (roomType: RoomType) => ({ label: roomType.name, value: roomType.id }),
                    this.props.roomTypesFetch.value || []
                  )
                }
                placeholder='Не задано'
                hasFeedback={false}
                allowClear
              />
            </Spin>
          </Col>
          <Col span={7} offset={1}>
            <label>Павильон</label>
            <Field
              name='isPavilion'
              component={RadioField}
              options={[
                {
                  label: 'Да',
                  value: true
                },
                {
                  label: 'Нет',
                  value: false
                }
              ]}
              hasFeedback={false}
            />
          </Col>
          <Col span={7} offset={0}>
            <label>Конфигурации</label>
            <Field
              name='configs'
              mode="multiple"
              component={SelectField}
              options={
                R.map(
                  (roomTypesConfig: RoomTypesConfig) => ({ label: roomTypesConfig.name, value: roomTypesConfig.id }),
                  this.props.roomTypesConfigsFetch.value || []
                )
              }
              placeholder='Не задано'
              hasFeedback={false}
              allowClear
            />
          </Col>
          {
            this.props.isPavilion === 'true' &&
            <Col span={7} offset={1}>
              <label>Площадь</label>
              <Field
                name='squares'
                component={TextField}
                placeholder='30'
              />
            </Col>
          }
        </Row>

        {
          this.props.isPavilion === 'true' &&
            <AntForm.Item>
              <label className={styles.pavilionSchemaLabel}>Схема павильона</label>
              {
                this.props.schemaUrl &&
                  <img
                    className={styles.schemaImage}
                    src={urljoin(config.backendUrl, this.props.schemaUrl)}
                    alt='schema'
                  />
              }
              <Field
                name='schema'
                component={Dropzone}
              />
            </AntForm.Item>
        }
      </Form>
    )
  }
}

const selector = formValueSelector(ROOM_FORM)

export default connect(
  [
    {
      resource: 'roomTypes',
      method: 'get',
      request: {
        url: urljoin(config.backendUrl, 'roomTypes')
      }
    },
    {
      resource: 'roomTypesConfigs',
      method: 'get',
      request: {
        url: urljoin(config.backendUrl, 'roomTypesConfigs')
      }
    }
  ],
  (state: Store) => ({
    isPavilion: selector(state, 'isPavilion'),
    schemaUrl: selector(state, 'schemaUrl')
  })
)(reduxForm(
  {
    form: ROOM_FORM,
    enableReinitialize: true,
    // tslint:disable-next-line:no-any
    validate: validate as any
  }
)(RoomForm))
