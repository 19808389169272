exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__underline___1y9jz {\n  display: inline-block;\n  width: 50px;\n}\n", ""]);

// exports
exports.locals = {
	"underline": "styles__underline___1y9jz"
};