import React from 'react'
import connect from 'react-redux-fetch'
import * as R from 'ramda'
import urljoin from 'url-join'
import { message, Spin } from 'antd'
import moment from 'moment'
import config from 'common/config'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect'
import { Booking, Content, EVENT_STATUS, EVENT_RESERVATION_TYPE } from 'types/models'

import EventForm from '../_shared/form/Form'
import { EventFormInterface, BookingFormInterface, ContentFormInterface } from '../_shared/form/types/form'

import { ComponentPropsInterface } from './types'
import * as styles from './styles.styl'

class Edit extends React.Component<ComponentPropsInterface & InjectedAuthRouterProps> {
  private readonly formatBookings: (bookings: Booking[] | undefined, timezone: any | undefined) => BookingFormInterface[] =
    R.pipe(
      R.defaultTo([]),
      R.map(
        (booking: Booking, timezone) => ({
          id: booking.id,
          dates: [
            timezone ? moment(booking.dates[0].value).utcOffset(timezone) : moment(booking.dates[0].value),
            timezone ? moment(booking.dates[1].value).utcOffset(timezone) : moment(booking.dates[1].value)
          ],
          installingDates: [
            timezone ? moment(booking.installingDates[0].value).utcOffset(timezone) : moment(booking.installingDates[0].value),
            timezone ? moment(booking.installingDates[1].value).utcOffset(timezone) : moment(booking.installingDates[1].value)
          ],
          dismantlingDates: [
            timezone ? moment(booking.dismantlingDates[0].value).utcOffset(timezone) : moment(booking.dismantlingDates[0].value),
            timezone ? moment(booking.dismantlingDates[1].value).utcOffset(timezone) : moment(booking.dismantlingDates[1].value)
          ],
          squares: booking.squares,
          roomId: booking.roomId,
          usageFormats: booking.usageFormats ? booking.usageFormats : [],
          configs: booking.configs ? booking.configs : [],
          countPeople: booking.countPeople ? booking.countPeople : 0,
          datesAlt: (booking.datesAlt && booking.datesAlt.length == 2) ? [
            timezone ? moment(booking.datesAlt[0].value).utcOffset(timezone) : moment(booking.datesAlt[0].value),
            timezone ? moment(booking.datesAlt[1].value).utcOffset(timezone) : moment(booking.datesAlt[1].value)
          ] : null,
          installingDatesAlt: (booking.installingDatesAlt && booking.installingDatesAlt.length == 2) ? [
            timezone ? moment(booking.installingDatesAlt[0].value).utcOffset(timezone) : moment(booking.installingDatesAlt[0].value),
            timezone ? moment(booking.installingDatesAlt[1].value).utcOffset(timezone) : moment(booking.installingDatesAlt[1].value)
          ] : null,
          dismantlingDatesAlt: (booking.dismantlingDatesAlt && booking.dismantlingDatesAlt.length == 2) ? [
            timezone ? moment(booking.dismantlingDatesAlt[0].value).utcOffset(timezone) : moment(booking.dismantlingDatesAlt[0].value),
            timezone ? moment(booking.dismantlingDatesAlt[1].value).utcOffset(timezone) : moment(booking.dismantlingDatesAlt[1].value)
          ] : null
        })
      )
    )

  private readonly formatContents: (contents: Content[] | undefined, timezone: any | undefined) => ContentFormInterface[] =
    R.pipe(
      R.defaultTo([]),
      R.map(
        (content: Content, timezone) => ({
          id: content.id,
          contentId: content.id,
          usageDates: [
            timezone ? moment(content.usageDates[0].value).utcOffset(timezone) : moment(content.usageDates[0].value),
            timezone ? moment(content.usageDates[1].value).utcOffset(timezone) : moment(content.usageDates[1].value)
          ],
          file: {
            0:{name: content.file}
          },
          rightTypeId: content.rightTypeId && content.rightTypeId.toString(),
          fileMethodId: content.fileMethodId && content.fileMethodId.toString(),
        })
      )
    )

  public componentDidMount(): void {
    const { dispatchTimezoneGet, dispatchEventGet, match: { params } } = this.props
    dispatchEventGet(params.id)
    dispatchTimezoneGet()
  }
  // public componentDidMount(): void {
  //   const { dispatchEventGet, match: { params } } = this.props
  //   dispatchEventGet(params.id)
  // }

  public handleSubmit = (body: EventFormInterface) => {
    const { dispatchEventPut, match: { params } } = this.props
    dispatchEventPut(params.id, body)
  }

  public componentDidUpdate(prevProps: ComponentPropsInterface): void {
    if (prevProps.eventPutFetch.pending && this.props.eventPutFetch.fulfilled) {
      message.success('Мероприятие обновлено')
      this.props.history.push('/events')
    }
  }

  public render(): React.ReactNode {
    const { eventFetch, timezoneFetch, eventPutFetch } = this.props

    return (
      <>
        {
          eventFetch.pending && timezoneFetch.pending && (
            <div className={styles.spinWrapper}>
              <Spin />
            </div>
          )
        }
        {
          eventFetch.fulfilled && eventFetch.value && timezoneFetch.fulfilled && (
            <EventForm
			  createMode={false}
              reviewMode={false}
              initialValues={{
                id: eventFetch.value.id,
                authorId: eventFetch.value.authorId && eventFetch.value.authorId.toString(),
                name: eventFetch.value.name,
                typeId: eventFetch.value.typeId && eventFetch.value.typeId.toString(),
                mealFormatId: eventFetch.value.mealFormatId && eventFetch.value.mealFormatId.toString(),
                description: eventFetch.value.description,
                targetAudience: eventFetch.value.targetAudience,
                participantCount: eventFetch.value.participantCount,
                participantCountMeal: eventFetch.value.participantCountMeal,
                website: eventFetch.value.website,
                ageRating: eventFetch.value.ageRating,
                isSellingAlcohol: eventFetch.value.isSellingAlcohol.toString(),
                enableSquareCheck: eventFetch.value.enableSquareCheck.toString(),
                crmId: eventFetch.value.crmId,
                bookings: timezoneFetch.value ? this.formatBookings(eventFetch.value.bookings, timezoneFetch.value.value) : this.formatBookings(eventFetch.value.bookings, null),
                contents: timezoneFetch.value ? this.formatContents(eventFetch.value.contents, timezoneFetch.value.value) : this.formatContents(eventFetch.value.contents, null),
                status: EVENT_STATUS.PENDING,
                reservationType: EVENT_RESERVATION_TYPE.PRELIMINARY,
                // startDate: moment(eventFetch.value.startDate)
                // startDate: moment(eventFetch.value.startDate).utc()
                startDate: timezoneFetch.value ? moment(eventFetch.value.startDate).utcOffset(timezoneFetch.value.value) : moment(eventFetch.value.startDate)
              }}
              onSubmit={this.handleSubmit}
              submitBlocked={eventPutFetch.pending}
            />
          )
        }
      </>
    )
  }
}

export default connect(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource:  'event',
      method: 'get',
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'events', id)
      })
    },
    {
      resource: {
        action: 'event',
        name: 'eventPut'
      },
      method: 'put',
      request: (id: string, body: EventFormInterface) => ({
        url: urljoin(config.backendUrl, 'events', id),
        body
      })
    }
  ]
)(Edit)
