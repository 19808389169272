exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form__firstStepForm___3cSm_ {\n  max-width: 600px;\n  margin: 0 auto;\n}\n.form__rangePicker___20pS9 {\n  width: 450px !important;\n}\n.form__buttonsWrapper___Mar3f {\n  margin-top: 24px;\n  text-align: center;\n}\n.form__buttonsWrapper___Mar3f > * {\n  margin-right: 16px;\n}\n.form__reviewFormWrapper___3E5Od {\n  max-width: 600px;\n  margin: 0 auto;\n}\n.form__descriptionTextArea___-iTI- {\n  min-height: 100px !important;\n}\n", ""]);

// exports
exports.locals = {
	"firstStepForm": "form__firstStepForm___3cSm_",
	"rangePicker": "form__rangePicker___20pS9",
	"buttonsWrapper": "form__buttonsWrapper___Mar3f",
	"reviewFormWrapper": "form__reviewFormWrapper___3E5Od",
	"descriptionTextArea": "form__descriptionTextArea___-iTI-"
};