import React from 'react'
import { Room } from 'types/models'
import { Modal, Button } from 'antd'

import { BookingFormInterface } from '../../types/form'
import BookingFormMass, { EVENT_BOOKING_FORM_MASS, EventBookingFormMassInterface } from '../bookingFormMass/BookingFormMass'

interface Props {
    timezone: any
    rooms: Room[]
    visible: boolean
    onClose(): void
    onAdd(values: BookingFormInterface[]): void
}

class BookingModalMass extends React.Component<Props> {
    public handleBookingAddMass = (data: EventBookingFormMassInterface) => {
        let selectedRooms = data.rooms || [];
        let resultData : BookingFormInterface[] = [];

        selectedRooms.forEach(element => {
            resultData.push({
                id: data.id,
                roomId: parseInt(element),
                dismantlingDates: data.dismantlingDates,
                installingDates: data.installingDates,
                dates: data.dates,
                squares: data.squares,
                dismantlingDatesAlt: data.dismantlingDatesAlt,
                installingDatesAlt: data.installingDatesAlt,
                datesAlt: data.datesAlt,
            })
        });

        this.props.onAdd(resultData);
    }

    public render(): React.ReactNode {
        const { rooms, timezone, visible, onClose } = this.props

        return (
          <Modal
            title={<div>Добавить несколько помещений</div>}
            onCancel={onClose}
            visible={visible}
            maskClosable={false}
            footer={[
              <Button key='back' onClick={onClose}>
                Отмена
              </Button>,
              <Button key='submit' form={EVENT_BOOKING_FORM_MASS} type='primary' htmlType='submit'>
                Ок
              </Button>
            ]}
            width='500px'
          >
            <BookingFormMass
              onSuccess={this.handleBookingAddMass}
              rooms={rooms}
              timezone={timezone}
            />
          </Modal>
        )
    }
}

export default BookingModalMass