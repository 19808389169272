import React from 'react'
import Form, { FormComponentProps } from 'antd/lib/form'
// import { DatePicker, Input, Select } from 'antd'
import { DatePicker, Select } from 'antd'
import { Moment } from 'moment'
import { Room } from 'types/models'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'

import * as styles from './styles.styl'

const FIELD_REQUIRED_MESSAGE = 'Поле обязательно для заполнения'
const DATES_SHOULD_NOT_BE_EQUAL_MESSAGE = 'Даты не должны совпадать'

export const EVENT_BOOKING_FORM_MASS = 'BOOKING_FORM_MASS' 

export interface EventBookingFormMassInterface {
    id: number,
    rooms?: []
    dismantlingDates?: [Moment, Moment]
    installingDates?: [Moment, Moment]
    dates?: [Moment, Moment]
    squares?: string
    dismantlingDatesAlt?: [Moment, Moment]
    installingDatesAlt?: [Moment, Moment]
    datesAlt?: [Moment, Moment]
}
interface OwnPropsInterface {
    rooms: Room[],
    timezone: any,
    onSuccess(data: EventBookingFormMassInterface): void
}
export interface FormComponentInterface extends FormComponentProps<EventBookingFormMassInterface>, OwnPropsInterface {
}

// tslint:disable-next-line:no-any
function validator(rule: any, value: [Moment, Moment] | undefined, callback: (message?: string) => {}): void {
    if (!value) {
      callback(FIELD_REQUIRED_MESSAGE)

      return
    }

    if (value[0].diff(value[1]) === 0) {
      callback(DATES_SHOULD_NOT_BE_EQUAL_MESSAGE)

      return
    }

    callback()
}

class BookingFormMass extends React.Component<FormComponentInterface> {

    public handleSubmit = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        e.preventDefault()
        // tslint:disable-next-line: no-any
        this.props.form.validateFields((err: any, values: EventBookingFormMassInterface) => {
          if (!err) {
            this.props.onSuccess(values)
          }
        })
    }

    public getForm(): React.ReactNode {
        const { rooms, form: { getFieldDecorator } } = this.props

        return (
          <Form onSubmit={this.handleSubmit} id={EVENT_BOOKING_FORM_MASS}>
            <Form.Item label="Помещения" required>
                {
                getFieldDecorator('rooms', {
                    valuePropName: 'rooms',
                    rules: [
                    { required: true, message: 'Помещения не заданы' },
                    ],
                })(
                    <Select mode="multiple" placeholder="Задайте помещения">
                        {rooms.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
                    </Select>
                )
                }
            </Form.Item>
            <h3>Основные даты</h3>
            <Form.Item label='Даты монтажа' required>
            {
              getFieldDecorator('installingDates', {
                rules: [{ validator }]
              })(
                <DatePicker.RangePicker
                  format={HUMAN_DATETIME_FORMAT}
                  className={styles.rangePicker}
                  showTime
                />
              )
            }
            </Form.Item>
            <Form.Item label='Даты проведения' required>
              {
                getFieldDecorator('dates', {
                  rules: [{ validator }]
                })(
                  <DatePicker.RangePicker
                    format={HUMAN_DATETIME_FORMAT}
                    className={styles.rangePicker}
                    showTime
                  />
                )
              }
            </Form.Item>
            <Form.Item label='Даты демонтажа' required>
              {
                getFieldDecorator('dismantlingDates', {
                  rules: [{ validator }]
                })(
                  <DatePicker.RangePicker
                    format={HUMAN_DATETIME_FORMAT}
                    className={styles.rangePicker}
                    showTime
                  />
                )
              }
            </Form.Item>
            <h3>Альтернативные даты</h3>
            <Form.Item label='Даты монтажа'>
            {
              getFieldDecorator('installingDatesAlt', {
                rules: [{ required: false }]
              })(
                <DatePicker.RangePicker
                  format={HUMAN_DATETIME_FORMAT}
                  className={styles.rangePicker}
                  showTime
                />
              )
            }
            </Form.Item>
            <Form.Item label='Даты проведения'>
              {
                getFieldDecorator('datesAlt', {
                  rules: [{ required: false }]
                })(
                  <DatePicker.RangePicker
                    format={HUMAN_DATETIME_FORMAT}
                    className={styles.rangePicker}
                    showTime
                  />
                )
              }
            </Form.Item>
            <Form.Item label='Даты демонтажа'>
              {
                getFieldDecorator('dismantlingDatesAlt', {
                  rules: [{ required: false }]
                })(
                  <DatePicker.RangePicker
                    format={HUMAN_DATETIME_FORMAT}
                    className={styles.rangePicker}
                    showTime
                  />
                )
              }
            </Form.Item>
            {/* { 
              <Form.Item label='Квадраты'>
              {
                  getFieldDecorator('squares', {
                  rules: [{ required: false }]
                  })(
                  <Input />
                  )
              }
              </Form.Item>
            } */}
          </Form>
        )
    }

    public render(): React.ReactNode {
        return (
            this.getForm()
        )
    }
}

export default Form.create<FormComponentInterface>({ name: EVENT_BOOKING_FORM_MASS })(BookingFormMass)
