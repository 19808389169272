import React from 'react'
import connect from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import { User } from 'types/models'
import { USER_ROLE_TO_STRING } from 'constants/User'
import config from 'common/config'

interface Props {
  usersFetch: {
    value: User[];
  }

  dispatchUsersGet(): void
}

const columns: ColumnProps<User>[] = [
  {
    key: 'id',
    title: 'id',
    dataIndex: 'id',
    width: 100
  },
  {
    key: 'name',
    title: 'Имя',
    dataIndex: 'name',
    render: (text: string, record: User) => <Link to={`/admin/users/${record.id}`}>{text}</Link>
  },
  {
    key: 'role',
    title: 'Роль',
    dataIndex: 'role',
    render: (role: string): string => USER_ROLE_TO_STRING[role]
  }
]

class UserTable extends Table<User> {}

class Users extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchUsersGet()
  }

  public render(): React.ReactNode {
    return (
      <div>
        <Link to='/admin/users/create'>
          <Button type='primary'>
            Новый пользователь
          </Button>
        </Link>
        <UserTable
          columns={columns}
          rowKey='id'
          dataSource={this.props.usersFetch.value}
        />
      </div>
    )
  }
}

export default connect([{
  resource: 'users',
  method: 'get',
  request: {
    url: urljoin(config.backendUrl, 'users')
  }
}])(Users)
