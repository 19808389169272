import React from 'react'
import { Dispatch } from 'redux'
import { Button, Layout, Menu, Row, Col } from 'antd'
import { Switch, Route, Redirect, useLocation } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import 'moment/locale/ru'
import { StoreInterface } from 'store'
import { AbacProvider } from 'react-abac'
import { USER_ROLE_TO_STRING } from 'constants/User'
import Admin from 'components/pages/Admin/Admin'
import Events from 'components/pages/Events/Events'
import Login from 'components/pages/Login/Login'
import rules from 'permissions/rules'
import { User } from 'types/models'
import actions from 'actions/User'

import { userIsNotAuthenticatedRedir, userIsAdminRedir, userIsAuthenticatedRedir } from '../auth'

import * as styles from './app.styl'
import Calendar from './pages/Calendar/Calendar'

const AdminComponent = userIsAuthenticatedRedir(userIsAdminRedir(Admin))
const CalendarComponent = userIsAuthenticatedRedir(Calendar)
const LoginComponent = userIsNotAuthenticatedRedir(Login)

const { Header, Content } = Layout

interface Props {
  user?: User
  logout(): void
}

const App: React.FC<Props> = (props: Props) => {
  const userRoles = props.user && [props.user.role]

  return (
    <AbacProvider
      user={props.user}
      roles={userRoles}
      rules={rules}
      permissions={[]}
    >
      <Layout>
        <Header>
          <Row>
            <Col span={8}>
              {
                props.user &&
                  <Menu
                    mode='horizontal'
                    theme='dark'
                    style={{ lineHeight: '64px' }}
                    selectedKeys={[useLocation().pathname.split('/')[1]]}
                  >
                    <Menu.Item key='events'>
                      <Link to='/events'>
                        Мероприятия
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='calendar'>
                      <Link to='/calendar'>
                        Календарь
                      </Link>
                    </Menu.Item>
                  </Menu>
              }
            </Col>
            <Col span={8} className={styles.headerCenterSection}>
              Календарь мероприятий
            </Col>
            <Col span={8} className={styles.headerRightSection}>
              {
                props.user && <>
                  <span>{ props.user && props.user.name }</span>
                  <span>{ props.user && USER_ROLE_TO_STRING[props.user.role] }</span>
                  <Button onClick={props.logout}>Выйти</Button>
                </>
              }
            </Col>
          </Row>
        </Header>
        <Content className={styles.content}>
          <Switch>
            <Route path='/events' component={Events} />
            <Route path='/admin' component={AdminComponent} />
            <Route path='/login' component={LoginComponent} />
            <Route path='/calendar' component={CalendarComponent} />
            <Redirect exact path='/' to='/events' />
          </Switch>
        </Content>
      </Layout>
    </AbacProvider>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => { dispatch(actions.logout()) }
  }
}

const mapStateToProps = (store: StoreInterface) => ({
  user: store.user.data
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
