import * as R from 'ramda'
import { Row, Spin } from 'antd'
import connect, { PromiseState } from 'react-redux-fetch'
import { ROOM_TYPE_FORM } from 'constants/RoomType'
import { autobind } from 'core-decorators'
import React from 'react'
// import { connect } from 'react-redux'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import { TextField, SelectField } from 'redux-form-antd'
import { RoomTypesConfig } from 'types/models'
import urljoin from 'url-join'
import config from 'common/config'

import { validate } from './helpers'

interface Props extends InjectedFormProps {
  onSubmit(values: RoomTypeFormInterface): void
  roomTypesConfigsFetch: PromiseState<RoomTypesConfig[]>
  dispatchRoomTypesConfigsGet(): void
}

export interface RoomTypeFormInterface {
  name: string
  description?: string
  roomTypesConfigId?: number
}

class RoomTypeForm extends React.Component<Props> {
  @autobind
  public handleSubmit(values: RoomTypeFormInterface): void {
    this.props.onSubmit(values)
  }

  public componentDidMount(): void {
    this.props.dispatchRoomTypesConfigsGet()
  }

  public render(): React.ReactNode {
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <Row>
          <label>Название</label>
          <Field
            name='name'
            component={TextField}
            placeholder='Тип помещения'
          />
        </Row>
        <Row>
          <label>Описание</label>
          <Field
            name='description'
            component={TextField}
            placeholder='Описание'
          />
        </Row>
        <Row>
            <label>Конфигурация</label>
            <Spin spinning={this.props.roomTypesConfigsFetch.pending}>
              <Field
                name='roomTypesConfigId'
                component={SelectField}
                options={
                  R.map(
                    (roomTypesConfig: RoomTypesConfig) => ({ label: roomTypesConfig.name, value: roomTypesConfig.id }),
                    this.props.roomTypesConfigsFetch.value || []
                  )
                }
                placeholder='Не задано'
                hasFeedback={false}
                allowClear
              >
                <option />
              </Field>
            </Spin>
        </Row>
      </Form>
    )
  }
}

export default connect(
  [
    {
      resource: 'roomTypesConfigs',
      method: 'get',
      request: {
        url: urljoin(config.backendUrl, 'roomTypesConfigs')
      }
    }
  ]
)(reduxForm(
  {
    form: ROOM_TYPE_FORM,
    enableReinitialize: true,
    // tslint:disable-next-line:no-any
    validate: validate as any
  }
)(RoomTypeForm))
