import { Button, message, PageHeader } from 'antd'
import config from 'common/config'
import { EVENT_TYPE_FORM } from 'constants/EventType'
import { autobind } from 'core-decorators'
import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { Action } from 'redux'
import { submit } from 'redux-form'
import { EventType } from 'types/models'
import urljoin from 'url-join'

import Form, { EventTypeFormInterface } from '../_shared/form/Form'

interface Props extends RouteComponentProps {
  eventTypePostFetch: PromiseState<EventType>

  dispatchEventTypePost(data: EventTypeFormInterface): void

  dispatch(value: Action): void
}

class Create extends React.Component<Props> {
  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(EVENT_TYPE_FORM))
  }

  @autobind
  public handeSubmit(values: EventTypeFormInterface): void {
    this.props.dispatchEventTypePost(values)
  }

  public componentDidUpdate(prevProps: Props): void {
    const fetch = this.props.eventTypePostFetch
    if (prevProps.eventTypePostFetch.pending && fetch.fulfilled && fetch.value) {
      message.success('Тип мероприятия создан')
      this.props.history.push(`/admin/eventTypes/${fetch.value.id}`)
    }
  }

  public render(): React.ReactNode {
    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Тип мероприятия'
          subTitle='Создание'
          extra={[
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Создать
            </Button>
          ]}
        />
        <Form
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'eventType',
        name: 'eventTypePost'
      },
      method: 'post',
      request: (body: EventTypeFormInterface) => ({
        url: urljoin(config.backendUrl, 'eventTypes'),
        body
      })
    }
  ]
)(Create)
