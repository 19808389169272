import React from 'react'
import connect from 'react-redux-fetch'
import { Store } from 'redux'
import moment from 'moment'
import { Form, reduxForm, Field, getFormValues } from 'redux-form'
import { Row, Button, Col } from 'antd'
import { EVENT_FORM } from 'constants/Event'
import RangePicker from 'components/shared/form/rangePicker/RangePicker'
import { HUMAN_DATE_FORMAT } from 'constants/DateTime'
import { EventFormInterface } from '../types/form'

import Calendar from '../calendar/Calendar'
import * as styles from '../form.styl'

import { ComponentPropsInterface, ComponentStateInterface, StatePropsInterface, ComponentPropsType, MapStateToPropsType } from './types'

class SecondStep extends React.Component<ComponentPropsType> {
  public state: ComponentStateInterface = {
    dates: [moment(this.props.values.startDate).startOf('month'), moment(this.props.values.startDate).endOf('month')]
  }

  public handleRangeChange = (dates: [moment.Moment, moment.Moment]) => {
    this.setState({ dates })
  }

  public render(): React.ReactNode {
    const { handleSubmit, onBack, timezone } = this.props
    const { dates } = this.state

    let enableSquareCheck;

    if (typeof this.props.values.enableSquareCheck !== 'undefined') {
      enableSquareCheck = this.props.values.enableSquareCheck
    } else {
      enableSquareCheck = true; // true by default
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <label>Выбор дат</label>
          <RangePicker
            format={HUMAN_DATE_FORMAT}
            // defaultValue={dates}
            defaultValue={[
              timezone ? moment(this.props.values.startDate).utcOffset(timezone).startOf('month') : moment(this.props.values.startDate).startOf('month'),
              timezone ? moment(this.props.values.startDate).utcOffset(timezone).endOf('month') : moment(this.props.values.startDate).endOf('month'),
            ]}
            onChange={this.handleRangeChange}
            allowClear={false}
            ranges={{
              'Этот месяц': [
                timezone ? moment().utcOffset(timezone).startOf('month') : moment().startOf('month'),
                timezone ? moment().utcOffset(timezone).endOf('month') : moment().endOf('month')
              ],
              'Следующий месяц': [
                timezone ? moment().utcOffset(timezone).add(1, 'month').startOf('month') : moment().add(1, 'month').startOf('month'),
                timezone ? moment().utcOffset(timezone).add(1, 'month').endOf('month') : moment().add(1, 'month').endOf('month')
              ]
            }}
          />
          <Field
            name='bookings'
            component={Calendar}
            dates={dates}
            enableSquareCheck={enableSquareCheck}
          />
        </Row>
        <Row>
          <Col className={styles.buttonsWrapper}>
            <Button
              type='ghost'
              onClick={onBack}
            >
              Назад
            </Button>
            <Button
              type='primary'
              htmlType='submit'
            >
              Далее
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

const valuesGetter = getFormValues(EVENT_FORM) as (state: Store) => EventFormInterface

const mapStateToProps: MapStateToPropsType = (state: Store) => ({
  values: valuesGetter(state)
})

export default reduxForm<{}, Partial<ComponentPropsInterface>>({
  form: EVENT_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(connect<StatePropsInterface, {}, ComponentPropsInterface>(
  [],
  mapStateToProps
)(SecondStep))
