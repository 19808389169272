import React from 'react'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history3/redirect'
import { default as CalendarComponent } from 'components/shared/calendar/Calendar'
import moment from 'moment'
import RangePicker from 'components/shared/form/rangePicker/RangePicker'
import { Row, Col } from 'antd'

import ReportDownloader from './reportDownloader/ReportDownloader'
import * as styles from './calendar.styl'

interface State {
  dates: [moment.Moment, moment.Moment]
}

type Props = InjectedAuthRouterProps

class Calendar extends React.Component<Props, State> {
  public state: State = {
    dates: [
      moment().startOf('month'),
      moment().endOf('month')
    ]
  }

  public handleRangeChange = (dates: [moment.Moment, moment.Moment]) => {
    this.setState({ dates })
  }

  public render(): React.ReactNode {
    const [startDate, endDate] = this.state.dates

    return (
      <div>
        <label>Выбор дат</label>
        <Row gutter={16}>
          <Col span={5}>
            <RangePicker
              defaultValue={[moment().startOf('month'), moment().endOf('month')]}
              onChange={this.handleRangeChange}
              allowClear={false}
              ranges={{
                'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
                'Следующий месяц': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
                'Текущий год': [moment().startOf('year'), moment().endOf('year')],
                'Предыдущий год': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
              }}
            />
          </Col>
          <Col
            span={19}
            className={styles.exportButtonWrapper}
          >
            <ReportDownloader
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
        </Row>
        <CalendarComponent
          dates={this.state.dates}
        />
      </div>
    )
  }
}

export default Calendar
