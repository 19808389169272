import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import urljoin from 'url-join'
import { PageHeader, Button, Popconfirm, Spin, message } from 'antd'
import { autobind } from 'core-decorators'
import { User } from 'types/models'
import { submit, FormAction } from 'redux-form'
import { USER_FORM } from 'constants/User'
import config from 'common/config'

import Form from '../_shared/form/Form'
import { UserFormInterface } from '../_shared/form/types'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  userGetFetch: PromiseState<User>
  userPutFetch: PromiseState<User>
  userDeleteFetch: PromiseState

  dispatchUserGet(id: string): void
  dispatchUserPut(id: string, data: UserFormInterface): void
  dispatchUserDelete(id: string): void

  dispatch(value: FormAction): void
}

class Edit extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchUserGet(this.props.match.params.id)
  }

  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(USER_FORM))
  }

  @autobind
  public handleDelete(): void {
    if (this.props.userGetFetch.value) {
      this.props.dispatchUserDelete(String(this.props.userGetFetch.value.id))
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.userDeleteFetch.pending && this.props.userDeleteFetch.fulfilled) {
      message.success('Пользователь удален')
      this.props.history.push(`/admin/users`)
    }

    if (prevProps.userPutFetch.pending && this.props.userPutFetch.fulfilled) {
      message.success('Пользователь сохранен')
      this.props.dispatchUserGet(this.props.match.params.id)
    }
  }

  @autobind
  public handeSubmit(values: UserFormInterface): void {
    this.props.dispatchUserPut(
      this.props.match.params.id,
      values
    )
  }

  public render(): React.ReactNode {
    if (!this.props.userGetFetch.value) {
      return <Spin/>
    }

    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Пользователь'
          subTitle='Редактирование'
          extra={[
            <Popconfirm
              key='delete'
              title='Действительно удалить данного пользователя?'
              onConfirm={this.handleDelete}
              okText='Удалить'
              cancelText='Отмена'
            >
              <Button
                type='danger'
              >
                Удалить
              </Button>
            </Popconfirm>,
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Сохранить
            </Button>
          ]}
          />
        <Form
          initialValues={this.props.userGetFetch.value}
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'user',
        name: 'userGet'
      },
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'users', id)
      })
    },
    {
      resource: {
        action: 'user',
        name: 'userPut'
      },
      method: 'put',
      request: (id: string, body: UserFormInterface) => ({
        url: urljoin(config.backendUrl, 'users', id),
        body
      })
    },
    {
      resource: {
        action: 'user',
        name: 'userDelete'
      },
      method: 'delete',
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'users', id)
      })
    }
  ]
)(Edit)
