import { OptionFormInterface } from './types'

type OptionFormValidation = {
    [F in keyof OptionFormInterface]?: string
}

const REQUIRED_FIELD = 'Поле обязательно для заполнения'
const NO_SPACES_ALLOWED = 'Пробелы в этом поле запрещены'

export function validate(values: OptionFormInterface): OptionFormValidation {
    const errors: OptionFormValidation = {}

    if (!values.name) {
        errors.name = REQUIRED_FIELD
    }
    if (!values.slug) {
        errors.slug = REQUIRED_FIELD
    }
    if (!values.value) {
        errors.value = REQUIRED_FIELD
    }

    if (values.slug && values.slug.indexOf(' ') !== -1) {
        errors.slug = NO_SPACES_ALLOWED
    }

    return errors
}
