import React from 'react'
import classnames from 'classnames'
import { RESERVATION_TYPE_TO_STRING, STATUS_TO_STRING } from 'constants/Event'
import moment from 'moment'
import { Booking as BookingModel } from 'types/models'
import { formatDateRange, checkDateRange } from 'utils/date.utils'

import { BookingFormInterface } from '../../../../types/form'
import { PopoverDateRanges } from '../types'

import * as styles from './booking.styl'

interface Props {
  booking: BookingModel | BookingFormInterface
  dateRanges: PopoverDateRanges
  isConflicting?: boolean
}

function isCurrentBookingGuard(booking: BookingModel | BookingFormInterface): booking is BookingFormInterface {
  return Array.isArray(booking.dates) && moment.isMoment(booking.dates[0])
}

class Booking extends React.Component<Props> {
  public render(): React.ReactNode {
    const { booking, dateRanges, isConflicting } = this.props

    return (
      <div className={styles.root}>
        {
          isCurrentBookingGuard(booking) ?
            <div className={styles.label}>Ваше мероприятие</div> :
            <>
              {
                isConflicting &&
                <div className={styles.label}>Конфликтующее мероприятие</div>
              }
              <div>
                <span className={styles.label}>Мероприятие:</span>{' '}
                {
                  booking.event && booking.event.id 
                   ? <strong><a target='blank' href={'/events/' + booking.event.id}>{booking.event.name}</a></strong>
                   : <strong>{booking.event && booking.event.name}</strong>
                }
              </div>
              <div>
                <span className={styles.label}>Менеджер:</span>{' '}
                {booking.event && booking.event.author && booking.event.author.name}
              </div>
              <div>
                <span className={styles.label}>Статус брони:</span>{' '}
                {booking.event && booking.event.reservationType ?
                  RESERVATION_TYPE_TO_STRING[booking.event.reservationType] :
                  'Не указано'}
              </div>
              <div>
                <span className={styles.label}>Статус согласования:</span>{' '}
                {booking.event && STATUS_TO_STRING[booking.event.status]}
              </div>
            </>
        }
        <div className={classnames({ [styles.highlighted]: dateRanges.installingDates.isConflicting })}>
          <span className={styles.label}>Даты монтажа:</span><br />{' '}
          {
            dateRanges.installingDatesAlt && dateRanges.installingDatesAlt.range && checkDateRange(dateRanges.installingDatesAlt.range) ? 
              <div><span><u>Осн.:</u>{' '}{formatDateRange(dateRanges.installingDates.range)}</span><br /><span><u>Альт.:</u>{' '}{formatDateRange(dateRanges.installingDatesAlt.range)}</span></div> : formatDateRange(dateRanges.installingDates.range)
          }
        </div>
        <div className={classnames({ [styles.highlighted]: dateRanges.dates.isConflicting })}>
          <span className={styles.label}>Даты проведения мероприятия:</span><br />{' '}
          {
            dateRanges.datesAlt && dateRanges.datesAlt.range && checkDateRange(dateRanges.datesAlt.range) ? 
              <div><span><u>Осн.:</u>{' '}{formatDateRange(dateRanges.dates.range)}</span><br /><span><u>Альт.:</u>{' '}{formatDateRange(dateRanges.datesAlt.range)}</span></div> : formatDateRange(dateRanges.dates.range)
          }
        </div>
        <div className={classnames({ [styles.highlighted]: dateRanges.dismantlingDates.isConflicting })}>
          <span className={styles.label}>Даты демонтажа:</span><br />{' '}
          {
            dateRanges.dismantlingDatesAlt && dateRanges.dismantlingDatesAlt.range && checkDateRange(dateRanges.dismantlingDatesAlt.range) ? 
              <div><span><u>Осн.:</u>{' '}{formatDateRange(dateRanges.dismantlingDates.range)}</span><br /><span><u>Альт.:</u>{' '}{formatDateRange(dateRanges.dismantlingDatesAlt.range)}</span></div> : formatDateRange(dateRanges.dismantlingDates.range)
          }
        </div>

      </div>
    )
  }
}

export default Booking
