import * as R from 'ramda'
import { Room } from 'types/models'

import { RoomFormInterface } from './Form'

type RoomFormValidation = {
  [F in keyof RoomFormInterface]?: string
}

export function formatFromForm(values: RoomFormInterface): FormData {
  const form = new FormData()
  for (const key of Object.keys(R.omit(['schema'], values))) {
    if (values[key] !== undefined) {
      form.append(key, values[key])
    }
  }

  if (values.schema) {
    form.append('schema', values.schema)
  }

  return form
}

export function formatToForm(values: Room): RoomFormInterface {
  return {
    ...R.pick(['name', 'squares', 'schemaUrl'], values),
    isPavilion: values.isPavilion ? 'true' : 'false',
    typeId: values.typeId && values.typeId.toString(),
    configs: values.configs ? values.configs.toString().split(',') : []
  }
}

export function validate(values: RoomFormInterface): RoomFormValidation {
  const errors: RoomFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  if (!values.isPavilion) {
    errors.isPavilion = 'Поле обязательно для заполнения'
  }

  if (values.isPavilion && values.isPavilion == 'true' && !values.squares){
    errors.squares = 'Поле обязательно для заполнения'
  }

  if (values.isPavilion && values.isPavilion == 'true' && values.squares)
  {
    if(/^\d+$/.test(values.squares.toString())){
    } else {
      errors.squares = 'Это поле должно содержать только цифры'
    }
  }

  return errors
}
