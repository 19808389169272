import React from 'react'
import { Modal, Button } from 'antd'

import { ContentFormInterface } from '../../types/form'
import ContentForm, { EVENT_CONTENT_FORM, EventContentFormInterface } from '../contentForm/ContentForm'

import { modifyDateRangeAccordingToLocalTimezone } from 'utils/date.utils'

interface Props {
  currentValues?: ContentFormInterface
  visible: boolean,
  rightTypes: any,
  fileMethods: any,
  timezone: any,
  onClose(): void
  onAdd(values: ContentFormInterface): void
  onEdit(valuesOld: ContentFormInterface, valuesNew: ContentFormInterface): void
}

class ContentModal extends React.Component<Props> {

  public handleContentAdd = (data: EventContentFormInterface) => {

    if (this.props.timezone) {
      data.usageDates = modifyDateRangeAccordingToLocalTimezone(data.usageDates, this.props.timezone);
    }

    this.props.onAdd({...data})
  }

  public handleContentEdit = (dataOld: EventContentFormInterface, dataNew: EventContentFormInterface) => {
    this.props.onEdit({ ...dataOld }, { ...dataNew })
  }

  public render(): React.ReactNode {
    const { visible, rightTypes, fileMethods, timezone, currentValues, onClose } = this.props

    const width: string = '500px'

    return (
      <Modal
        title={<div>Добавление контента 3-их лиц</div>}
        onCancel={onClose}
        visible={visible}
        maskClosable={false}
        footer={[
          <Button key='back' onClick={onClose}>
            Отмена
          </Button>,
          <Button key='submit' form={EVENT_CONTENT_FORM} type='primary' htmlType='submit'>
            Ок
          </Button>
        ]}
        width={width}
      >
        <ContentForm
          rightTypes={rightTypes}
          fileMethods={fileMethods}
          timezone={timezone}
          currentValues={currentValues}
          onSuccess={this.handleContentAdd}
          onEditSuccess={this.handleContentEdit}
        />
      </Modal>
    )

  }
}

export default ContentModal