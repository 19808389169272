import { EventType } from 'types/models'

import { EventTypeFormInterface } from './Form'

type EventTypeFormValidation = {
  [F in keyof EventTypeFormInterface]?: string
}

export function validate(values: EventTypeFormInterface): EventTypeFormValidation {
  const errors: EventTypeFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  return errors
}

export function formatToForm(values: EventType): EventTypeFormInterface {
  return {
    name: values.name,
    isSendableToCrm: values.isSendableToCrm ? 'true' : 'false'
  }
}
