import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { autobind } from 'core-decorators'
import urljoin from 'url-join'
import { IOption } from 'types/models'
import { TIMEZONE_FORM } from 'constants/Timezone'
import { submit, FormAction } from 'redux-form'
import { PageHeader, Button, message } from 'antd'
import config from 'common/config'

import Form from './Form'
import { TimezoneFormInterface } from './types'
  
interface Props extends RouteComponentProps {
    timezoneGetFetch: PromiseState<IOption>
    timezonePutFetch: PromiseState<IOption>

    dispatchTimezoneGet(): void
    dispatchTimezonePut(data: TimezoneFormInterface): void

    dispatch(value: FormAction): void
}

class Index extends React.Component<Props> {
    public componentDidMount(): void {
        this.props.dispatchTimezoneGet()
    }

    @autobind
    public handleSubmitClick(): void {
        this.props.dispatch(submit(TIMEZONE_FORM))
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.timezonePutFetch.pending && this.props.timezonePutFetch.fulfilled) {
            message.success('Часовой пояс сохранен')
            this.props.dispatchTimezoneGet()
        }
    }

    @autobind
    public handeSubmit(values: TimezoneFormInterface): void {
        this.props.dispatchTimezonePut(values)
    }

    public render(): React.ReactNode {
        // if (!this.props.timezoneGetFetch.value) {
        // if (this.props.timezoneGetFetch.value) {
        //     return <Spin/>
        // }

        return (
        <div>
            <PageHeader
                title='Часовой пояс'
                subTitle='Изменение'
                extra={[
                    <Button
                      key='save'
                      type='primary'
                      onClick={this.handleSubmitClick}
                    >
                      Сохранить
                    </Button>
                  ]}
            />
            <Form
                initialValues={this.props.timezoneGetFetch.value}
                onSubmit={this.handeSubmit}
            />
        </div>
        )
    }
}

export default connect([
    {
        resource: {
          action: 'timezone',
          name: 'timezoneGet'
        },
        request: () => ({
          url: urljoin(config.backendUrl, 'timezone')
        })
      },
      {
        resource: {
          action: 'timezone',
          name: 'timezonePut'
        },
        method: 'put',
        request: (body: TimezoneFormInterface) => ({
          url: urljoin(config.backendUrl, 'timezone'),
          body
        })
      },
])(Index)