import React from 'react'
import { RcFile } from 'antd/lib/upload'
import Form, { FormComponentProps } from 'antd/lib/form'
import { DatePicker } from 'antd'
import { Upload, Button, Icon, Select, Input } from 'antd'
import { Moment } from 'moment'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'
import urljoin from 'url-join'
import config from 'common/config'

import * as styles from './styles.styl'

const FIELD_REQUIRED_MESSAGE = 'Поле обязательно для заполнения'
const DATES_SHOULD_NOT_BE_EQUAL_MESSAGE = 'Даты не должны совпадать'

export const EVENT_CONTENT_FORM = 'CONTENT_FORM'

export interface EventContentFormInterface {
  rightTypeId: any,
  fileMethodId: any,
  contentId?: string
  usageDates: [Moment, Moment]
  file?: RcFile | Object
  fileBinary?: string | ArrayBuffer | null | any
}
interface OwnPropsInterface {
  currentValues?: EventContentFormInterface
  rightTypes: any,
  fileMethods: any,
  timezone: any,
  onSuccess(data: EventContentFormInterface): void
  onEditSuccess(dataOld: EventContentFormInterface, dataNew: EventContentFormInterface): void
}
export interface FormComponentInterface extends FormComponentProps<EventContentFormInterface>, OwnPropsInterface {
}

// tslint:disable-next-line:no-any
function validator(rule: any, value: [Moment, Moment] | undefined, callback: (message?: string) => {}): void {
  if (!value) {
    callback(FIELD_REQUIRED_MESSAGE)
    return
  }

  if (value[0].diff(value[1]) === 0) {
    callback(DATES_SHOULD_NOT_BE_EQUAL_MESSAGE)
    return
  }

  callback()
}

class ContentForm extends React.Component<FormComponentInterface> {

  public normFile(e) {
    if (Array.isArray(e)){
      return e;
    }
    if (e.fileList.length > 1){
      e.fileList.shift();
    }

    return e && e.fileList;
  }

  /*
  public readFile(file) {
    var reader = new FileReader();
  
    reader.onloadend = (function (file) {
      return function(){
        return this.result;
      }
      
    })(file);
  
    reader.onerror = function(e) {
      return function(){
        return reader.error;
      }
    };

    reader.readAsBinaryString(file);
  }*/

  dummyRequest({ file, onSuccess }){
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  /*
  public dummyRequest(this, { file, onSuccess }){
    return new Promise(function(resolve, reject) {
      let reader = new FileReader();
      let fileObject = new File([file], file.name, {
        type: file.type,
      });

      reader.onloadend = function() {

        // this.setState({
        //   binaryData: reader.result,
        // });

        resolve(onSuccess("ok"));
      }

      reader.onerror = function () {
        reject(new Error('Upload error'));
      }

      reader.readAsBinaryString(fileObject);
    });
  }
  */

  /*
  public formatFromForm(values: EventContentFormInterface): FormData {
    const form = new FormData()
    for (const key of Object.keys(R.omit(['file'], values))) {
      if (values[key] !== undefined) {
        form.append(key, values[key])
      }
    }
  
    if (values.file) {
      form.append('schema', values.file)
    }
  
    return form
  }
  */

  public async readFileData(file) {
    let resultData = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onloadend = (e) => resolve(fileReader.result);
        fileReader.readAsBinaryString(file);
    });

    return resultData;
  }

  public handleSubmit = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()

    // tslint:disable-next-line: no-any
    this.props.form.validateFields((err: any, values: EventContentFormInterface) => {

      if (!values.contentId){
        values.contentId = 'temp_'+Date.now();
      }

      if (!err){

        if (this.props.currentValues){
          values.fileMethodId = values.fileMethodId.join(',');
          values.rightTypeId = values.rightTypeId.join(',');
        }

        if (this.props.currentValues && !values.file){
          values.file = this.props.currentValues.file;
          this.props.onEditSuccess(this.props.currentValues, values);
          this.props.form.resetFields();
        }

        if (values.file && values.file[0] && values.file[0].originFileObj){

          let originalFileObject = values.file[0].originFileObj;
          let file = new File([originalFileObject], originalFileObject.name, {
            type: values.file[0].type,
          });

          this.readFileData(file).then(resultData => {
              values.fileBinary = resultData;

              if (this.props.currentValues){
                this.props.onEditSuccess(this.props.currentValues, values);
              } else {
                this.props.onSuccess(values);
              }
              
              this.props.form.resetFields();
          });
          // let reader = new FileReader();

          // reader.onloadend = function () {
          //   console.log('reader.result', reader.result);
          //   values.fileBinary = reader.result;
          //   ready = true;
          // }

          // reader.readAsBinaryString(file);
        }

        /*
        let formData = new FormData();

        for (const key of Object.keys(R.omit(['file'], values))) {
          if (values[key] !== undefined) {
            formData.append(key, values[key])
          }
        }

        if (values.file) {
          let file = new File([values.file[0]], values.file[0].name);
          formData.append("file", file, values.file[0].name);
        }

        values.formData = formData;
        */

        // var check = function() {
        //   if (ready === true) {
        //       this.props.onSuccess(values)
        //       this.props.form.resetFields()
        //       return;
        //   } else {
        //     console.log('...loading');
        //   }
        //   setTimeout(check, 500);
        // }

        // do {
        //   setTimeout(() => {  
        //     console.log('not finished');
        //     this.props.onSuccess(values)
        //     this.props.form.resetFields()
        //   }, 500);
        // } while (ready != true);

        // check();

        // setTimeout(() => {
        //   this.props.onSuccess(values)
        //   this.props.form.resetFields()
        // }, 2000);
        
      }
    })
  }

  public render(): React.ReactNode {
    const { currentValues, form: { getFieldDecorator } } = this.props

    let defRightTypeId: any = [],
        defFileMethodId: any = [],
        defUsageDates : Moment[] = [],
        currentFile,
        nameArr = [],
        uploadFileRequired = true;

    if (currentValues)
    {
      if (currentValues.rightTypeId){
        this.props.rightTypes.forEach((element) => {
          if (element.id == currentValues.rightTypeId){
            defRightTypeId.push(element.id);
          }
        });
      }
      if (currentValues.fileMethodId){

        let arrValues;

        if (!Array.isArray(currentValues.fileMethodId)){
          arrValues = currentValues.fileMethodId.split(',');
        } else {
          arrValues = currentValues.fileMethodId;
        }

        this.props.fileMethods.forEach((element) => {
          if (arrValues.includes(element.id.toString()) || arrValues.includes(element.id)){
            defFileMethodId.push(element.id);
          }
        });
        
      }
      if (currentValues.usageDates) {

        if (this.props.timezone) {
          currentValues.usageDates[0] = currentValues.usageDates[0].utcOffset(this.props.timezone);
          currentValues.usageDates[1] = currentValues.usageDates[1].utcOffset(this.props.timezone);
        }

        defUsageDates = currentValues.usageDates;
      }
      if (currentValues.file && currentValues.file[0].name){

        let countUpload = (currentValues.file[0].name.match(/uploads/g) || []).length;

        if (countUpload > 0){
          currentFile = currentValues.file[0].name;
          nameArr = currentFile.split('/');
          uploadFileRequired = false;
        }
      }
    }

    return (
      <Form onSubmit={this.handleSubmit} id={EVENT_CONTENT_FORM}>
        {<Form.Item label="Пределы прав" required>
          {
            getFieldDecorator('rightTypeId', {
              initialValue: defRightTypeId,
              // valuePropName: 'rightTypeId',
              rules: [
                { required: true, message: 'Пределы прав не заданы' },
              ],
            })(
              <Select placeholder="Задайте пределы прав для контента">
                {this.props.rightTypes.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
              </Select>
            )
          }
        </Form.Item>}
        {<Form.Item label="Способы" required>
          {
            getFieldDecorator('fileMethodId', {
              initialValue: defFileMethodId,
              // valuePropName: 'fileMethodId',
              rules: [
                { required: true, message: 'Способы не заданы' },
              ],
            })(
              <Select mode="multiple" placeholder="Задайте способы для контента">
                {this.props.fileMethods.map(option => <Select.Option key={option.id} value={option.id}>{`${option.name}`}</Select.Option>)}
              </Select>
            )
          }
        </Form.Item>}
        <Form.Item label='Сроки использования' required>
          {
            getFieldDecorator('usageDates', {
              initialValue: defUsageDates,
              rules: [{ validator }]
            })(
              <DatePicker.RangePicker
                format={HUMAN_DATETIME_FORMAT}
                className={styles.rangePicker}
                showTime
              />
            )
          }
        </Form.Item>
        <Form.Item label="Файл" required>
          {
            getFieldDecorator('file', {
              rules: [{ required: uploadFileRequired, message: 'Файл не выбран' }],
              valuePropName: 'fileList',
              getValueFromEvent: this.normFile
            })(
              <Upload name="file" customRequest={this.dummyRequest}>
                <Button>
                  <Icon type="upload" />Загрузить файл
                </Button>
              </Upload>
            )
          }
        </Form.Item>
        {
          currentFile && 
          <>
            <a target="blank" href={urljoin(config.backendUrl, currentFile)}>{nameArr[nameArr.length - 1]}</a>
          </>
        }
        {
          currentValues && currentValues.contentId && 
            <Form.Item>
              {
                getFieldDecorator('contentId', {
                  initialValue: currentValues.contentId,
                })(
                  <Input type="hidden" />
                )
              }
            </Form.Item>
        }
      </Form>
    )
  }
}

export default Form.create<FormComponentInterface>({ name: EVENT_CONTENT_FORM })(ContentForm)