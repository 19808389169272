import { Row, Spin, Descriptions } from 'antd'
import React from 'react'
import * as moment from 'moment'
import * as R from 'ramda'
import config from 'common/config'
import connect, { PromiseState } from 'react-redux-fetch'
import { Room, EventType, MealFormat, RightType, FileMethod, Timezone, EVENT_RESERVATION_TYPE } from 'types/models'
import urljoin from 'url-join'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'
import Bookings from '../calendar/bookings/Bookings'
import ContentList from '../contentPeople/contentList/ContentList'
import { BookingFormInterface, EventFormInterface } from '../types/form'
import { autobind } from 'core-decorators'
import BookingModal from '../calendar/bookingModal/BookingModal'
import { DispatchProp } from 'react-redux'
import { change, formValueSelector, WrappedFieldProps } from 'redux-form'
import { EVENT_FORM } from 'constants/Event'
import { Store } from 'antd/es/table/createStore'

import { modifyDateAccordingToLocalTimezone } from 'utils/date.utils'
import * as styles from './styles.styl'

interface StatePropsInterface {
  values: EventFormInterface,
  reviewMode?: boolean,
  createMode?: boolean,
  // timezone?: any,
}

interface FetchPropsInterface extends DispatchProp, WrappedFieldProps {
  roomsFetch: PromiseState<Room[]>
  dispatchRoomsGet(): void
  eventTypesFetch: PromiseState<EventType[]>
  dispatchEventTypesGet(): void
  mealFormatsFetch: PromiseState<MealFormat[]>
  dispatchMealFormatsGet(): void
  rightTypesFetch: PromiseState<RightType[]>
  dispatchRightTypesGet(): void
  fileMethodsFetch: PromiseState<FileMethod[]>
  dispatchFileMethodsGet(): void
  timezoneFetch: PromiseState<Timezone>
  dispatchTimezoneGet(): void
}


interface Props extends FetchPropsInterface {
    eventId: number
    bookings: BookingFormInterface[]
}

type ComponentPropsType = StatePropsInterface & Props

interface DescriptionStateInterface {
    modalOpen: boolean
    modalMassOpen: boolean
    currentValues?: BookingFormInterface
    room?: Room
  }

class Description extends React.Component<ComponentPropsType, DescriptionStateInterface> {
  public state: DescriptionStateInterface = {
    modalOpen: false,
    modalMassOpen: false
  }

  constructor (props: ComponentPropsType) {
    super(props);
  }

  public componentDidMount(): void {
    // this.props.dispatchTimezoneGet()
    this.props.dispatchRoomsGet()
    this.props.dispatchEventTypesGet()
    this.props.dispatchMealFormatsGet()
    this.props.dispatchRightTypesGet()
    this.props.dispatchFileMethodsGet()
  }

  @autobind
  public handleBookingEditAction(record: BookingFormInterface): void {
    // const { bookings, meta: { form }, input: { name } } = this.props
    let room = R.find(R.propEq('id', record.roomId), this.props.roomsFetch.value)
    this.setState({ modalOpen: true, currentValues: record, room: room })
  }

  @autobind
  public handleBookingEdit(recordOld: BookingFormInterface, recordNew: BookingFormInterface): void {
    const { bookings } = this.props
    this.props.dispatch(change(
      EVENT_FORM,
      "bookings",
      R.map(R.when(R.whereEq({
        'roomId': recordOld.roomId,
        'dates': recordOld.dates,
        'dismantlingDates': recordOld.dismantlingDates,
        'installingDates': recordOld.installingDates,
        'countPeople': recordOld.countPeople,
        'usageFormats': recordOld.usageFormats,
        'configs': recordOld.configs,
        'datesAlt': recordOld.datesAlt,
        'dismantlingDatesAlt': recordOld.dismantlingDatesAlt,
        'installingDatesAlt': recordOld.installingDatesAlt,
      }),
      R.pipe(
        R.assoc('countPeople', recordNew.countPeople),
        R.assoc('usageFormats', recordNew.usageFormats),
        R.assoc('configs', recordNew.configs),
        R.assoc('installingDates', recordNew.installingDates),
        R.assoc('dismantlingDates', recordNew.dismantlingDates),
        R.assoc('dates', recordNew.dates),
        R.assoc('installingDatesAlt', recordNew.installingDatesAlt),
        R.assoc('dismantlingDatesAlt', recordNew.dismantlingDatesAlt),
        R.assoc('datesAlt', recordNew.datesAlt),
      )
      ))(bookings)
    ))

    this.handleModalClose()
  }

  @autobind
  public handleModalClose(): void {
    this.setState({ modalOpen: false, room: undefined, currentValues: undefined })
  }

  public render(): React.ReactNode {
    const { values, roomsFetch, eventTypesFetch, mealFormatsFetch, rightTypesFetch, fileMethodsFetch, timezoneFetch, reviewMode, createMode } = this.props

    const timezone = timezoneFetch.fulfilled && timezoneFetch.value ? timezoneFetch.value.value : undefined
    const rooms = roomsFetch.fulfilled ? roomsFetch.value : undefined
    const eventType: EventType | undefined = eventTypesFetch.fulfilled &&
      R.find(R.propEq('id', parseInt(values.typeId, 10)))(eventTypesFetch.value)
    const mealFormat: MealFormat | undefined = mealFormatsFetch.fulfilled &&
      R.find(R.propEq('id', parseInt(values.mealFormatId, 10)))(mealFormatsFetch.value)

    
    let start = moment(values.startDate);
    if (timezone) {
      if (createMode) {
        start = modifyDateAccordingToLocalTimezone(start, timezone);
      }
      
      start = start.utcOffset(timezone);
    }
    // let start = moment(values.startDate).format(HUMAN_DATETIME_FORMAT);
    // if (timezone) {
    //   start = moment(values.startDate).utcOffset(timezone).format(HUMAN_DATETIME_FORMAT);
    // }

    return (
      <Row>
        <Descriptions>
          <Descriptions.Item label='Название мероприятия'>{values.name}</Descriptions.Item>
          <Descriptions.Item label='Тип мероприятия'>{eventType && eventType.name}</Descriptions.Item>
          <Descriptions.Item label='Краткое описание мероприятия'>{values.description}</Descriptions.Item>
          <Descriptions.Item label='Целевая аудитория'>{values.targetAudience}</Descriptions.Item>
          <Descriptions.Item label='Количество людей на площадке по дням'>{values.participantCount}</Descriptions.Item>
          <Descriptions.Item label='Формат питания'>{mealFormat && mealFormat.name}</Descriptions.Item>
          <Descriptions.Item className={styles.descLabel} label='Количество питающихся людей по предполагаемому формату питания по дням'>{values.participantCountMeal}</Descriptions.Item>
          <Descriptions.Item label='Сайт'>{values.website}</Descriptions.Item>
          <Descriptions.Item label='Возрастная категория'>{values.ageRating}</Descriptions.Item>
          <Descriptions.Item label='Продажа алкоголя'>{values.isSellingAlcohol ? 'Да' : 'Нет'}</Descriptions.Item>
          <Descriptions.Item label='Контроль квадратуры'>{values.enableSquareCheck ? 'Да' : 'Нет'}</Descriptions.Item>
          <Descriptions.Item label='Начало мероприятия'>{start.format(HUMAN_DATETIME_FORMAT)}</Descriptions.Item>
          {values.crmId && <Descriptions.Item label='ID сделки в CRM'>{values.crmId}</Descriptions.Item>}
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label='Помещения'>
            {
              rooms ?
                <Bookings
                  items={values.bookings}
                  rooms={rooms}
                  timezone={timezone}
                  reservationType={values.reservationType || EVENT_RESERVATION_TYPE.PRELIMINARY}
                  reviewMode={reviewMode || false}
                  shortModal={true}
                  onEdit={this.handleBookingEditAction}
                /> :
                <Spin />
            }
            {
                this.state.room &&
                    <BookingModal
                        timezone={timezone}
                        visible={this.state.modalOpen}
                        currentValues={this.state.currentValues}
                        room={this.state.room}
                        onAdd={() => {}}
                        onEdit={this.handleBookingEdit}
                        onClose={this.handleModalClose}
                        shortModal={true}
                        enableSquareCheck={false}
                    />
            }
          </Descriptions.Item>
        </Descriptions>
        {
          values.contents &&
            <Descriptions>
              <Descriptions.Item label='Контент 3-их лиц'>
                <ContentList
                  timezone={timezone}
                  fileMethods={fileMethodsFetch.value}
                  rightTypes={rightTypesFetch.value}
                  items={values.contents}
                />
              </Descriptions.Item>
            </Descriptions>
        }
      </Row>
    )
  }
}


const selector = formValueSelector(EVENT_FORM)

const mapStateToProps = (state: Store) => ({
  eventId: selector(state, 'id'),
  bookings: selector(state, 'bookings')
})

export default connect<
  Pick<Props, 'bookings' | 'eventId'>,
  Props
>(
  [
      {
        resource:  'timezone',
        method: 'get',
        request: {
          url: urljoin(config.backendUrl, 'timezone')
        }
      },
      {
        resource: 'rooms',
        method: 'get',
        request: {
          url: urljoin(config.backendUrl, 'rooms')
        }
      },
      {
        resource: 'eventTypes',
        method: 'get',
        request: {
          url: urljoin(config.backendUrl, 'eventTypes')
        }
      },
      {
        resource: 'mealFormats',
        method: 'get',
        request: {
          url: urljoin(config.backendUrl, 'mealFormats')
        }
      },
      {
        resource: 'rightTypes',
        method: 'get',
        request: {
          url: urljoin(config.backendUrl, 'rightTypes')
        }
      },
      {
        resource: 'fileMethods',
        method: 'get',
        request: {
          url: urljoin(config.backendUrl, 'fileMethods')
        }
      }
  ],
  mapStateToProps
)(Description)
