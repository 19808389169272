exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contentPeople__error___1WA_z {\n  color: #f5222d;\n}\n.contentPeople__wrapper___13dTa {\n  margin: 16px 0 0 0;\n}\n.contentPeople__addButton___XlcWl {\n  margin: 0 0 16px 0;\n}\n", ""]);

// exports
exports.locals = {
	"error": "contentPeople__error___1WA_z",
	"wrapper": "contentPeople__wrapper___13dTa",
	"addButton": "contentPeople__addButton___XlcWl"
};