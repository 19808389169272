import { autobind } from 'core-decorators'
import { PageHeader, Button, message } from 'antd'
import * as R from 'ramda'
import connect, { PromiseState, container } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import urljoin from 'url-join'
import React from 'react'
import { submit } from 'redux-form'
import { Action } from 'redux'
import { Room } from 'types/models'
import { ROOM_FORM } from 'constants/Room'
import config from 'common/config'

import Form, { RoomFormInterface } from '../_shared/form/Form'

interface Props extends RouteComponentProps {
  roomPostFetch: PromiseState<Room>

  dispatchRoomPost(data: RoomFormInterface): void

  dispatch(value: Action): void
}

class Create extends React.Component<Props> {
  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(ROOM_FORM))
  }

  @autobind
  public handeSubmit(values: RoomFormInterface): void {
    this.props.dispatchRoomPost(values)
  }

  public componentDidUpdate(prevProps: Props): void {
    const fetch = this.props.roomPostFetch
    if (prevProps.roomPostFetch.pending && fetch.fulfilled && fetch.value) {
      message.success('Помещение создано')
      this.props.history.push(`/admin/rooms/${fetch.value.id}`)
    }
  }

  public render(): React.ReactNode {
    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Помещение'
          subTitle='Создание'
          extra={[
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Создать
            </Button>
          ]}
        />
        <Form
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'room',
        name: 'roomPost'
      },
      method: 'post',
      request: (body: FormData) => ({
        url: urljoin(config.backendUrl, 'rooms'),
        body,
        headers: R.omit(['Content-Type'], container.getDefinition('requestHeaders').getArguments())
      })
    }
  ]
)(Create)
