import { Button, message, PageHeader, Popconfirm, Spin } from 'antd'
import config from 'common/config'
import { ROOM_FORM } from 'constants/Room'
import { autobind } from 'core-decorators'
import * as R from 'ramda'
import React from 'react'
import connect, { container, PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { FormAction, submit } from 'redux-form'
import { Room } from 'types/models'
import urljoin from 'url-join'

import Form from '../_shared/form/Form'
import { formatToForm } from '../_shared/form/helpers'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  roomGetFetch: PromiseState<Room>
  roomPutFetch: PromiseState<Room>
  roomDeleteFetch: PromiseState

  dispatchRoomGet(id: string): void
  dispatchRoomPut(id: string, data: FormData): void
  dispatchRoomDelete(id: string): void

  dispatch(value: FormAction): void
}

class Edit extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchRoomGet(this.props.match.params.id)
  }

  @autobind
  public handleSubmitClick(): void {
    this.props.dispatch(submit(ROOM_FORM))
  }

  @autobind
  public handleDelete(): void {
    if (this.props.roomGetFetch.value) {
      this.props.dispatchRoomDelete(String(this.props.roomGetFetch.value.id))
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.roomDeleteFetch.pending && this.props.roomDeleteFetch.fulfilled) {
      message.success('Помещение удалено')
      this.props.history.push(`/admin/rooms`)
    }

    if (prevProps.roomPutFetch.pending && this.props.roomPutFetch.fulfilled) {
      message.success('Помещение сохранено')
      this.props.dispatchRoomGet(this.props.match.params.id)
    }
  }

  @autobind
  public handeSubmit(values: FormData): void {
    this.props.dispatchRoomPut(
      this.props.match.params.id,
      values
    )
  }

  public render(): React.ReactNode {
    if (!this.props.roomGetFetch.value) {
      return <Spin/>
    }

    return (
      <div>
        <PageHeader
          onBack={this.props.history.goBack}
          title='Помещение'
          subTitle='Редактирование'
          extra={[
            <Popconfirm
              key='delete'
              title='Действительно удалить данное помещение?'
              onConfirm={this.handleDelete}
              okText='Удалить'
              cancelText='Отмена'
            >
              <Button
                type='danger'
              >
                Удалить
              </Button>
            </Popconfirm>,
            <Button
              key='save'
              type='primary'
              onClick={this.handleSubmitClick}
            >
              Сохранить
            </Button>
          ]}
        />
        <Form
          initialValues={formatToForm(this.props.roomGetFetch.value)}
          onSubmit={this.handeSubmit}
        />
      </div>
    )
  }
}

export default connect(
  [
    {
      resource: {
        action: 'room',
        name: 'roomGet'
      },
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'rooms', id)
      })
    },
    {
      resource: {
        action: 'room',
        name: 'roomPut'
      },
      method: 'put',
      request: (id: string, body: FormData) => ({
        url: urljoin(config.backendUrl, 'rooms', id),
        headers: R.omit(['Content-Type'], container.getDefinition('requestHeaders').getArguments()),
        body
      })
    },
    {
      resource: {
        action: 'room',
        name: 'roomDelete'
      },
      method: 'delete',
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'rooms', id)
      })
    }
  ]
)(Edit)
