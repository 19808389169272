import React from 'react'
import { Room } from 'types/models'
import { Modal, Button } from 'antd'

import { BookingFormInterface } from '../../types/form'
import BookingForm, { EVENT_BOOKING_FORM, EventBookingFormInterface } from '../bookingForm/BookingForm'

interface Props {
  timezone: any
  enableSquareCheck: Boolean
  currentValues?: BookingFormInterface
  room: Room
  visible: boolean
  shortModal?: boolean
  bookingsExclude?: number[]
  onClose(): void
  onAdd(values: BookingFormInterface): void
  onEdit(valuesOld: BookingFormInterface, valuesNew: BookingFormInterface): void
}

class BookingModal extends React.Component<Props> {
  public handleBookingAdd = (data: EventBookingFormInterface) => {
    this.props.onAdd({ ...data, roomId: this.props.room.id })
  }

  public handleBookingEdit = (dataOld: EventBookingFormInterface, dataNew: EventBookingFormInterface) => {
    this.props.onEdit({ ...dataOld, roomId: this.props.room.id }, { ...dataNew, roomId: this.props.room.id })
  }

  public render(): React.ReactNode {
    const { enableSquareCheck, currentValues, room, visible, timezone, onClose, shortModal } = this.props

    let width: string = room.isPavilion ? '80%' : '500px'
    if ( shortModal ) width = '500px';

    return (
      <Modal
        title={<div>Вы бронируете "{room.name}"</div>}
        onCancel={onClose}
        visible={visible}
        maskClosable={false}
        footer={[
          <Button key='back' onClick={onClose}>
            Отмена
          </Button>,
          <Button key='submit' form={EVENT_BOOKING_FORM} type='primary' htmlType='submit'>
            Ок
          </Button>
        ]}
        width={width}
      >
        <BookingForm
          onSuccess={this.handleBookingAdd}
          onEditSuccess={this.handleBookingEdit}
          bookingsExclude={this.props.bookingsExclude}
          room={room}
          shortModal={(shortModal) ? shortModal : false}
          currentValues={currentValues}
          enableSquareCheck={enableSquareCheck}
          timezone={timezone}
        />
      </Modal>
    )
  }
}

export default BookingModal
