import { Button, Col, Row, Card } from 'antd'
import { EVENT_FORM } from 'constants/Event'
import React from 'react'
import connect from 'react-redux-fetch'
import { Store } from 'redux'
import { Form, getFormValues, Field, reduxForm } from 'redux-form'
import { RadioField, TextField, SwitchField } from 'redux-form-antd'
import { EVENT_STATUS, EVENT_STATUS_LOCALIZED, EVENT_RESERVATION_TYPE, EVENT_RESERVATION_LOCALIZED, ALT_DATES, ALT_DATES_LOCALIZED } from 'types/models'

import Description from '../description/Description'
import * as styles from '../form.styl'
import { EventFormInterface } from '../types/form'

import { ComponentPropsType, MapStateToPropsType, StatePropsInterface, ComponentPropsInterface } from './types'
class ThirdStep extends React.Component<ComponentPropsType> {
  public render(): React.ReactNode {
    const { onSubmit, values, onBack, reviewMode, createMode, submitBlocked } = this.props

    let hasAltDates = false;

    if (reviewMode){
      let bookings = this.props.values.bookings;

      if(bookings){
        bookings.forEach((item) => {

          if (item.datesAlt || item.dismantlingDatesAlt || item.installingDatesAlt)
          {
            hasAltDates = true;
          }
        });
      }
    }

    if (values.reservationType == EVENT_RESERVATION_TYPE.CONCLUDING && !values.removeDates){
    }

    /*
     * Wrong way to do this
    */
    if (typeof values.isSellingAlcohol != "boolean")
    {
      let copyAlc = values.isSellingAlcohol;
      if (copyAlc == "false")
      {
        values.isSellingAlcohol = false
      } else {
        values.isSellingAlcohol = true
      }
    }

    if (typeof values.enableSquareCheck != "boolean")
    {
      let copyCheck = values.enableSquareCheck;
      if (copyCheck == "false")
      {
        values.enableSquareCheck = false
      } else {
        values.enableSquareCheck = true
      }
    }

    return (
      <Form onSubmit={onSubmit}>
        <Description values={values} reviewMode={reviewMode} createMode={createMode} />
        {
          reviewMode &&
            <div className={styles.reviewFormWrapper}>
              <Card title='Согласование'>
                <label>Статус согласования</label>
                <Field
                  name='status'
                  component={RadioField}
                  options={[EVENT_STATUS.DECLINED, EVENT_STATUS.APPROVED].map(
                    (value: EVENT_STATUS) => ({
                      value,
                      label: EVENT_STATUS_LOCALIZED[value]
                    })
                  )}
                  hasFeedback={false}
                />
                <label>Статус брони</label>
                <Field
                  name='reservationType'
                  component={RadioField}
                  options={[EVENT_RESERVATION_TYPE.CONCLUDING, EVENT_RESERVATION_TYPE.PRELIMINARY].map(
                    (value: EVENT_RESERVATION_TYPE) => ({
                      value,
                      label: EVENT_RESERVATION_LOCALIZED[value]
                    })
                  )}
                  hasFeedback={false}
                />
                {
                  hasAltDates && reviewMode && values.reservationType == EVENT_RESERVATION_TYPE.CONCLUDING &&
                    <>
                    <label>Альтернативные даты</label>
                    <Field
                      name='removeDates'
                      component={RadioField}
                      options={[ALT_DATES.MAIN, ALT_DATES.ALT].map(
                        (value: ALT_DATES) => ({
                          value,
                          label: ALT_DATES_LOCALIZED[value]
                        })
                      )}
                      hasFeedback={false}
                    />
                    </>
                }
                <label>Разместить на сайте?</label>
                <Field
                  name='shouldBePublishedOnWebsite'
                  component={SwitchField}
                  hasFeedback={false}
                />
                <label>Комментарий</label>
                <Field
                  name='reason'
                  component={TextField}
                />
              </Card>
            </div>
        }
        <Row>
          <Col className={styles.buttonsWrapper}>
            <Button
              type='ghost'
              onClick={onBack}
            >
              Назад
            </Button>
            <Button
              disabled={submitBlocked}
              type='primary'
              htmlType='submit'
            >
              Отправить
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

const valuesGetter = getFormValues(EVENT_FORM) as (state: Store) => EventFormInterface

const mapStateToProps: MapStateToPropsType = (state: Store) => ({
  values: valuesGetter(state)
})

export default reduxForm<{}, Partial<ComponentPropsInterface>>({
  form: EVENT_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(connect<StatePropsInterface, {}, ComponentPropsInterface>(
  [],
  mapStateToProps
)(ThirdStep))
