import React from 'react'
import { reduxForm, Field, Form } from 'redux-form'
import { TextField } from 'redux-form-antd'
import { autobind } from 'core-decorators'
import { EMAIL_FORM } from 'constants/Email'

import { EmailFormInterface, Props } from './types'
import { validate } from './helpers'

class RoomForm extends React.Component<Props> {
  @autobind
  public handleSubmit(values: EmailFormInterface): void {
    this.props.onSubmit(values)
  }

  public render(): React.ReactNode {
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <label>Электронный адрес</label>
        <Field
          name='email'
          component={TextField}
        />
      </Form>
    )
  }
}

export default reduxForm<{}>(
  {
    form: EMAIL_FORM,
    enableReinitialize: true,
    validate
  }
)(RoomForm)
