exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".calendar__rooms___2ys0X {\n  margin-top: 16px;\n}\n.calendar__block___2KMCd {\n  line-height: 32px;\n  display: inline-block;\n  margin-left: 16px;\n}\n.calendar__block___2KMCd:before {\n  vertical-align: -35%;\n  content: '';\n  display: inline-block;\n  width: 51.77px;\n  height: 32px;\n  margin-right: 8px;\n}\n.calendar__block___2KMCd.calendar__montage___1IVq-:before {\n  background-color: #434343;\n}\n.calendar__block___2KMCd.calendar__softReserve___3Vr5t:before {\n  background-color: #feff00;\n}\n.calendar__block___2KMCd.calendar__booked___3Zk6l:before {\n  background-color: #fe0000;\n}\n.calendar__block___2KMCd.calendar__selection___11qA1:before {\n  background-color: #4a86e8;\n}\n.calendar__block___2KMCd.calendar__overlap___2EFUg:before {\n  background-color: #ffc500;\n}\n", ""]);

// exports
exports.locals = {
	"rooms": "calendar__rooms___2ys0X",
	"block": "calendar__block___2KMCd",
	"montage": "calendar__montage___1IVq-",
	"softReserve": "calendar__softReserve___3Vr5t",
	"booked": "calendar__booked___3Zk6l",
	"selection": "calendar__selection___11qA1",
	"overlap": "calendar__overlap___2EFUg"
};