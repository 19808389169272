import React from 'react'
import { Row, Spin } from 'antd'
import { RESERVATION_TYPE_TO_STRING } from 'constants/Event'
import moment from 'moment'
import * as R from 'ramda'
import config from 'common/config'
import connect, { PromiseState } from 'react-redux-fetch'
import { RouteComponentProps } from 'react-router'
import { Dispatch } from 'redux'
import { Booking, Content, Event, RightType, FileMethod, EVENT_STATUS_LOCALIZED, DateRangeDatumType, Timezone } from 'types/models'
import urljoin from 'url-join'
import Calendar from 'components/shared/calendar/Calendar'
import userActions from 'actions/User'
import { RcFile } from 'antd/lib/upload'

import Description from '../_shared/form/description/Description'
import { BookingFormInterface, ContentFormInterface, EventFormInterface } from '../_shared/form/types/form'

import * as styles from './styles.styl'

interface RouteParams {
  id: string
}

interface Props extends RouteComponentProps<RouteParams> {
  eventFetch: PromiseState<Event>
  dispatchEventGet(id: string): void
  rightTypesFetch: PromiseState<RightType[]>
  dispatchRightTypesGet(): void
  fileMethodsFetch: PromiseState<FileMethod[]>
  dispatchFileMethodsGet(): void
  timezoneFetch: PromiseState<Timezone>
  dispatchTimezoneGet(): void
  restoreToken(): void
}

function bookingToBookingForm(booking: Booking, timezone: any | undefined): BookingFormInterface {
  return {
    id: booking.id,
    roomId: booking.roomId,
    dates: [
      timezone ? moment(booking.dates[0].value).utcOffset(timezone) : moment(booking.dates[0].value),
      timezone ? moment(booking.dates[1].value).utcOffset(timezone) : moment(booking.dates[1].value)
    ],
    dismantlingDates: [
      timezone ? moment(booking.dismantlingDates[0].value).utcOffset(timezone) : moment(booking.dismantlingDates[0].value),
      timezone ? moment(booking.dismantlingDates[1].value).utcOffset(timezone) : moment(booking.dismantlingDates[1].value)
    ],
    installingDates: [
      timezone ? moment(booking.installingDates[0].value).utcOffset(timezone) : moment(booking.installingDates[0].value),
      timezone ? moment(booking.installingDates[1].value).utcOffset(timezone) : moment(booking.installingDates[1].value)
    ],
    squares: booking.squares || '',
    datesAlt: (booking.datesAlt && booking.datesAlt.length == 2) ? [
      timezone ? moment(booking.datesAlt[0].value).utcOffset(timezone) : moment(booking.datesAlt[0].value),
      timezone ? moment(booking.datesAlt[1].value).utcOffset(timezone) : moment(booking.datesAlt[1].value)
    ] : undefined,
    installingDatesAlt: (booking.installingDatesAlt && booking.installingDatesAlt.length == 2) ? [
      timezone ? moment(booking.installingDatesAlt[0].value).utcOffset(timezone) : moment(booking.installingDatesAlt[0].value),
      timezone ? moment(booking.installingDatesAlt[1].value).utcOffset(timezone) : moment(booking.installingDatesAlt[1].value)
    ] : undefined,
    dismantlingDatesAlt: (booking.dismantlingDatesAlt && booking.dismantlingDatesAlt.length == 2) ? [
      timezone ? moment(booking.dismantlingDatesAlt[0].value).utcOffset(timezone) : moment(booking.dismantlingDatesAlt[0].value),
      timezone ? moment(booking.dismantlingDatesAlt[1].value).utcOffset(timezone) : moment(booking.dismantlingDatesAlt[1].value)
    ] : undefined
  }
}

function contentToContentForm(content: Content, timezone: any | undefined): ContentFormInterface {

  // let blobObject = {start: undefined, end: undefined, contentType: undefined},
      // tmpBlob = new Blob([JSON.stringify(blobObject, null, 2)], {type : 'application/json'});
      // bb = Buffer.from(JSON.stringify(blobObject, null, 2), "utf-8");

  let tmpRightTypeId = '';
  if (content.rightTypeId){
    tmpRightTypeId = content.rightTypeId.toString()
  }

  let tmpFileMethodId = '';
  if (content.fileMethodId){
    tmpFileMethodId = content.fileMethodId;
  }

  let tmpFile: RcFile = {
    name: String(content.file),
    uid: '',
    lastModifiedDate: new Date(),
    webkitRelativePath: '',
    lastModified: 1,
    size: 1,
    type: '',
    slice: File.prototype.slice
  }

  return {
    id: content.id,
    contentId: String(content.id) || '',
    rightTypeId: tmpRightTypeId,
    fileMethodId: tmpFileMethodId,
    usageDates: [
      timezone ? moment(content.usageDates[0].value).utcOffset(timezone) : moment(content.usageDates[0].value),
      timezone ? moment(content.usageDates[1].value).utcOffset(timezone) : moment(content.usageDates[0].value)
    ],
    file: tmpFile,
  }
}

function eventToEventForm(event: Event, rightTypes: RightType[], fileMethods: FileMethod[], timezone: any | undefined): EventFormInterface {
  // function eventToEventForm(event: Event): EventFormInterface {

  // wrong!
  let eventCopy = JSON.parse(JSON.stringify(event));
  let processedContents = eventCopy.contents || [];

  for (let i = 0; i < processedContents.length; i++) {

    /*
     * Обрабатываем пределы прав для вывода
    */
    let rTypeId = processedContents[i].rightTypeId || 0;
    if (rTypeId){
      for(const element of rightTypes){
        if (element.id.toString() === rTypeId.toString()){
          processedContents[i].rightTypeId = element.name
        }
      }
    }

    /*
     * Обрабатываем способы для вывода
    */
    let fMethodId = processedContents[i].fileMethodId || '';
    if (fMethodId != ''){
      if (typeof fMethodId === 'string'){fMethodId = fMethodId.split(',');}

      if (fileMethods)
      {
        let result = '';
        for(const methodId of fMethodId){
          for(const element of fileMethods){
            if (element.id.toString() === methodId.toString())
            {
              let delimiter = ', ';
              if (result == ''){delimiter = '';}
              result = result + delimiter + element.name;
            }
          }
        }
        processedContents[i].fileMethodId = result;
      }
    }
  }

  return {
    ...R.pick(
      [
        'name',
        'description',
        'targetAudience',
        'participantCount',
        'participantCountMeal',
        'website',
        'ageRating',
        'isSellingAlcohol',
        'enableSquareCheck',
        'startDate'
      ],
      event
    ),
    bookings: eventCopy.bookings ? R.map(bookingToBookingForm, eventCopy.bookings, timezone) : [],
    contents: eventCopy.contents ? R.map(contentToContentForm, eventCopy.contents, timezone) : [],
    typeId: String(eventCopy.typeId),
    mealFormatId: String(eventCopy.mealFormatId)
  }
}

class Show extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchEventGet(this.props.match.params.id)
    this.props.dispatchRightTypesGet()
    this.props.dispatchFileMethodsGet()
    this.props.dispatchTimezoneGet()
    this.props.restoreToken()
  }

  public generateDatesFromBookings(bookings: Booking[], timezone: any | undefined): [moment.Moment, moment.Moment] {

    let utcOffset = '+00:00';
    if (timezone) {
      utcOffset = timezone;
    }

    const sortedBookings: DateRangeDatumType[] = R.pipe(
      R.map(R.pick(['dates', 'dismantlingDates', 'installingDates'])),
      R.map(R.values),
      R.flatten,
      R.sort(R.comparator((date: DateRangeDatumType, date2: DateRangeDatumType) => moment(date.value).isBefore(moment(date2.value))))
    )(bookings)

    if (sortedBookings.length === 0) {
      return [
        moment().startOf('month').utcOffset(utcOffset),
        moment().endOf('month').utcOffset(utcOffset)
      ]
    }

    return [
      moment(R.head(sortedBookings).value).subtract(15, 'd').utcOffset(utcOffset),
      moment(R.last(sortedBookings).value).add(15, 'd').utcOffset(utcOffset)
    ]
  }

  public render(): React.ReactNode {
    const { eventFetch, rightTypesFetch, fileMethodsFetch, timezoneFetch } = this.props
    {
      rightTypesFetch.fulfilled && fileMethodsFetch.fulfilled && timezoneFetch.fulfilled && rightTypesFetch.value && fileMethodsFetch.value

      let fileMethods = fileMethodsFetch.value || [],
          rightTypes = rightTypesFetch.value || [],
          timezone = timezoneFetch.value ? timezoneFetch.value.value : null;

      return (
        <>
          {
            eventFetch.fulfilled && eventFetch.value ?
              <Description values={eventToEventForm(eventFetch.value, rightTypes, fileMethods, timezone)} /> :
              <Spin/>
          }
          {
            eventFetch.fulfilled && eventFetch.value &&
              <Calendar
                dates={this.generateDatesFromBookings(eventFetch.value.bookings || [], timezone)}
                currentEvent={eventFetch.value}
              />
          }
          {
            eventFetch.fulfilled ?
              <Row>
                <div className={styles.status}>
                  <div>
                    {
                      eventFetch.value &&
                      eventFetch.value.reservationType &&
                        `Статус брони: ${RESERVATION_TYPE_TO_STRING[eventFetch.value.reservationType]}`
                    }
                  </div>
                  <div>
                    {
                      eventFetch.value &&
                        `Статус согласования: ${EVENT_STATUS_LOCALIZED[eventFetch.value.status]}`
                    }
                  </div>
                </div>
              </Row> :
              <Spin/>
          }
        </>
      )
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    restoreToken: () => { dispatch(userActions.restoreToken()) }
  }
}

export default connect(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource: 'event',
      method: 'get',
      request: (id: string) => ({
        url: urljoin(config.backendUrl, 'events', id)
      })
    },
    {
      resource: 'rightTypes',
      method: 'get',
      request: {
        url: urljoin(config.backendUrl, 'rightTypes')
      }
    },
    {
      resource: 'fileMethods',
      method: 'get',
      request: {
        url: urljoin(config.backendUrl, 'fileMethods')
      }
    }
  ],
  undefined,
  mapDispatchToProps
)(Show)
