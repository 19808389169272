import React from 'react'
import { Steps, Icon, Spin } from 'antd'
import { autobind } from 'core-decorators'
import connect from 'react-redux-fetch'
import { reduxForm } from 'redux-form'
import { EVENT_FORM } from 'constants/Event'
import config from 'common/config'
import urljoin from 'url-join'

import FirstStep from './firstStep/FirstStep'
import SecondStep from './secondStep/SecondStep'
import ThirdStep from './thirdStep/ThirdStep'
import ContentStep from './contentStep/ContentStep'
import { validate } from './helpers/validate'
import { ComponentPropsInterface, ComponentStateInterface, OwnPropsInterface, MapStateToPropsType } from './types'
import { StoreInterface } from 'store'

const { Step } = Steps

class EventForm extends React.Component<ComponentPropsInterface, ComponentStateInterface, MapStateToPropsType> {
  public state: ComponentStateInterface = {
    step: 0
  }

  public componentDidMount(): void {
    this.props.dispatchEventTypesGet()
    this.props.dispatchMealFormatsGet()
    this.props.dispatchRightTypesGet()
    this.props.dispatchFileMethodsGet()
    this.props.dispatchUsersGet()
    // this.props.dispatchTimezoneGet()
  }

  @autobind
  public nextPage(): void {
    this.setState((state: ComponentStateInterface) => ({ step: state.step + 1 }))
  }

  @autobind
  public prevPage(): void {
    this.setState((state: ComponentStateInterface) => ({ step: state.step - 1 }))
  }

  public render(): React.ReactNode {
    const { currentUserRole } = this.props

    // return this.props.eventTypesFetch.value ? (
    return this.props.eventTypesFetch.value && this.props.mealFormatsFetch.value && this.props.rightTypesFetch.value && this.props.fileMethodsFetch.value && this.props.usersFetch.value && this.props.timezoneFetch ? (
    // return this.props.eventTypesFetch.value && this.props.mealFormatsFetch.value && this.props.rightTypesFetch.value && this.props.fileMethodsFetch.value && this.props.usersFetch.value ? (
      <>
        <Steps current={this.state.step}>
          <Step title='Основная информация' icon={<Icon type='profile' />} />
          <Step title='Контент 3-их лиц' icon={<Icon type='file' />} />
          <Step title='Выбор помещения' icon={<Icon type='home' />} />
          <Step title='Отправка на согласование' icon={<Icon type='like' />} />
        </Steps>
        {
          this.state.step === 0 &&
          <FirstStep
            users={this.props.usersFetch.value}
            currentUserRole={currentUserRole}
            eventTypes={this.props.eventTypesFetch.value}
            mealFormats={this.props.mealFormatsFetch.value}
            onSubmit={this.nextPage}
          />
        }
        {
          this.state.step === 1 &&
          <ContentStep
            fileMethods={this.props.fileMethodsFetch.value}
            rightTypes={this.props.rightTypesFetch.value}
            timezone={this.props.timezoneFetch && this.props.timezoneFetch.value ? this.props.timezoneFetch.value.value : null}
            onBack={this.prevPage}
            onSubmit={this.nextPage}
          />
        }
        {
          this.state.step === 2 &&
          <SecondStep
            timezone={this.props.timezoneFetch && this.props.timezoneFetch.value ? this.props.timezoneFetch.value.value : null}
            onBack={this.prevPage}
            onSubmit={this.nextPage}
          />
        }
        {
          this.state.step === 3 &&
          <ThirdStep
            submitBlocked={this.props.submitBlocked}
            reviewMode={this.props.reviewMode}
			createMode={this.props.createMode}
            onBack={this.prevPage}
            onSubmit={this.props.handleSubmit(this.props.onSubmit)}
          />
        }
      </>
    ) : <Spin />
  }
}

const mapStateToProps: MapStateToPropsType = (state: StoreInterface) => ({
  currentUserRole: state.user.data && state.user.data.role
})

export default connect(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource:  'eventTypes',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'eventTypes')
      })
    },
    {
      resource:  'mealFormats',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'mealFormats')
      })
    },
    {
      resource:  'rightTypes',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'rightTypes')
      })
    },
    {
      resource:  'fileMethods',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'fileMethods')
      })
    },
    {
      resource:  'users',
      method: 'get',
      request: () => ({
        url: urljoin(config.backendUrl, 'users')
      })
    }
  ],
  mapStateToProps
)(reduxForm<{}, OwnPropsInterface>({
  form: EVENT_FORM,
  // tslint:disable-next-line:no-any
  validate: validate as any
})(EventForm))
