import { Row } from 'antd'
import { EVENT_TYPE_FORM } from 'constants/EventType'
import { autobind } from 'core-decorators'
import React from 'react'
import { connect } from 'react-redux'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import { TextField, RadioField } from 'redux-form-antd'

import { validate } from './helpers'

interface Props extends InjectedFormProps {
  onSubmit(values: EventTypeFormInterface): void
}

export interface EventTypeFormInterface {
  name: string
  isSendableToCrm: string
}

class EventTypeForm extends React.Component<Props> {
  @autobind
  public handleSubmit(values: EventTypeFormInterface): void {
    this.props.onSubmit(values)
  }

  public render(): React.ReactNode {
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <Row>
          <label>Название</label>
          <Field
            name='name'
            component={TextField}
            placeholder='Тип мероприятия'
          />
          <label>Отправлять в CRM</label>
          <Field
            name='isSendableToCrm'
            component={RadioField}
            options={[
              {
                label: 'Да',
                value: true
              },
              {
                label: 'Нет',
                value: false
              }
            ]}
            hasFeedback={false}
          />
        </Row>
      </Form>
    )
  }
}

export default connect()(reduxForm(
  {
    form: EVENT_TYPE_FORM,
    enableReinitialize: true,
    // tslint:disable-next-line:no-any
    validate: validate as any
  }
)(EventTypeForm))
