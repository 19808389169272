import { USER_ROLE, User, Event, EVENT_RESERVATION_TYPE } from 'types/models'
import { Rules } from 'react-abac'

import { Permissions } from './permissions'

const rules: Rules<USER_ROLE, Permissions, User> = {
  [USER_ROLE.ADMIN]: {
    [Permissions.EVENT_CREATE]: true,
    [Permissions.EVENT_EDIT]: (event: Event) => !event.archivedAt,
    [Permissions.EVENT_REVIEW]: (event: Event) => !event.archivedAt,
    [Permissions.EVENT_ARCHIVE]: (event: Event) => !event.archivedAt
  },
  [USER_ROLE.SUPERVISOR]: {
    [Permissions.EVENT_CREATE]: true,
    [Permissions.EVENT_EDIT]: (event: Event) => !event.archivedAt,
    [Permissions.EVENT_REVIEW]: (event: Event) => !event.archivedAt,
    [Permissions.EVENT_ARCHIVE]: (event: Event) => !event.archivedAt
  },
  [USER_ROLE.MANAGER]: {
    [Permissions.EVENT_CREATE]: true,
    [Permissions.EVENT_EDIT]: (event: Event, user?: User) =>
      user &&
        event.authorId === user.id &&
        event.reservationType !== EVENT_RESERVATION_TYPE.CONCLUDING &&
        !event.archivedAt,
    [Permissions.EVENT_ARCHIVE]: (event: Event, user?: User) =>
      user &&
        event.authorId === user.id &&
        !event.archivedAt
  }
}

export default rules
