import { User } from 'types/models'

import { LoginActions } from '../constants/Login'
import { UserStoreInterface } from '../store'

const initialState: UserStoreInterface = {
  data: undefined,
  isLoading: false,
  error: undefined
}

export default function user(
  state: UserStoreInterface = initialState,
  { type, payload }: { type: LoginActions; payload: User | string }
): UserStoreInterface {
  switch (type) {
  case LoginActions.USER_LOGGING_IN:
    return { ...initialState, isLoading: true }
  case LoginActions.USER_LOGGED_IN:
    return { data: payload as User, isLoading: false, error: undefined }
  case LoginActions.USER_LOGIN_ERROR:
    return { ...initialState, isLoading: false, error: payload as string }
  case LoginActions.USER_LOGGED_OUT:
    return initialState
  default:
    return state
  }
}
