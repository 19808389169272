exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".admin__content___xSdgZ {\n  padding: 0 16px;\n  background-color: #fff;\n}\n.admin__sider___2pHWe {\n  background-color: #fff;\n}\n.admin__menu___32bOr {\n  height: 100%;\n}\n.admin__menu___32bOr .ant-menu-item {\n  white-space: normal;\n  height: auto;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  line-height: 22px;\n}\n", ""]);

// exports
exports.locals = {
	"content": "admin__content___xSdgZ",
	"sider": "admin__sider___2pHWe",
	"menu": "admin__menu___32bOr"
};