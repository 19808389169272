import React from 'react'
import { reduxForm, Field, Form } from 'redux-form'
import { TextField, RadioField } from 'redux-form-antd'
import { autobind } from 'core-decorators'
import { USER_ROLE } from 'types/models'
import { USER_ROLE_TO_STRING, USER_FORM } from 'constants/User'

import { UserFormInterface, Props, OwnPropsInterface } from './types'
import { validate } from './helpers'

class RoomForm extends React.Component<Props> {
  @autobind
  public handleSubmit(values: UserFormInterface): void {
    this.props.onSubmit(values)
  }

  public render(): React.ReactNode {
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <label>Имя</label>
        <Field
          name='name'
          component={TextField}
        />
        <label>Логин</label>
        <Field
          name='login'
          component={TextField}
        />
        <label>Пароль</label>
        <Field
          name='password'
          component={TextField}
        />
        <label>Роль</label>
        <Field
          name='role'
          component={RadioField}
          options={
            Object.values(USER_ROLE)
              .map((role: string) => ({
                label: USER_ROLE_TO_STRING[role],
                value: role
              }))
          }
        />
      </Form>
    )
  }
}

export default reduxForm<{}, OwnPropsInterface>(
  {
    form: USER_FORM,
    enableReinitialize: true,
    validate
  }
)(RoomForm)
