import React from 'react'
import classnames from 'classnames'
import { Booking as BookingModel, EVENT_STATUS_LOCALIZED, EVENT_RESERVATION_LOCALIZED } from 'types/models'
import { formatDateRange, checkDateRange } from 'utils/date.utils'

import { PopoverDateRanges } from '../types'

import * as styles from './booking.styl'

interface Props {
  booking: BookingModel
  dateRanges: PopoverDateRanges
}

class Booking extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { dateRanges, booking } = this.props

    return (
      <div className={styles.root}>
        {
          booking.event && booking.event.id
            ? <h2><a target='_blank' href={'/events/' + booking.event.id}>{booking.event && booking.event.name}</a></h2>
            : <h2>{booking.event && booking.event.name}</h2>
        }
        <div>
          <span className={styles.label}>Менеджер:</span>{' '}
          {booking.event && booking.event.author && booking.event.author.name}
        </div>
        <div>
          <span className={styles.label}>Статус брони:</span>{' '}
          {booking.event && booking.event.reservationType ?
            EVENT_RESERVATION_LOCALIZED[booking.event.reservationType] :
            'Не указано'}
        </div>
        <div>
          <span className={styles.label}>Статус согласования:</span>{' '}
          {booking.event && EVENT_STATUS_LOCALIZED[booking.event.status]}
        </div>
        <div className={classnames({ [styles.highlighted]: dateRanges.installingDates.isConflicting })}>
          <span className={styles.label}>Даты монтажа:</span><br />{' '}
          {
            dateRanges.installingDatesAlt && dateRanges.installingDatesAlt.range && checkDateRange(dateRanges.installingDatesAlt.range) ? 
              <div><span><u>Осн.:</u>{' '}{formatDateRange(dateRanges.installingDates.range)}</span><br /><span><u>Альт.:</u>{' '}{formatDateRange(dateRanges.installingDatesAlt.range)}</span></div> : formatDateRange(dateRanges.installingDates.range)
          }
        </div>
        <div className={classnames({ [styles.highlighted]: dateRanges.dates.isConflicting })}>
          <span className={styles.label}>Даты проведения мероприятия:</span><br />{' '}
          {
            dateRanges.datesAlt && dateRanges.datesAlt.range && checkDateRange(dateRanges.datesAlt.range) ? 
              <div><span><u>Осн.:</u>{' '}{formatDateRange(dateRanges.dates.range)}</span><br /><span><u>Альт.:</u>{' '}{formatDateRange(dateRanges.datesAlt.range)}</span></div> : formatDateRange(dateRanges.dates.range)
          }
        </div>
        <div className={classnames({ [styles.highlighted]: dateRanges.dismantlingDates.isConflicting })}>
          <span className={styles.label}>Даты демонтажа:</span><br />{' '}
          {
            dateRanges.dismantlingDatesAlt && dateRanges.dismantlingDatesAlt.range && checkDateRange(dateRanges.dismantlingDatesAlt.range) ? 
              <div><span><u>Осн.:</u>{' '}{formatDateRange(dateRanges.dismantlingDates.range)}</span><br /><span><u>Альт.:</u>{' '}{formatDateRange(dateRanges.dismantlingDatesAlt.range)}</span></div> : formatDateRange(dateRanges.dismantlingDates.range)
          }
        </div>
      </div>
    )
  }
}

export default Booking
