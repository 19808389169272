import { applyMiddleware, createStore, Store, compose } from 'redux'
import { middleware as fetchMiddleware, PromiseState } from 'react-redux-fetch'
import createSagaMiddleware from 'redux-saga'
import { User } from 'types/models'
import rootReducer from 'reducers/rootReducer'
import sagas from 'sagas/index'

export interface UserStoreInterface {
  data?: User
  isLoading: boolean
  error?: string
}

export interface StoreInterface {
  user: UserStoreInterface,
  repository?: {
    [name: string]: PromiseState;
  }
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [fetchMiddleware, sagaMiddleware]

const initialState: StoreInterface = {
  user: {
    data: undefined,
    isLoading: false,
    error: undefined
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store: Store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
)

sagaMiddleware.run(sagas)

export default store
