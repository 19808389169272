exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form__pavilionSchemaLabel___5Wkzl {\n  display: block;\n}\n.form__schemaImage___Pslji {\n  max-width: 100%;\n  margin-bottom: 24px;\n}\n", ""]);

// exports
exports.locals = {
	"pavilionSchemaLabel": "form__pavilionSchemaLabel___5Wkzl",
	"schemaImage": "form__schemaImage___Pslji"
};