import urljoin from 'url-join'
import Axios, { AxiosResponse } from 'axios'
import { takeLatest, put, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { container, FETCH } from 'react-redux-fetch'
import { decode } from 'jsonwebtoken'
import { User } from 'types/models'

import loginActions, { ILoginAction } from '../actions/User'
import { LoginActions } from '../constants/Login'
import config from '../common/config'

enum STORAGE_KEY {
  TOKEN = 'token'
}

enum REQUEST_HEADER {
  AUTHORIZATION = 'Authorization'
}

function setRequestHeader(key: REQUEST_HEADER, value: string): void {
  container.registerRequestHeader(key, value)
  Axios.defaults.headers.common[key] = value
}

function* login(action: ILoginAction): SagaIterator {
  try {
    const resp: AxiosResponse<string> = yield call(
      Axios.post,
      urljoin(String(config.backendUrl), '/login'),
      action.payload
    )

    setRequestHeader(REQUEST_HEADER.AUTHORIZATION, `Bearer ${resp.data}`)
    window.localStorage.setItem(STORAGE_KEY.TOKEN, resp.data)

    const user: User = decode(resp.data) as User

    yield put(loginActions.loginSuccess(user))
  } catch (error) {
    yield put(loginActions.loginError(error.message))
  }
}

// any here, ts wont compile this if we hint action as PromiseState
// tslint:disable-next-line:no-any
function* handleUnauthorizedResponse(action: any): SagaIterator {
  if (action.request && action.request.meta && action.request.meta.response.status === 401) {
    yield put(loginActions.logout())
  }
}

function* restoreToken(): SagaIterator {
  const token: string | null = window.localStorage.getItem(STORAGE_KEY.TOKEN)
  if (!token) {
    return
  }
  setRequestHeader(REQUEST_HEADER.AUTHORIZATION, `Bearer ${token}`)

  const user: User = decode(token) as User

  yield put(loginActions.loginSuccess(user))
}

function logout(): void {
  window.localStorage.removeItem(STORAGE_KEY.TOKEN)
  setRequestHeader(REQUEST_HEADER.AUTHORIZATION, '')
}

export default function* (): SagaIterator {
  yield takeLatest(LoginActions.USER_LOGGING_IN, login)
  yield takeLatest(LoginActions.USER_LOGGED_OUT, logout)
  yield takeLatest(LoginActions.USER_RESTORE_TOKEN, restoreToken)
  yield takeLatest(FETCH.for('get').REJECT, handleUnauthorizedResponse)
}
