exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__status___3u1OA {\n  margin-bottom: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"status": "styles__status___3u1OA"
};