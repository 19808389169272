import { EventFormInterface } from '../types/form'
import { EVENT_RESERVATION_TYPE } from 'types/models'

type ValidationResult = { [P in keyof EventFormInterface]?: string }

const MAX_LENGTH_SIZE_FOR_VARCHAR: number = 250

export function validate(values: EventFormInterface): ValidationResult {
  const requiredFields: (keyof EventFormInterface)[] = [
    'ageRating', 'bookings', 'website',
    'description', 'isSellingAlcohol',
    'name', 'participantCount', 'participantCountMeal', 'targetAudience',
    'status', 'reservationType', 'startDate', 'typeId', 'mealFormatId'
  ]

  const maxLengthFields: (keyof EventFormInterface)[] = ['participantCount', 'participantCountMeal', 'website', 'name', 'crmId']

  const errors: ValidationResult = requiredFields.reduce(
    (acc: ValidationResult, field: keyof EventFormInterface) => ({
      ...acc,
      ...(!values[field] ? { [field]: 'Поле обязательно для заполнения' } : {})
    }),
    {}
  )

  maxLengthFields.forEach((field: 'name' | 'participantCount' | 'participantCountMeal' | 'website' | 'crmId') => {
    if (values && values[field] && values[field].length > MAX_LENGTH_SIZE_FOR_VARCHAR) {
      errors[field] = 'Максимальная длина для данного поля: 250 символов'
    }
  })

  if (!values.bookings || values.bookings.length < 1) {
    errors.bookings = 'Выберите как минимум одно помещение'
  }

  if (values.crmId && !/^[0-9]*$/.test(values.crmId)) {
    errors.crmId = 'В этом поле разрешены только цифры'
  }

  if (values.participantCount && !/^[0-9,]*$/.test(values.participantCount)) {
    errors.participantCount = 'В этом поле разрешены только цифры и запятые'
  }

  if (values.participantCountMeal && !/^[0-9,]*$/.test(values.participantCountMeal)) {
    errors.participantCountMeal = 'В этом поле разрешены только цифры и запятые'
  }

  if (values.reservationType == EVENT_RESERVATION_TYPE.CONCLUDING && !values.removeDates){
    errors.removeDates = 'Поле обязательно для заполнения'
  }

  return errors
}
