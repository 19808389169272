import { RightType } from 'types/models'

import { RightTypeFormInterface } from './Form'

type RightTypeFormValidation = {
  [F in keyof RightTypeFormInterface]?: string
}

export function validate(values: RightTypeFormInterface): RightTypeFormValidation {
  const errors: RightTypeFormValidation = {}

  if (!values.name) {
    errors.name = 'Поле обязательно для заполнения'
  }

  return errors
}

export function formatToForm(values: RightType): RightTypeFormInterface {
  return {
    name: values.name,
  }
}
