import { Button, Tooltip, Popover, Descriptions, Popconfirm, Icon } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import copy from 'copy-to-clipboard'
import React from 'react'
import * as moment from 'moment'
import { AllowedTo } from 'react-abac'
import { Link } from 'react-router-dom'
import { Permissions } from 'permissions/permissions'
import {
  Booking,
  Event,
  EVENT_RESERVATION_LOCALIZED,
  EVENT_RESERVATION_TYPE,
  EVENT_STATUS,
  EVENT_STATUS_LOCALIZED,
  User
} from 'types/models'
import { backendDateRangeToMomentDatetimeRange, formatDateRange } from 'utils/date.utils'
import { HUMAN_DATETIME_FORMAT } from 'constants/DateTime'

import StatusReasonPopover from '../_shared/statusReasonPopover/StatusReasonPopover'

import * as styles from './styles.styl'

// tslint:disable-next-line: max-func-body-length
const getColumns = (onArchive?: (event: Event) => void, timezone:any = null): ColumnProps<Event>[] => {
  return [
    {
      title: 'Мероприятие',
      dataIndex: 'name',
      render: (name: string, event: Event) => (
      <AllowedTo
        perform={Permissions.EVENT_REVIEW}
        data={event}
        yes={() => <Link target="_blank" to={`/events/${event.id}/review`}>{name}</Link>}
        no={() =>
          <AllowedTo
            perform={Permissions.EVENT_EDIT}
            data={event}
            yes={() => <Link target="_blank" to={`/events/${event.id}/edit`}>{name}</Link>}
            no={() => <span>{name}</span>}
          />
        }
      />
    )
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 108,
      render: (_: number, event: Event) => (
        <div className={styles.actionButtons}>
          <AllowedTo
            perform={Permissions.EVENT_ARCHIVE}
            data={event}
          >
            <Popconfirm
              title={<div>Действительно отправить в архив?<br />Данное действие нельзя отменить</div>}
              icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
              onConfirm={() => { if (onArchive) { onArchive(event) } }}
              okText='Да'
              okType='danger'
              cancelText='Нет'
            >
              <Tooltip placement='bottom' title='Отправить в архив'>
                <Button icon='delete' style={{ color: 'red' }} />
              </Tooltip>
            </Popconfirm>
          </AllowedTo>
          <Tooltip
            title='Скопировать ссылку'
            placement='bottom'
          >
            <Button
              icon='copy'
              onClick={() => copy(`${window.location.origin}/events/${event.id}`)}
            />
          </Tooltip>
        </div>
      )
    },
    {
      title: 'Менеджер',
      dataIndex: 'author',
      width: 150,
      render: (author?: User) => `${author ? author.name : '-'}`
    },
    {
      title: 'Статус брони',
      dataIndex: 'reservationType',
      width: 150,
      render: (reservationType: EVENT_RESERVATION_TYPE, event: Event) => (
      <StatusReasonPopover id={event.id} title={EVENT_RESERVATION_LOCALIZED[reservationType]} statusReason={event.statusReason} />
    )
    },
    {
      title: 'Статус согласования',
      dataIndex: 'status',
      width: 150,
      render: (status: EVENT_STATUS, event: Event) => (
      <StatusReasonPopover id={event.id} title={EVENT_STATUS_LOCALIZED[status]} statusReason={event.statusReason} />
    )
    },
    {
      title: 'Дата начала',
      width: 110,
      sorter: true,
      dataIndex: 'startDate',
      render: (date: string) => timezone ? moment(date).utcOffset(timezone).format(HUMAN_DATETIME_FORMAT) : moment(date).format(HUMAN_DATETIME_FORMAT)
    },
    {
      title: 'Помещения',
      dataIndex: 'bookings',
      width: 200,
      render: (bookings: Booking[]) => bookings
      .map((booking: Booking) => {
        const installingDatesRange = backendDateRangeToMomentDatetimeRange(booking.installingDates, timezone)
        const dismantlingDatesRange = backendDateRangeToMomentDatetimeRange(booking.dismantlingDates, timezone)
        const datesRange = backendDateRangeToMomentDatetimeRange(booking.dates, timezone)
        const roomName = booking.room ? booking.room.name : '-'

        const installingDatesAltRange = (booking.installingDatesAlt && booking.installingDatesAlt[0]) ? backendDateRangeToMomentDatetimeRange(booking.installingDatesAlt, timezone) : false
        const dismantlingDatesAltRange = (booking.dismantlingDatesAlt && booking.dismantlingDatesAlt[0]) ? backendDateRangeToMomentDatetimeRange(booking.dismantlingDatesAlt, timezone) : false
        const datesAltRange = (booking.datesAlt && booking.datesAlt[0]) ? backendDateRangeToMomentDatetimeRange(booking.datesAlt, timezone) : false     

        return (
          <Popover
            trigger='hover'
            key={booking.id}
            title={roomName}
            content={
              <Descriptions size='small' column={1} className={styles.roomPopover}>
                 <Descriptions.Item label='Монтаж'>
                {
                  installingDatesAltRange ?
                  <span><u>Осн.:</u>{' '}{formatDateRange(installingDatesRange)}<br /><u>Альт.:</u>{' '}{formatDateRange(installingDatesAltRange)}</span>
                  : <span>{formatDateRange(installingDatesRange)}</span>
                }
                </Descriptions.Item>
                <Descriptions.Item label='Проведение мероприятия'>
                {
                  datesAltRange ?
                  <span><u>Осн.:</u>{' '}{formatDateRange(datesRange)}<br /><u>Альт.:</u>{' '}{formatDateRange(datesAltRange)}</span>
                  : <span>{formatDateRange(datesRange)}</span>
                }
                </Descriptions.Item>
                <Descriptions.Item label='Демонтаж'>
                {
                  dismantlingDatesAltRange ?
                  <span><u>Осн.:</u>{' '}{formatDateRange(dismantlingDatesRange)}<br /><u>Альт.:</u>{' '}{formatDateRange(dismantlingDatesAltRange)}</span>
                  : <span>{formatDateRange(dismantlingDatesRange)}</span>
                }
                </Descriptions.Item>
              </Descriptions>
            }
          >
            {/* tslint:disable-next-line: react-a11y-anchors */}
            <div><a className={styles.linkPopup}>{booking.room ? booking.room.name : '-'}</a></div>
          </Popover>)
      })
    },
    {
      title: 'Тип мероприятия',
      dataIndex: 'type.name',
      width: 200,
      render: (eventTypeName: number) => eventTypeName
    },
    {
      title: 'Количество людей на площадке по дням через запятую',
      dataIndex: 'participantCount',
      width: 120,
      render: (participantCount: number) => participantCount
    },
    {
      title: 'Формат питания',
      dataIndex: 'mealFormat.name',
      width: 200,
      render: (mealFormatName: number) => mealFormatName
    },
    {
      title: 'Количество питающихся людей по предполагаемому формату питания по дням через запятую',
      dataIndex: 'participantCountMeal',
      width: 120,
      render: (participantCountMeal: number) => participantCountMeal
    },
    {
      title: 'Сайт',
      dataIndex: 'website',
      width: 200,
      render: (website: string) => (
        <div className={styles.wrappedText}>
          <a href={website} target='_blank' rel='noreferrer'>{website}</a>
        </div>
      )
    },
    {
      title: 'Возрастная категория',
      dataIndex: 'ageRating',
      width: 120,
      render: (ageRating: number) => ageRating
    },
    {
      title: 'Продажа алкоголя',
      dataIndex: 'isSellingAlcohol',
      width: 100,
      render: (isSellingAlcohol: string) => isSellingAlcohol ? 'Да' : 'Нет'
    },
    {
      title: 'Контроль квадратуры',
      dataIndex: 'enableSquareCheck',
      width: 100,
      render: (enableSquareCheck: string) => enableSquareCheck ? 'Да' : 'Нет'
    }
  ]
}

export default getColumns
