import { Room, Event, Booking, Timezone } from 'types/models'
import queryString from 'query-string'
import classnames from 'classnames'
import connect, { PromiseState } from 'react-redux-fetch'
import urljoin from 'url-join'
import * as R from 'ramda'
import React from 'react'
import { DateRange } from 'moment-range'
import moment from 'moment'
import { DispatchProp } from 'react-redux'
import config from 'common/config'
import { rangeSelectionToMomentDateRange } from 'utils/date.utils'
import { Spin } from 'antd'

import { default as RoomsComponent } from './rooms/Rooms'
import * as styles from './calendar.styl'

interface Legend {
  style: string
  text: string
}

const legends: Legend[] = [
  {
    style: styles.montage,
    text: 'Монтаж/Демонтаж'
  },
  {
    style: styles.booked,
    text: 'Проведение мероприятия'
  },
  {
    style: styles.softReserve,
    text: 'Предварительная бронь'
  },
  {
    style: styles.selection,
    text: 'Текущее мероприятие'
  },
  {
    style: styles.overlap,
    text: 'Конфликт'
  }
]

interface Props extends DispatchProp {
  currentEvent?: Event
  timezone?: any
  dates: [moment.Moment, moment.Moment]
  roomsForCalendarFetch: PromiseState<Room[]>
  dispatchRoomsForCalendarGet(range: DateRange): void
  timezoneFetch: PromiseState<Timezone>
  dispatchTimezoneGet(): void
}

interface State {
  expandedGroups: (string | null)[]
  roomFilter?: string
}

class Calendar extends React.Component<Props, State> {
  public state: State = {
    expandedGroups: ['null'],
    roomFilter: undefined
  }

  public memoizedRangeSelectionToMomentDateRange: (dates: [string | moment.Moment, string | moment.Moment]) => DateRange =
    R.memoizeWith(R.identity, rangeSelectionToMomentDateRange)

  public expandGroup = (id: string | null) => {
    this.setState(
      (state: State) => ({ expandedGroups: [...state.expandedGroups, id] })
    )
  }

  public toggleGroup = (id: string | null) => {
    this.setState(
      (state: State) => {
        if (state.expandedGroups.includes(id)) {
          return { expandedGroups: R.reject(R.equals(id), state.expandedGroups) }
        }

        return { expandedGroups: [...state.expandedGroups, id] }
      }
    )
  }

  public roomFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      { roomFilter: event.target.value.toLocaleLowerCase() },
      () => {
        if (!this.state.roomFilter || !this.props.roomsForCalendarFetch.value) {
          return
        }

        this.setState({
          expandedGroups: R.uniq([
            ...this.state.expandedGroups,
            ...this.props.roomsForCalendarFetch.value
              .filter(
                (room: Room) => room.name.toLocaleLowerCase().includes(this.state.roomFilter as string)
              )
              .map(
                (room: Room) => String(room.typeId)
              )
          ])
        })
      })
  }

  public componentDidMount(): void {
    this.props.dispatchTimezoneGet()
    this.props.dispatchRoomsForCalendarGet(rangeSelectionToMomentDateRange(this.props.dates))
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!R.equals(prevProps.dates, this.props.dates)) {
      this.props.dispatchRoomsForCalendarGet(rangeSelectionToMomentDateRange(this.props.dates))
    }

    if (
      this.props.currentEvent
      && this.props.roomsForCalendarFetch.value
      && this.props.roomsForCalendarFetch.fulfilled
      && prevProps.roomsForCalendarFetch.pending
    ) {
      const types: string[] =
        R.pipe(
          R.map(
            (booking: Booking) => booking && booking.room && booking.room.typeId
          ),
          String,
          R.uniq
        )(this.props.currentEvent.bookings)

      types.forEach(this.expandGroup)
    }
  }

  public render(): React.ReactNode {
    const { roomsForCalendarFetch, timezoneFetch, dates, currentEvent} = this.props

    if (roomsForCalendarFetch.pending || timezoneFetch.pending || !roomsForCalendarFetch.value) {
      return <Spin />
    }

    return (
      <div>
        Легенда:
        {legends.map((legend: Legend, i: number) => <div key={i} className={classnames(styles.block, legend.style)}>{legend.text}</div>)}
        <div className={styles.rooms}>
          <RoomsComponent
            timezone={timezoneFetch && timezoneFetch.value ? timezoneFetch.value.value : null}
            selectedEvent={currentEvent}
            rooms={roomsForCalendarFetch.value}
            range={this.memoizedRangeSelectionToMomentDateRange(dates)}
            toggleGroup={this.toggleGroup}
            expandedGroups={this.state.expandedGroups}
            roomFilter={this.state.roomFilter}
            roomFilterChange={this.roomFilterChange}
          />
        </div>
      </div>
    )
  }
}

export default connect<{}, Pick<Props, 'dispatchTimezoneGet' | 'dispatchRoomsForCalendarGet' | 'dispatch' | 'roomsForCalendarFetch' | 'timezoneFetch'>>(
  [
    {
      resource:  'timezone',
      method: 'get',
      request: () => ({
          url: urljoin(config.backendUrl, 'timezone')
      })
    },
    {
      resource: 'roomsForCalendar',
      method: 'get',
      request: (range: DateRange) => {
        const query: string = queryString.stringify({
          scope: 'CALENDAR',
          startDate: range.start.startOf('day').toISOString(),
          endDate: range.end.endOf('day').toISOString(),
          excludeArchived: true
        })

        return {
          url: urljoin(config.backendUrl, 'rooms', `?${query}`),
          comparison: query
        }
      }
    }
  ]
)(Calendar)
