import React from 'react'
import connect, { PromiseState } from 'react-redux-fetch'
import { Link } from 'react-router-dom'
import { ColumnProps } from 'antd/es/table'
import { Table, Button } from 'antd'
import urljoin from 'url-join'
import { MealFormat } from 'types/models'
import config from 'common/config'

interface Props {
  mealFormatsFetch: PromiseState<MealFormat[]>

  dispatchMealFormatsGet(): void
}

const columns: ColumnProps<MealFormat>[] = [
  {
    key: 'id',
    title: 'id',
    width: 100,
    dataIndex: 'id'
  },
  {
    key: 'name',
    title: 'Имя',
    dataIndex: 'name',
    render: (text: string, record: MealFormat) => <Link to={`/admin/mealFormats/${record.id}`}>{text}</Link>
  }
]

class EventTable extends Table<MealFormat> {}

class Index extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.dispatchMealFormatsGet()
  }

  public render(): React.ReactNode {
    return (
      <div>
        <Link to='/admin/mealFormats/create'>
          <Button type='primary'>
            Новый формат питания
          </Button>
        </Link>
        <EventTable
          columns={columns}
          rowKey='id'
          dataSource={this.props.mealFormatsFetch.value}
        />
      </div>
    )
  }
}

export default connect([{
  resource: 'mealFormats',
  method: 'get',
  request: {
    url: urljoin(config.backendUrl, 'mealFormats')
  }
}])(Index)
