exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__root___2Bq_t {\n  max-width: 50vw;\n  max-height: 40vh;\n  overflow: auto;\n}\n.styles__linkPopup___2wthT {\n  -webkit-text-decoration: underline dotted;\n          text-decoration: underline dotted;\n}\n.styles__linkPopup___2wthT:hover {\n  -webkit-text-decoration: underline dotted;\n          text-decoration: underline dotted;\n}\n.styles__linkPopup___2wthT:active {\n  color: #40a9ff;\n}\n", ""]);

// exports
exports.locals = {
	"root": "styles__root___2Bq_t",
	"linkPopup": "styles__linkPopup___2wthT"
};