exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__loginWrap___2mXph {\n  text-align: center;\n}\n.styles__login___3ZFZA {\n  text-align: left;\n  display: inline-block;\n  width: 600px;\n}\n", ""]);

// exports
exports.locals = {
	"loginWrap": "styles__loginWrap___2mXph",
	"login": "styles__login___3ZFZA"
};