exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__rangePicker___3Fc10 {\n  width: 450px !important;\n}\n.styles__errorNewLine___3Vma4 .ant-form-explain {\n  white-space: pre;\n}\n", ""]);

// exports
exports.locals = {
	"rangePicker": "styles__rangePicker___3Fc10",
	"errorNewLine": "styles__errorNewLine___3Vma4"
};