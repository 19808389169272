import { UsageFormatFormInterface } from './types'

type OptionFormValidation = {
    [F in keyof UsageFormatFormInterface]?: string
}

const REQUIRED_FIELD = 'Поле обязательно для заполнения'

export function validate(values: UsageFormatFormInterface): OptionFormValidation {
    const errors: OptionFormValidation = {}

    if (!values.name) {
        errors.name = REQUIRED_FIELD
    }

    return errors
}
