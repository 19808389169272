exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__spinWrapper___20p8X {\n  text-align: center;\n}\n.styles__authorWrapper___QpaR8 {\n  font-size: 16px;\n}\n.styles__author___36Dml {\n  font-weight: 600;\n}\n", ""]);

// exports
exports.locals = {
	"spinWrapper": "styles__spinWrapper___20p8X",
	"authorWrapper": "styles__authorWrapper___QpaR8",
	"author": "styles__author___36Dml"
};