import React from 'react'
import { DateRange } from 'moment-range'
import classnames from 'classnames'
import * as R from 'ramda'
import { Popover } from 'antd'
import moment from 'moment'
import { Booking, EVENT_RESERVATION_TYPE, DateRangeType } from 'types/models'
import {
  backendDateRangeToMomentDateRange,
  intersectDateRanges,
  backendDateRangeToMomentDatetimeRange
} from 'utils/date.utils'

import { default as BookingComponent } from './booking/Booking'
import * as styles from './room.styl'
import { PopoverDateRanges, Props, State, CalendarDate } from './types'

class Room extends React.PureComponent<Props, State> {
  public state: State = {
    dates: []
  }

  private readonly backendDateRangeToMomentDateRangeMemoized: (range: DateRangeType | null | undefined, timezone: any | undefined) => DateRange =
    R.memoizeWith(
      R.pipe(
        R.pluck('value'),
        R.join(',')
      ),
      backendDateRangeToMomentDateRange
    )

  private readonly backendDateRangeToMomentDatetimeRangeMemoized: (range: DateRangeType | null | undefined, timezone: any) => DateRange =
    R.memoizeWith(
      R.pipe(
        R.pluck('value'),
        R.join(',')
      ),
      backendDateRangeToMomentDatetimeRange
    )

  public componentDidMount(): void {
    const { range } = this.props

    const dates: CalendarDate[] =
      Array
      .from(range.by('day'))
      .map(
        (realDate: moment.Moment, i: number) => {
          const date = realDate.clone().startOf('day')
          const bookings: Booking[] =
            this.getBookings(date)
          const actualBookingsNumber: number = R.reject(R.isNil, bookings).length
          const hasBookings: boolean = actualBookingsNumber > 0

          let className: string = ''

          if (hasBookings) {
            if (actualBookingsNumber > 1) {
              if (this.isMontage(bookings, date)){
                className = styles.overlapMontag
              } else {
                className = styles.overlap
              }
            }

            if (className === '' && this.isCurrentSelection(date)) {
              className = styles.selection
            }

            if (className === '' && this.isSoftReserved(bookings, date)) {
              if (this.isMontage(bookings, date)){
                className = styles.softReserveMontag
              } else {
                className = styles.softReserve
              }
            }

            if (className === '' && this.isBooked(bookings, date)) {
              if (this.isMontage(bookings, date)){
                className = styles.bookedMontag
              } else {
                className = styles.booked
              }              
            }

            if (className === '' && this.isMontage(bookings, date)) {
              className = styles.montage
            }
          }

          return {
            date: realDate,
            className,
            popoverContent: this.getPopoverContent(bookings),
            hasBookings
          }
        }
      )

    this.setState({ dates })
  }

  public isDateInRanges(date: moment.Moment, range: (DateRange | undefined)[]): boolean {
    return R.reject(R.isNil)(range).some((dateRange: DateRange) => dateRange.contains(date))
  }

  public isMontage: (bookings: Booking[], date: moment.Moment) => boolean = (bookings: Booking[], date: moment.Moment) =>
    bookings.some((booking: Booking) =>
      this.isDateInRanges(
        date,
        [
          this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDates, this.props.timezone),
          this.backendDateRangeToMomentDateRangeMemoized(booking.installingDates, this.props.timezone),
          (booking.dismantlingDatesAlt) ? this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDatesAlt, this.props.timezone) : this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDates, this.props.timezone),
          (booking.installingDatesAlt) ? this.backendDateRangeToMomentDateRangeMemoized(booking.installingDatesAlt, this.props.timezone) : this.backendDateRangeToMomentDateRangeMemoized(booking.installingDates, this.props.timezone)
        ]
      )
    )

  public isBooked: (bookings: Booking[], date: moment.Moment) => boolean = (bookings: Booking[], date: moment.Moment) =>
    bookings.some((booking: Booking) =>
      // this.backendDateRangeToMomentDateRangeMemoized(booking.dates).contains(date)

      booking.datesAlt && booking.datesAlt[0]
      ? this.isDateInRanges(
        date, [
          this.backendDateRangeToMomentDateRangeMemoized(booking.dates, this.props.timezone),
          this.backendDateRangeToMomentDateRangeMemoized(booking.datesAlt, this.props.timezone)
        ])
      : this.isDateInRanges(
        date, [
        this.backendDateRangeToMomentDateRangeMemoized(booking.dates, this.props.timezone)
      ])
    )

  public isSoftReserved: (bookings: Booking[], date: moment.Moment) => boolean = (bookings: Booking[], date: moment.Moment) =>
    bookings.some((booking: Booking) => {

      let tmpRange = [
        this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDates, this.props.timezone),
        this.backendDateRangeToMomentDateRangeMemoized(booking.installingDates, this.props.timezone),
        this.backendDateRangeToMomentDateRangeMemoized(booking.dates, this.props.timezone)
      ]

      if(booking.dismantlingDatesAlt && booking.dismantlingDatesAlt[0]){
        tmpRange.push(this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDatesAlt, this.props.timezone));
      }
      if(booking.installingDatesAlt && booking.installingDatesAlt[0]){
        tmpRange.push(this.backendDateRangeToMomentDateRangeMemoized(booking.installingDatesAlt, this.props.timezone));
      }
      if(booking.datesAlt && booking.datesAlt[0]){
        tmpRange.push(this.backendDateRangeToMomentDateRangeMemoized(booking.datesAlt, this.props.timezone));
      }

      booking.event &&
      booking.event.reservationType === EVENT_RESERVATION_TYPE.PRELIMINARY && 
      this.isDateInRanges(
        date,
        tmpRange
      )
    }
  )

  public isCurrentSelection: (date: moment.Moment) => boolean = (date: moment.Moment) =>
    this.props.selectedEvent && this.props.selectedEvent.bookings ?
      this.props.selectedEvent.bookings
        .filter((booking: Booking) => booking.roomId === this.props.room.id)
        .some(
          (booking: Booking) => {

            let cond: any = [];
            if (booking.dismantlingDates){cond.push(this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDates, this.props.timezone));}
            if (booking.installingDates){cond.push(this.backendDateRangeToMomentDateRangeMemoized(booking.installingDates, this.props.timezone));}
            if (booking.dates){cond.push(this.backendDateRangeToMomentDateRangeMemoized(booking.dates, this.props.timezone));}
            if (booking.dismantlingDatesAlt){cond.push(this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDatesAlt, this.props.timezone));}
            if (booking.installingDatesAlt){cond.push(this.backendDateRangeToMomentDateRangeMemoized(booking.installingDatesAlt, this.props.timezone));}
            if (booking.datesAlt){cond.push(this.backendDateRangeToMomentDateRangeMemoized(booking.datesAlt, this.props.timezone));}

            this.isDateInRanges(
              date,
              cond
              // [
              //   this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDates),
              //   this.backendDateRangeToMomentDateRangeMemoized(booking.installingDates),
              //   this.backendDateRangeToMomentDateRangeMemoized(booking.dates),
              //   this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDatesAlt),
              //   this.backendDateRangeToMomentDateRangeMemoized(booking.installingDatesAlt),
              //   this.backendDateRangeToMomentDateRangeMemoized(booking.datesAlt)
              // ]
            )
          }
        ) :
        false

  public getBookings: (date: moment.Moment) => Booking[] = (date: moment.Moment) => {
    return this.props.room.bookings.filter((booking: Booking) => 
      this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDates, this.props.timezone).contains(date) ||
      this.backendDateRangeToMomentDateRangeMemoized(booking.installingDates, this.props.timezone).contains(date) ||
      this.backendDateRangeToMomentDateRangeMemoized(booking.dates, this.props.timezone).contains(date) ||
      ( booking.dismantlingDatesAlt && this.backendDateRangeToMomentDateRangeMemoized(booking.dismantlingDatesAlt, this.props.timezone).contains(date) ) ||
      ( booking.installingDatesAlt && this.backendDateRangeToMomentDateRangeMemoized(booking.installingDatesAlt, this.props.timezone).contains(date) ) ||
      ( booking.datesAlt && this.backendDateRangeToMomentDateRangeMemoized(booking.datesAlt, this.props.timezone).contains(date) )
    )
  }

  public getPopoverDateRanges(bookings: Booking[]): [] {
    const popoverDateRanges = R.map(
      (booking: Booking) => ({
        dates: {
          range: this.backendDateRangeToMomentDatetimeRangeMemoized(booking.dates, this.props.timezone),
          isConflicting: false
        },
        installingDates: {
          range: this.backendDateRangeToMomentDatetimeRangeMemoized(booking.installingDates, this.props.timezone),
          isConflicting: false
        },
        dismantlingDates: {
          range: this.backendDateRangeToMomentDatetimeRangeMemoized(booking.dismantlingDates, this.props.timezone),
          isConflicting: false
        },
        datesAlt: {
          range: (booking.datesAlt && booking.datesAlt[0]) ? this.backendDateRangeToMomentDatetimeRangeMemoized(booking.datesAlt, this.props.timezone) : null,
          isConflicting: false
        },
        installingDatesAlt: {
          range: (booking.installingDatesAlt && booking.installingDatesAlt[0]) ? this.backendDateRangeToMomentDatetimeRangeMemoized(booking.installingDatesAlt, this.props.timezone) : null,
          isConflicting: false
        },
        dismantlingDatesAlt: {
          range: (booking.dismantlingDatesAlt && booking.dismantlingDatesAlt[0]) ? this.backendDateRangeToMomentDatetimeRangeMemoized(booking.dismantlingDatesAlt, this.props.timezone) : null,
          isConflicting: false
        }
      }),
      bookings
    )

    const dateRangePool: DateRange[] = []

    popoverDateRanges.forEach((ranges: PopoverDateRanges) => {
      dateRangePool.push(ranges.dates.range)
      dateRangePool.push(ranges.installingDates.range)
      dateRangePool.push(ranges.dismantlingDates.range)
      if (ranges.datesAlt && ranges.datesAlt.range){dateRangePool.push(ranges.datesAlt.range)}
      if (ranges.installingDatesAlt && ranges.installingDatesAlt.range){dateRangePool.push(ranges.installingDatesAlt.range)}
      if (ranges.dismantlingDatesAlt && ranges.dismantlingDatesAlt.range){dateRangePool.push(ranges.dismantlingDatesAlt.range)}
    })

    const intersectionPool = intersectDateRanges(dateRangePool)

    // tslint:disable-next-line:one-variable-per-declaration
    for (let i = 0, n = 0, l = dateRangePool.length; i < l; i += 6, n = Math.floor(i / 6)) {
    // for (let i = 0, n = 0, l = dateRangePool.length; i < l; i += 3, n = Math.floor(i / 3)) {
      popoverDateRanges[n].dates.isConflicting = intersectionPool[i]
      popoverDateRanges[n].installingDates.isConflicting = intersectionPool[i + 1]
      popoverDateRanges[n].dismantlingDates.isConflicting = intersectionPool[i + 2]
      popoverDateRanges[n].datesAlt.isConflicting = intersectionPool[i+ 3]
      popoverDateRanges[n].installingDatesAlt.isConflicting = intersectionPool[i + 4]
      popoverDateRanges[n].dismantlingDatesAlt.isConflicting = intersectionPool[i + 5]
    }

    return popoverDateRanges
  }

  public getPopoverContent(bookings: Booking[]): React.ReactNode {
    const popoverDateRanges = this.getPopoverDateRanges(bookings)

    return (
      <>
        {
          bookings.map(
            (booking: Booking, i: number) =>
              <BookingComponent
                key={i}
                booking={booking}
                dateRanges={popoverDateRanges[i]}
              />
          )
        }
      </>
    )

  }

  public render(): React.ReactNode {
    const { hidden } = this.props
    const { dates } = this.state

    if (hidden) {
      return null
    }

    return (
      <div className={styles.room}>
        {
          dates.map(({ className, popoverContent, date, hasBookings }: CalendarDate, i: number) => (
            <div
              key={i}
              className={classnames(styles.block, className)}
            >
              {hasBookings ?
                <Popover
                  className={styles.popover}
                  content={popoverContent}
                >
                  {date.get('date')}
                </Popover> :
                <span>
                  {date.get('date')}
                </span>
              }
            </div>
          ))
        }
      </div>
    )
  }
}

export default Room
